import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import {
  createUser,
  createCollaborator,
  areaActions,
  updateShowcases,
  updateEvent,
  MediaTagActions,
} from "../../actions/auth";
import LoaderGif from "../modules/loader";
import { statusErrorSuccess } from "../../App";
import { editEvent } from "../../actions/auth";

const DeleteConfirmation = (props) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  // For People
  const removecollaboratorRow = (id) => {
    setLoader(true);
    dispatch(createUser(null, id, "DELETE"))
      .then(() => {
        // Delete collaborator
        dispatch(createCollaborator(null, props.deleteCollaboratorId, "DELETE"))
          .then(() => {
            setLoader(false);
            statusErrorSuccess("deletedSuccess", "success");
            window.location.href = "/admin/access";
          })
          .catch(() => {
            setLoader(false);
            statusErrorSuccess("deleteFailed", "danger");
          });
        // setLoader(false);
        // window.location.href = "/admin/access";
      })
      .catch(() => {
        setLoader(false);
      });
  };

  // For Collaborate
  const removecreateCollaborator = (id) => {
    setLoader(true);
    dispatch(createCollaborator(null, id, "DELETE"))
      .then(() => {
        statusErrorSuccess("deletedSuccess", "success");
        window.location.href = "/admin/collaborators";
      })
      .catch(() => {
        statusErrorSuccess("deleteFailed", "danger");
        setLoader(false);
      });
  };

  // For Area
  const removeareaRow = async (areaId) => {
    setLoader(true);
    dispatch(areaActions(areaId, null, "DELETE"))
      .then(() => {
        setLoader(false);
        statusErrorSuccess("deletedSuccess", "success");
        window.location.href = "/admin/areas";
      })
      .catch((error) => {
        statusErrorSuccess("deleteFailed", "danger");
        setLoader(false);
      });
  };

  // For Showcase
  const removeShowcases = (id) => {
    setLoader(true);
    dispatch(updateShowcases(id, null, "DELETE"))
      .then(() => {
        statusErrorSuccess("deletedSuccess", "success");
        setLoader(false);
        window.location.href = "/admin/showcases";
      })
      .catch(() => {
        statusErrorSuccess("deleteFailed", "danger");
        setLoader(false);
      });
  };

  // For CollaboratorsList
  let collabIds = props.collabIds;
  const removeCollaborator = (id) => {
    setLoader(true);
    let event_update = {};
    let collaborator_ids = collabIds.replace(id + ",", "");
    collaborator_ids = collabIds.replace(id, "");
    event_update.collaborator_ids = collaborator_ids;
    dispatch(updateEvent(props.eventCardId, event_update))
      .then(() => {
        statusErrorSuccess("deletedSuccess", "success");
        setLoader(false);
        props.onHide();
        window.location.href = "/admin/view-event/others/" + props.eventId;
      })
      .catch((error) => {
        statusErrorSuccess("deleteFailed", "danger");
        setLoader(false);
      });
  };

  const removeUserFromEventAccess = (
    selected_user,
    orgData,
    eventType,
    action
  ) => {
    setLoader(true);
    if (action === "DELETE") {
      let finalData = orgData;
      finalData.event_users = finalData.event_users.filter(
        (item) => item.id !== selected_user.id
      );

      dispatch(editEvent(finalData, eventType, orgData.id))
        .then(() => {
          setLoader(false);
          statusErrorSuccess("deleteEventAccessSuccess", "success");
          window.location.href =
            "/admin/event-access/" + eventType + "/" + orgData.id;
        })
        .catch(() => {
          setLoader(false);
          statusErrorSuccess("deleteFailed", "danger");
          window.location.href =
            "/admin/event-access/" + eventType + "/" + orgData.id;
        });
    } else if (action === "CANCEL") {
      setLoader(false);
      props.onHide();
    }
  };

  const confirmDeleteProps = () => {
    props.delete();
    props.onHide();
  };

  return (
    <React.Fragment>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="ad-area-modal upload-assets-popup delete_confirmation_popup"
      >
        <Modal.Header>
          <h4 className="popup_title">Delete Confirmation</h4>
          <p className="popup_close_icon text-right" onClick={props.onHide}>
            <img className="img-fluid" src="/images/close.svg" alt="" />
          </p>
        </Modal.Header>
        <Modal.Body>
          <div className="cover_delete_confirmation">
            <p className="confirmation_text">
              {props.deleteMessage.split("/n").map((item, key) => {
                return (
                  <React.Fragment key={key}>
                    {item}
                    <br />
                  </React.Fragment>
                );
              })}
            </p>
            <div className="btn_group text-right">
              {props.page === "event-access-page" ? (
                <Button
                  variant="outlined"
                  color="primary"
                  className="remove_btn mr-2"
                  onClick={() =>
                    removeUserFromEventAccess(
                      props.deleteConfirmationValue,
                      props.eventAccessOrgValue,
                      props.eventType,
                      "CANCEL"
                    )
                  }
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  className="remove_btn mr-2"
                  onClick={props.onHide}
                >
                  Cancel
                </Button>
              )}
              {props.deleteConfirmationValue === "" ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => confirmDeleteProps()}
                >
                  Delete
                </Button>
              ) : (
                <React.Fragment>
                  {props.page === "people-page" && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        removecollaboratorRow(props.deleteConfirmationValue)
                      }
                    >
                      Delete
                    </Button>
                  )}
                  {props.page === "collaborators-page" && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        removecreateCollaborator(props.deleteConfirmationValue)
                      }
                    >
                      Delete
                    </Button>
                  )}
                  {props.page === "area-page" && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        removeareaRow(props.deleteConfirmationValue)
                      }
                    >
                      Delete
                    </Button>
                  )}
                  {props.page === "showcase-page" && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        removeShowcases(props.deleteConfirmationValue)
                      }
                    >
                      Delete
                    </Button>
                  )}
                  {props.page === "collaborators-page-list" && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        removeCollaborator(props.deleteConfirmationValue)
                      }
                    >
                      Delete
                    </Button>
                  )}
                  {props.page === "event-access-page" && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        removeUserFromEventAccess(
                          props.deleteConfirmationValue,
                          props.eventAccessOrgValue,
                          props.eventType,
                          "DELETE"
                        )
                      }
                    >
                      Delete
                    </Button>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {loader === true && <LoaderGif />}
    </React.Fragment>
  );
};
export default DeleteConfirmation;
