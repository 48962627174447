import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useState, useCallback, useEffect } from "react";
import { Modal, Col, Form } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { useDropzone } from "react-dropzone";
import { uploadMedia, MediaTagActions } from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import { FormGroup } from "@material-ui/core";
import LoaderGif from "../modules/loader";
import UserService from "../../services/user.service";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import category_list_json from "../../assets/json/category_list";
import { statusErrorSuccess } from "../../App";
const ENV_UPLOAD_MEDIA_SIZE = process.env.REACT_APP_UPLOAD_MEDIA_SIZE;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100 + "%",
    maxWidth: 100 + "%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UploadAssets = (props) => {
  const { uploadMediaData } = useSelector((state) => state.auth);
  const categoryList = category_list_json;

  const [myFiles, setMyFiles] = useState(
    props.uploadFiles ? props.uploadFiles : []
  );
  const dispatch = useDispatch();
  const [loaderUpload, setLoaderUpload] = useState(false);
  const [tag, setTag] = React.useState([]);
  const [dropzoneType, setDropzoneType] = React.useState(
    "image/JPEG, image/JPG, image/PNG, image/jpeg, image/jpg, image/png, video/*, audio/*, application/pdf"
  );
  const [uploadFileType, setUploadFileTypeLoader] = useState(false);
  const [eventCategory, setEventCategory] = useState(
    props.category
      ? categoryList.filter((i) => props.category === i.value)[0]
      : {}
  );
  const [filesUploading, setFilesUploading] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      let newUploadFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setMyFiles(myFiles.concat(newUploadFiles));
    },
    [myFiles]
  );

  if (props.filteredBy && !uploadFileType) {
    setUploadFileTypeLoader(true);
    if (props.filteredBy === "image") {
      setDropzoneType(
        "image/JPEG, image/JPG, image/PNG, image/jpeg, image/jpg, image/png"
      );
    } else if (props.filteredBy === "video") {
      setDropzoneType("video/*");
    } else if (props.filteredBy === "audio") {
      setDropzoneType("audio/*");
    } else if (props.filteredBy === "application") {
      setDropzoneType("application/pdf");
    }
  }

  const onDropRejected = useCallback((acceptedFiles) => {
    statusErrorSuccess("uploadMediaErrorMessage", "danger");
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: ENV_UPLOAD_MEDIA_SIZE,
    accept: dropzoneType,
    onDropRejected,
  });

  // Remove Single File
  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const files = myFiles.map((file) => (
    <li
      className="col-xl-3 col-lg-3 col-sm-4 col-md-4 col-12 upload_item"
      key={file.path}
    >
      <div className="cover_uploaded_img">
        {file.type.split("/")[0] === "application" && (
          <img className="img-fluid" src="/images/pdf-card.png" alt="" />
        )}
        {file.type.split("/")[0] === "image" && (
          <img className="img-fluid" src={file.preview} alt="" />
        )}
        {file.type.split("/")[0] === "video" && (
          <video className="upload_assets_media">
            <source src={file.preview} type="video/mp4" />
          </video>
        )}
        {file.type.split("/")[0] === "audio" && (
          <div className="cover_audio_bg from_upload_assets">
            <audio className="media_video_lib" controls>
              <source src={file.preview} type="audio/mp3" />
            </audio>
          </div>
        )}
        <label className="file_type">{file.type.split("/")[0]}</label>
      </div>
      <h5 className="uploader_name">{file.name}</h5>
      <p className="uploader_size">
        {(file.size / (1024 * 1024)).toFixed(2) + " MB"}
      </p>
      <img
        className="remove_item_icon"
        src="/images/delete_icon.svg"
        alt=""
        onClick={removeFile(file)}
      />
    </li>
  ));

  const [uploadedIds, setUploadedIds] = useState([]);
  const handleMediaLib = () => {
    myFiles.map((file, i) => {
      setTimeout(function () {
        var formdata = new FormData();
        formdata.append("files", file, file.path);
        setLoaderUpload(true);
        setFilesUploading(true);
        dispatch(uploadMedia(formdata))
          .then(() => {
            // setLoader(false);
            statusErrorSuccess("addMediaSuccess", "success");
          })
          .catch(() => {
            setLoaderUpload(false);
            statusErrorSuccess("addMediaFailed", "danger");
          });
      }, 500);
    });
  };

  useEffect(() => {
    if (uploadMediaData && filesUploading) {
      let temp = [...uploadedIds];
      uploadMediaData.map((media, i) => {
        temp.push(media);
      });
      setUploadedIds(temp);
    }
  }, [uploadMediaData]);

  useEffect(() => {
    if (uploadedIds.length > 0) {
      if (tag.length > 0 || eventCategory) {
        if (uploadedIds.length === myFiles.length) {
          uploadedIds.map((media, i) => {
            let mimeType =
              media.mime.split("/")[0] === "application"
                ? media.mime.split("/")[1]
                : media.mime.split("/")[0];
            mimeType = mimeType.toLowerCase();
            let type = "Other";
            switch (mimeType) {
              case "image":
                type = "Image";
                break;
              case "video":
                type = "Video";
                break;
              case "pdf":
                type = "Pdf";
                break;
              case "audio":
                type = "Audio";
                break;
              default:
                type = "Other";
            }
            let data = {
              media_id: media.id,
              tags: tag.join(", "),
              type: type,
              industry:
                eventCategory && eventCategory.value ? eventCategory.value : "",
            };

            dispatch(MediaTagActions(0, data, "ADD"))
              .then(() => {
                if (i === uploadedIds.length - 1) {
                  setLoaderUpload(false);
                  setUploadedIds([]);
                  setFilesUploading(false);
                  props.onHide();
                  if (props.ismedialibrary == "true") {
                    window.location.href = "/admin/media-library";
                  } else {
                    props.newmediauploaded();
                  }
                }
              })
              .catch(() => {
                setLoaderUpload(false);
              });
          });
        }
      } else {
        setLoaderUpload(false);
        setUploadedIds([]);
        setFilesUploading(false);
        props.onHide();
        if (props.ismedialibrary === "true") {
          window.location.href = "/admin/media-library";
        } else {
          props.newmediauploaded();
        }
      }
    }
  }, [uploadedIds]);

  const [tagArray, setTagArray] = useState([]);
  const [mediatagsLoad, setMediatagsLoad] = useState(false);

  useEffect(() => {
    if (!mediatagsLoad) {
      UserService.getShowcaseList().then(
        (response) => {
          setMediatagsLoad(true);
          if (response.status === 200) {
            // let tag_array = Object.keys(response.data).map((key) => response.data[key].tags);
            let tag_array = new Array();
            response.data.map((i) => tag_array.push(i.showcase_name));
            setTagArray(tag_array);
          }
        },
        (error) => {}
      );
    }
  });

  const classes = useStyles();

  const handleChange = (event) => {
    setTag(event.target.value);
  };

  return (
    <React.Fragment>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="ad-area-modal upload-assets-popup"
      >
        <Modal.Header>
          <h4 className="popup_title">Upload Assets</h4>
          <p className="popup_close_icon" onClick={props.onHide}>
            <img className="img-fluid" src="/images/close.svg" alt="" />
          </p>
        </Modal.Header>
        <Modal.Body>
          <div className="brows_files">
            <div
              className={
                files.length !== 0
                  ? "d-flex align-items-center justify-content-between mb-4"
                  : undefined
              }
            >
              {files.length !== 0 && (
                <div>
                  <h4 className="selected_assets_title">
                    {files.length} Assets Selected
                  </h4>
                  <p className="selected_assets_caption">
                    Manage the assets before adding them to the Media Library
                  </p>
                </div>
              )}
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                {files.length === 0 ? (
                  <div className="drag_drop_div">
                    <img
                      className="img-fluid"
                      src="/images/upload-big.svg"
                      alt=""
                    />
                    <h4 className="drag_drop_title">
                      Drag & drop to upload or
                    </h4>
                    <Button variant="contained" color="primary">
                      Browse Files
                    </Button>
                  </div>
                ) : (
                  <div className="selected_assets mb-0">
                    <Button variant="outlined" color="primary">
                      Add More Assets
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <aside className={`${files.length >= 8 && "cover_middle_libaray"}`}>
              <ul className="row">{files}</ul>
            </aside>
          </div>
          <div
            className={`modal_footer ${files.length === 0 && "empty_dropzone"}`}
          >
            <Col lg={8} md={8} className="p-0">
              {files.length !== 0 && (
                <div className="tag_showcase d-flex align-items-start">
                  <FormGroup className="material-group w-100 mr-5 mb-0">
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="outlined-age-native-simple">
                        Tag Showcase
                      </InputLabel>
                      <Select
                        labelId="outlined-age-native-simple"
                        id="outlined-age-native-simple"
                        multiple
                        value={tag}
                        className="remove_check_bg"
                        onChange={handleChange}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={(selected) => (
                          <div className={classes.chips}>
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                className={classes.chip}
                              />
                            ))}
                          </div>
                        )}
                        MenuProps={MenuProps}
                      >
                        {tagArray.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={tag.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </FormGroup>
                  <Form.Group className="material-group w-100 mb-0">
                    {categoryList.length > 0 && (
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <Autocomplete
                          id="demo-simple-select-outlined-1"
                          options={categoryList}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setEventCategory(newValue);
                            }
                          }}
                          onInputChange={(event, newInputValue) => {}}
                          //  option => option.title?option.title:option
                          getOptionLabel={(option) =>
                            option.label ? option.label : ""
                          }
                          getOptionSelected={(option, value) =>
                            option.value === value.value
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Industry Vertical"
                              variant="outlined"
                            />
                          )}
                          name="event_category"
                          value={eventCategory}
                        />
                      </FormControl>
                    )}
                  </Form.Group>
                </div>
              )}
            </Col>
            <Col lg={4} md={4} className="p-0">
              <div className="upload_section text-right">
                <Button
                  variant="outlined"
                  color="primary"
                  className="remove_btn mr-2"
                  onClick={props.onHide}
                >
                  Cancel
                </Button>
                {files.length !== 0 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleMediaLib()}
                  >
                    Upload
                  </Button>
                )}
              </div>
            </Col>
          </div>
        </Modal.Body>
      </Modal>
      {loaderUpload && <LoaderGif />}
    </React.Fragment>
  );
};
export default UploadAssets;
