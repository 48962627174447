import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Redirect, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
// Material Theme
import Button from "@material-ui/core/Button";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormGroup from "@material-ui/core/FormGroup";
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
import BreadCrumb from "../modules/breadcrumb";
import UserService from "../../services/user.service";
import moment from "moment";
import DropZonePlaceHolder from "../modules/dropzone-placeholder";
import { editEvent } from "../../actions/auth";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import LoaderGif from "../modules/loader";
import { Base64 } from "js-base64";
import { googleTextToSpeech, uploadMedia } from "../../actions/auth";
import makeAnimated from "react-select/animated";
import { ErrorMessage } from "@hookform/error-message";
import { statusErrorSuccess } from "../../App";

const ENV_MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

const AddExperiences = ({ match }) => {
  const {
    params: { eventType, eventId, areaId },
  } = match;
  const { session } = useSelector((state) => state.auth);
  const [areaCard, setAreaCard] = useState();
  const [areaCardLoaded, setAreaCardLoaded] = useState(false);
  const [showcaseList, setShowcaseList] = useState();
  const [showcasesLoaded, setShowcasesLoaded] = useState(false);
  const [eventData, setEventData] = useState();
  // const [fullAreaData, setFullAreaData] = useState();
  const [eventDrivenType, setEventDrivenType] = useState("");
  const [eventCategory, setEventCategory] = useState("");
  const [languageVal, setLanguageVal] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (!areaCardLoaded) {
      setAreaCardLoaded(true);
      setLoader(true);
      UserService.getExperiencesList(eventId, eventType).then(
        (response) => {
          if (response.status === 200) {
            if (response.data.length > 0) {
              setEventData(response.data[0]);
              setEventDrivenType(
                response.data[0].base_configuration[0].event_configuration
                  .intro_configuration.intro_type
              );
              setEventCategory(response.data[0].event_category);
              setLanguageVal(response.data[0].base_configuration[0].language);
              let tempArea = {};
              tempArea["details"] = {
                area_id: parseInt(areaId),
                activated_for_event: true,
              };
              UserService.getAreaById(parseInt(areaId)).then(
                (response1) => {
                  setLoader(false);
                  if (response1.status === 200) {
                    tempArea["description"] = response1.data[0];
                    setAreaCard(tempArea);
                  }
                },
                (error) => {}
              );
            }
          }
        },
        (error) => {}
      );
    }

    if (!showcasesLoaded) {
      setShowcasesLoaded(true);
      UserService.getShowcaseList().then(
        (response) => {
          if (response.status === 200) {
            setShowcaseList(response.data);
          }
        },
        (error) => {}
      );
    }
  });

  if (!session) {
    return <Redirect push to="/" />;
  }

  return (
    <React.Fragment>
      {areaCard && (
        <React.Fragment>
          <Helmet>
            <title>
              {areaCard.description.area_id +
                " " +
                areaCard.description.area_name +
                " | Infosys Living Labs"}
            </title>
            <meta name="description" content="" />
          </Helmet>
          <BreadCrumb
            CurrentPage={areaCard.description.area_id}
            prevPageName={eventData.event_name}
            prevPageURL={
              "/admin/view-master-event/" +
              eventType +
              "/" +
              eventData.access_id
            }
          />
          <EditExperienceMain
            areaCard={areaCard}
            showcaseList={showcaseList}
            eventDrivenType={eventDrivenType}
            eventCategory={eventCategory}
            languageVal={languageVal}
            areaId={areaId}
            eventId={eventId}
            eventData={eventData}
            eventType={eventType}
            loader={loader}
            setLoader={setLoader}
          />
        </React.Fragment>
      )}
      {loader && <LoaderGif />}
    </React.Fragment>
  );
};

export default AddExperiences;

const EditExperienceMain = ({
  areaCard,
  showcaseList,
  eventDrivenType,
  areaId,
  eventId,
  eventData,
  eventType,
  languageVal,
  eventCategory,
  loader,
  setLoader,
}) => {
  const { register, handleSubmit, setValue, errors } = useForm({
    defaultValues: areaCard,
  });
  const [eventStatus, setEventStatus] = useState(eventData.event_status);
  const dispatch = useDispatch();
  // const [loader, setLoader] = useState(false);

  const onSubmitAddExperience = async (data) => {
    setLoader(true);
    data.details.area_id = parseInt(areaId);
    let finalData = eventData;
    finalData.event_status = eventStatus;

    let areaConfigs =
      eventData.base_configuration[0].event_configuration.area_configuration;
    if (!areaConfigs) {
      finalData.base_configuration[0].event_configuration.area_configuration = [];
      areaConfigs = [];
    }

    finalData.base_configuration[0].event_configuration.area_configuration[
      areaConfigs.length
    ] = data.details;

    dispatch(editEvent(finalData, eventType, finalData.id))
      .then(() => {
        statusErrorSuccess("addExperienceSuccess", "success");
        setLoader(false);
        window.location.href =
          "/admin/view-master-event/" + eventType + "/" + eventId;
      })
      .catch(() => {
        statusErrorSuccess("addExperienceFailed", "danger");
        setLoader(false);
      });
  };

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit(onSubmitAddExperience)}>
        <EditExperiencesbar
          eventData={eventData}
          areaCard={areaCard}
          eventId={eventId}
          eventType={eventType}
          register={register}
          areaId={areaId}
          setEventStatus={setEventStatus}
        />
        <EditExperiencesContent
          eventData={eventData}
          areaCard={areaCard}
          showcaseList={showcaseList}
          eventDrivenType={eventDrivenType}
          languageVal={languageVal}
          setValue={setValue}
          register={register}
          errors={errors}
          eventCategory={eventCategory}
          setLoader={setLoader}
        />
      </Form>
      {loader === true && <LoaderGif />}
    </React.Fragment>
  );
};

const EditExperiencesbar = ({ areaCard }) => {
  return (
    <section className="event_bar_section">
      <Container fluid>
        <div className="events_cover">
          <div>
            <div className="living_labs_btn for_dropdown">
              <h4 className="events_title mb-1">
                Add {areaCard.description.area_id}
              </h4>
            </div>
          </div>
          <div className="view_event_right_bar">
            <Button
              variant="contained"
              type="submit"
              color="primary"
              className="mr-0"
            >
              Submit
            </Button>
          </div>
        </div>
      </Container>
    </section>
  );
};

const EditExperiencesContent = ({
  errors,
  areaCard,
  showcaseList,
  eventData,
  eventDrivenType,
  languageVal,
  setValue,
  register,
  eventCategory,
  setLoader,
}) => {
  const animatedComponents = makeAnimated();
  const [areaSnapshot, setAreaSnapshot] = useState(
    areaCard.description ? areaCard.description.area_snapshot : ""
  );
  const [stateLoaded, setStateLoaded] = useState(false);
  const [tvContentType, setTvContentType] = useState("");
  const [pdfChange, setPDFChange] = useState(false);
  const [displayBanner, setDisplayBanner] = useState("");
  const [voiceOverFemaleIntro, setVoiceOverFemaleIntro] = useState("");
  const [voiceOverMaleIntro, setVoiceOverMaleIntro] = useState("");
  const [voiceOverHumanIntro, setVoiceOverHumanIntro] = useState(""); // intro_type
  // const [eventShownOnTv, setEventShownOnTv] = useState((areaCard.details.media_configuration && areaCard.details.media_configuration.tv_content_type) ? areaCard.details.media_configuration.tv_content_type : (eventDrivenType==="Video" ? "Wallpaper" : ""));
  const [eventShownOnTv, setEventShownOnTv] = useState(
    areaCard.details.media_configuration &&
      areaCard.details.media_configuration.tv_content_type
      ? areaCard.details.media_configuration.tv_content_type
      : eventDrivenType === "Video" || eventDrivenType === "Live"
      ? "Wallpaper"
      : ""
  );
  const [pdfFile, setPdfFile] = useState("");
  const [fullVideo, setFullVideo] = useState("");
  const [previewVideo, setPreviewVideo] = useState("");
  const [presenterVideoList, setPresenterVideoList] = useState([]);
  const [wallpaper, setWallpaper] = useState("");
  const [brandingImage, setBrandingImage] = useState(undefined);

  const handleAddMorePresenterVideo = () => {
    let temp = [...presenterVideoList];
    temp = temp.concat("");
    setPresenterVideoList(temp);
  };

  const removePresenterVideo = (val, index) => {
    setPresenterVideoList(presenterVideoList.filter((item, i) => i !== index));
  };

  const changeMedia = (changeField, media, pos) => {
    switch (changeField) {
      case "area_snapshot":
        setAreaSnapshot(media);
        setValue("description.area_snapshot", media);
        break;
      case "display_banner":
        setDisplayBanner(media);
        setValue("details.ui_configuration.display_banner", media);
        break;
      case "pdf_file":
        setPdfFile(media);
        setValue("details.media_configuration.pdf_file", media);
        break;
      case "full_video":
        setFullVideo(media);
        setValue(
          "details.media_configuration.video_configuration.full_video",
          media
        );
        break;
      case "preview_video":
        setPreviewVideo(media);
        setValue(
          "details.media_configuration.video_configuration.preview_video",
          media
        );
        break;
      case "presentation_videos":
        let temp = [...presenterVideoList];
        temp[pos] = media;
        setPresenterVideoList(temp);
        setValue(
          "details.media_configuration.video_configuration.presentation_videos",
          temp
        );
        break;
      case "wallpaper":
        setWallpaper(media);
        setValue(
          "details.media_configuration.wallpaper_configuration.wallpaper",
          media
        );
        break;
      case "area_intro_audio_female":
        setVoiceOverFemaleIntro(media);
        setValue("details.media_configuration.area_intro_audio_female", media);
        break;
      case "area_intro_audio_male":
        setVoiceOverMaleIntro(media);
        setValue("details.media_configuration.area_intro_audio_male", media);
        break;
      case "area_intro_audio_human":
        setVoiceOverHumanIntro(media);
        setValue("details.media_configuration.area_intro_audio_human", media);
        break;
      case "branding_image":
        setBrandingImage(media);
        setValue("details.branding_images", media);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!stateLoaded) {
      register("description.area_snapshot");
      register("details.ui_configuration.display_banner");
      register("details.media_configuration.pdf_file");
      register("details.media_configuration.video_configuration.full_video");
      register("details.media_configuration.video_configuration.preview_video");
      register(
        "details.media_configuration.video_configuration.presentation_videos"
      );
      register("details.media_configuration.wallpaper_configuration.wallpaper");
      register("details.branding_images");
      register("details.media_configuration.area_intro_audio_female");
      register("details.media_configuration.area_intro_audio_male");
      register("details.media_configuration.area_intro_audio_human");
      register("details.showcase_id");
      register("details.media_configuration.tv_content_type");

      showcaseList.map((showcase, i) => {
        if (showcase.default_area_id === areaCard.details.area_id) {
          setValue("details.showcase_id", showcase.showcase_id);
        }
      });
      if (areaCard.details.ui_configuration) {
        setValue(
          "details.ui_configuration.display_banner",
          areaCard.details.ui_configuration.display_banner
        );
      }
      if (areaCard.details.media_configuration) {
        setValue(
          "details.media_configuration.pdf_file",
          areaCard.details.media_configuration.pdf_file
        );
        setValue(
          "details.media_configuration.tv_content_type",
          areaCard.details.media_configuration.tv_content_type
        );

        if (areaCard.details.media_configuration.video_configuration) {
          setValue(
            "details.media_configuration.video_configuration.preview_video",
            areaCard.details.media_configuration.video_configuration
              .preview_video
          );
          setValue(
            "details.media_configuration.video_configuration.full_video",
            areaCard.details.media_configuration.video_configuration.full_video
          );
          setValue(
            "details.media_configuration.video_configuration.presentation_videos",
            areaCard.details.media_configuration.video_configuration
              .presentation_videos
          );
        }
        if (areaCard.details.media_configuration.wallpaper_configuration) {
          setValue(
            "details.media_configuration.wallpaper_configuration.wallpaper",
            areaCard.details.media_configuration.wallpaper_configuration
              .wallpaper
          );
        }
      } else if (eventDrivenType === "Video" || eventDrivenType === "Live") {
        setValue("details.media_configuration.tv_content_type", "Wallpaper");
      }
      if (areaCard.details.branding_images) {
        setValue("details.branding_images", areaCard.details.branding_images);
      }
      setStateLoaded(true);
    }
  });

  useEffect(() => {
    setValue(
      "details.media_configuration.video_configuration.presentation_videos",
      presenterVideoList
    );
  }, [presenterVideoList]);

  const handleIntroChange = (event) => {
    //setEventDrivenType(event.target.value);
  };

  const handleShownOnTvChange = (event) => {
    setEventShownOnTv(event.target.value);
    setValue("details.media_configuration.tv_content_type", event.target.value);
  };

  const handlePDFChange = (event) => {
    if (event.target.checked) {
      setPDFChange(true);
    } else {
      setPDFChange(false);
    }
  };

  const { textToSpeechMedia, uploadMediaData } = useSelector(
    (state) => state.auth
  );
  const [audioGender, setAudioGender] = useState("FEMALE");

  // after get response from API
  const createFileFromBase64 = (base64File) => {
    let byteCharacters = Base64.atob(base64File);
    var sliceSize = 512;

    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {
      type: "audio/mp3",
    }); //statement which creates the blob

    let fileName = "";
    const timemillis = new Date().getTime();
    if (audioGender === "FEMALE") {
      fileName = "AVO_F_" + timemillis + ".mp3";
    } else {
      fileName = "AVO_M_" + timemillis + ".mp3";
    }
    let file = new File([blob], fileName, { type: "audio/mp3" });

    return file;
  };

  const uploadAudioFile = (file) => {
    var formdata = new FormData();
    formdata.append("files", file, file.name);
    dispatch(uploadMedia(formdata))
      .then(() => {})
      .catch(() => {});
  };

  useEffect(() => {
    if (textToSpeechMedia) {
      let base64File = textToSpeechMedia.audioContent;
      let file = createFileFromBase64(base64File);
      uploadAudioFile(file);
    }
  }, [textToSpeechMedia]);

  useEffect(() => {
    if (uploadMediaData) {
      if (eventDrivenType === "Avatar") {
        if (audioGender === "FEMALE") {
          setVoiceOverFemaleIntro(uploadMediaData[0]);
          setValue(
            "details.media_configuration.area_intro_audio_female",
            uploadMediaData[0]
          );
          downloadbutton("MALE");
        } else if (audioGender === "MALE") {
          setVoiceOverMaleIntro(uploadMediaData[0]);
          setValue(
            "details.media_configuration.area_intro_audio_male",
            uploadMediaData[0]
          );
          setLoader(false);
          setAudioGender("");
        }
      } else if (eventDrivenType === "Video") {
        setVoiceOverHumanIntro(uploadMediaData[0]);
        setValue(
          "details.media_configuration.area_intro_audio_human",
          uploadMediaData[0]
        );
        setLoader(false);
        setAudioGender("");
      }
    }
  }, [uploadMediaData]);

  const [textToSpeechEnabled, setTextToSpeechEnabled] = useState(false);
  const [
    areaIntroVoiceOverTextValue,
    setAreaIntroVoiceOverTextValue,
  ] = useState(
    areaCard.details.media_configuration
      ? areaCard.details.media_configuration.area_intro_text
      : null
  );
  const voiceOverTextChange = (e) => {
    setAreaIntroVoiceOverTextValue(e.target.value);
    setTextToSpeechEnabled(true);
    setVoiceOverFemaleIntro(undefined);
    setVoiceOverMaleIntro(undefined);
    setVoiceOverHumanIntro(undefined);
  };

  const setGoogleLanguage = (
    gender,
    voiceOverData,
    langCode,
    langNameFemale,
    langNameMale
  ) => {
    if (gender === "FEMALE") {
      voiceOverData.voice = {
        languageCode: langCode,
        name: langNameFemale,
      };
    } else if (gender === "MALE") {
      voiceOverData.voice = {
        languageCode: langCode,
        name: langNameMale,
      };
    }

    return voiceOverData;
  };

  const dispatch = useDispatch();
  const downloadbutton = (gender) => {
    setAudioGender(gender);
    setTextToSpeechEnabled(false);
    setLoader(true);
    let voiceOverData = {
      audioConfig: {
        audioEncoding: "MP3",
        pitch: 0,
        speakingRate: 1,
      },
      input: {
        text: areaIntroVoiceOverTextValue,
      },
      voice: {},
    };

    if (languageVal === "DE") {
      voiceOverData = setGoogleLanguage(
        gender,
        voiceOverData,
        "de-DE",
        "de-DE-Wavenet-C",
        "de-DE-Wavenet-B"
      );
    } else if (languageVal === "FR") {
      voiceOverData = setGoogleLanguage(
        gender,
        voiceOverData,
        "fr-FR",
        "fr-FR-Wavenet-C",
        "fr-FR-Wavenet-D"
      );
    } else {
      voiceOverData = setGoogleLanguage(
        gender,
        voiceOverData,
        "en-US",
        "en-US-Wavenet-G",
        "en-US-Wavenet-I"
      );
    }

    dispatch(googleTextToSpeech(voiceOverData))
      .then(() => {})
      .catch((error) => {});
  };

  return (
    <section className="edit-experiences-section">
      <Container>
        <div className="accoridon_section">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h2 className="accorion_title">Area</h2>
            </AccordionSummary>
            <AccordionDetails>
              <div className="cover_edit_options w-100">
                <Row>
                  <Col xl={1} lg={1} md={1}>
                    <label className="material_label_text">Area ID</label>
                    <p className="id_text">{areaCard.details.area_id}</p>
                  </Col>
                  <Col xl={5} lg={5} md={5} sm={5}>
                    <Form.Group className="material-group for_textarea w-100 h-100">
                      <FormControl variant="outlined">
                        <InputLabel htmlFor="AreaDescription">
                          Area Description
                        </InputLabel>
                        <OutlinedInput
                          id="AreaDescription"
                          type="text"
                          name="description.area_description"
                          className="material-outline"
                          multiline
                          rows={7}
                          disabled
                          inputRef={register({
                            required: false,
                          })}
                        />
                      </FormControl>
                    </Form.Group>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12}>
                    <DropZonePlaceHolder
                      holder={
                        areaSnapshot && areaSnapshot.formats
                          ? ENV_MEDIA_URL + areaSnapshot.formats.thumbnail.url
                          : ""
                      }
                      type="image"
                      fileName={areaSnapshot ? areaSnapshot.name : ""}
                      fileSizeText=""
                      labelName="Area Snapshot"
                      ButtonText=""
                      changeselectedmedia={(media) =>
                        changeMedia("area_snapshot", media)
                      }
                      category={eventCategory ? eventCategory : "all"}
                    />
                  </Col>
                </Row>
                <div className="event_active">
                  <p className="event_showcase_status">
                    Showcase Activated for Event
                  </p>
                  <div className="checkbox_btns">
                    <div className="can-toggle">
                      <input
                        id="ShowcaseEvent"
                        type="checkbox"
                        name="details.activated_for_event"
                        ref={register({
                          required: false,
                        })}
                      />
                      <label htmlFor="ShowcaseEvent">
                        <div
                          className="can-toggle__switch"
                          data-checked="On"
                          data-unchecked="Off"
                        ></div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h2 className="accorion_title">UI</h2>
            </AccordionSummary>
            <AccordionDetails>
              <div className="cover_edit_options w-100">
                <Row>
                  <Col xl={5} lg={5} md={5} sm={12}>
                    <FormGroup className="material-group">
                      <FormControl variant="outlined">
                        <InputLabel
                          htmlFor="NamePlacard"
                          className="label-caption"
                        >
                          Name on Placard
                        </InputLabel>
                        <OutlinedInput
                          id="NamePlacard"
                          type="text"
                          name="details.ui_configuration.placard_name"
                          className="material"
                          inputRef={register({
                            required: {
                              value: true,
                              message: "Please enter name on placard.",
                            },
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="details.ui_configuration.placard_name"
                          as={<p className="error_msg"></p>}
                        />
                      </FormControl>
                    </FormGroup>
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={12}>
                    <FormGroup className="material-group">
                      <FormControl variant="outlined">
                        <InputLabel
                          htmlFor="display_order"
                          className="label-caption"
                        >
                          Display Order
                        </InputLabel>
                        <OutlinedInput
                          id="display_order"
                          type="text"
                          name="details.ui_configuration.display_order"
                          className="material"
                          inputRef={register({
                            required: {
                              value: true,
                              message: "Please select display order.",
                            },
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="details.ui_configuration.display_order"
                          as={<p className="error_msg"></p>}
                        />
                      </FormControl>
                    </FormGroup>
                  </Col>
                  <Col xl={5} lg={5} md={5} sm={12}>
                    <FormGroup className="material-group">
                      <FormControl variant="outlined">
                        <InputLabel
                          htmlFor="DisplayTitle"
                          className="label-caption"
                        >
                          Display Title
                        </InputLabel>
                        <OutlinedInput
                          id="DisplayTitle"
                          type="text"
                          name="details.ui_configuration.display_title"
                          className="material"
                          inputRef={register({
                            required: {
                              value: true,
                              message: "Please enter display title.",
                            },
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="details.ui_configuration.display_title"
                          as={<p className="error_msg"></p>}
                        />
                      </FormControl>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <DropZonePlaceHolder
                      holder={
                        displayBanner && displayBanner.formats
                          ? ENV_MEDIA_URL + displayBanner.formats.thumbnail.url
                          : ""
                      }
                      type="image"
                      fileName={displayBanner ? displayBanner.name : ""}
                      fileSizeText=""
                      labelName="Display Banner"
                      ButtonText="Browse"
                      changeselectedmedia={(media) =>
                        changeMedia("display_banner", media)
                      }
                      removeMedia={() => changeMedia("display_banner", null)}
                      category={eventCategory ? eventCategory : "all"}
                    />
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <Form.Group className="material-group for_textarea w-100 h-100">
                      <FormControl variant="outlined">
                        <InputLabel htmlFor="DisplayDescription">
                          Display Description
                        </InputLabel>
                        <OutlinedInput
                          id="DisplayDescription"
                          type="text"
                          name="details.ui_configuration.display_description"
                          className="material-outline"
                          multiline
                          rows={7}
                          inputRef={register({
                            required: false,
                          })}
                        />
                      </FormControl>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="event_active">
                      <p className="event_showcase_status">
                        Download PDF Enabled
                      </p>
                      <div className="checkbox_btns">
                        <div className="can-toggle">
                          <input
                            id="DownloadPDF"
                            type="checkbox"
                            onChange={handlePDFChange}
                            name="details.ui_configuration.download_pdf_enabled"
                            ref={register({
                              required: false,
                            })}
                          />
                          <label htmlFor="DownloadPDF">
                            <div
                              className="can-toggle__switch"
                              data-checked="On"
                              data-unchecked="Off"
                            ></div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="event_active">
                      <p className="event_showcase_status">
                        Connect to SME Enabled
                      </p>
                      <div className="checkbox_btns">
                        <div className="can-toggle">
                          <input
                            id="ConnectSME"
                            type="checkbox"
                            name="details.ui_configuration.connect_sme_enabled"
                            ref={register({
                              required: false,
                            })}
                          />
                          <label htmlFor="ConnectSME">
                            <div
                              className="can-toggle__switch"
                              data-checked="On"
                              data-unchecked="Off"
                            ></div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                {pdfChange && (
                  <Row>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <DropZonePlaceHolder
                        holder={
                          pdfFile && pdfFile.name ? "/images/pdf-card.png" : ""
                        }
                        type="application"
                        fileName={pdfFile ? pdfFile.name : ""}
                        fileSizeText=""
                        labelName="Add PDF"
                        ButtonText="Browse"
                        changeselectedmedia={(media) =>
                          changeMedia("pdf_file", media)
                        }
                        removeMedia={() => changeMedia("pdf_file", null)}
                        category={eventCategory ? eventCategory : "all"}
                      />
                    </Col>
                  </Row>
                )}
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h2 className="accorion_title">Media</h2>
            </AccordionSummary>
            <AccordionDetails>
              <div className="cover_edit_options w-100">
                <Row>
                  <Col xl={3} lg={3} md={3} sm={12}>
                    <FormGroup className="material-group">
                      <FormControl
                        variant="outlined"
                        disabled={eventDrivenType === "Live" ? true : false}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Content to be shown on TV
                        </InputLabel>
                        <Select
                          components={animatedComponents}
                          onChange={handleShownOnTvChange}
                          value={eventShownOnTv}
                          name="details.media_configuration.tv_content_type"
                          placeholder="Content to be shown on TV"
                          classnameprefix="react-select"
                        >
                          <MenuItem value={"Video"}>Video</MenuItem>
                          <MenuItem value={"Wallpaper"}>Wallpaper</MenuItem>
                        </Select>
                        {/* <OutlinedInput id="ContentTv" type="text" name="details.media_configuration.tv_content_type" value="Video" disabled className="material"
                    inputRef={register({
                      required: false,
                    })} /> */}
                      </FormControl>
                    </FormGroup>
                  </Col>
                </Row>
                {eventShownOnTv === "Video" && (
                  <Row>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <DropZonePlaceHolder
                        holder={
                          previewVideo && previewVideo.url
                            ? ENV_MEDIA_URL + previewVideo.url
                            : ""
                        }
                        type="video"
                        fileName={previewVideo ? previewVideo.name : ""}
                        fileSizeText=""
                        labelName="Preview Video"
                        ButtonText="Browse"
                        changeselectedmedia={(media) =>
                          changeMedia("preview_video", media)
                        }
                        removeMedia={() => changeMedia("preview_video", null)}
                        category={eventCategory ? eventCategory : "all"}
                      />
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <DropZonePlaceHolder
                        holder={
                          fullVideo && fullVideo.url
                            ? ENV_MEDIA_URL + fullVideo.url
                            : ""
                        }
                        type="video"
                        fileName={fullVideo ? fullVideo.name : ""}
                        fileSizeText=""
                        labelName="Full Video"
                        ButtonText="Browse"
                        changeselectedmedia={(media) =>
                          changeMedia("full_video", media)
                        }
                        removeMedia={() => changeMedia("full_video", null)}
                        category={eventCategory ? eventCategory : "all"}
                      />
                    </Col>
                  </Row>
                )}
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h2 className="accorion_title">Event Driven By</h2>
            </AccordionSummary>
            <AccordionDetails>
              <div className="cover_edit_options w-100">
                <Row
                  className={
                    eventDrivenType === "Video" ? "" : "pb-4 border_bottom_hr"
                  }
                >
                  <Col lg={6} md={6} sm={6}>
                    <FormGroup className="material-group">
                      <FormControl variant="outlined">
                        <InputLabel id="demo-simple-select-outlined-label">
                          Intro Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          name=""
                          value={eventDrivenType}
                          onChange={handleIntroChange}
                          disabled
                          label="Intro Type"
                        >
                          <MenuItem value={"Avatar"}>Avatar</MenuItem>
                          <MenuItem value={"Video"}>Video</MenuItem>
                          <MenuItem value={"Live"}>Live Presenter</MenuItem>
                        </Select>
                      </FormControl>
                    </FormGroup>
                  </Col>
                  {(eventDrivenType === "Avatar" ||
                    eventDrivenType === "Video") && (
                    <Col lg={6} md={6} sm={6}>
                      <FormGroup className="material-group mb-0">
                        <FormControl variant="outlined">
                          <InputLabel
                            htmlFor="voiceOverText"
                            className="label-caption"
                          >
                            Voice Over Text
                          </InputLabel>
                          <OutlinedInput
                            id="voiceOverText"
                            type="text"
                            multiline
                            onChange={voiceOverTextChange}
                            rows={5}
                            name="details.media_configuration.area_intro_text"
                            className="material"
                            inputRef={register({ required: false })}
                          />
                        </FormControl>
                      </FormGroup>
                      <div className="cover_text_speech_btn">
                        {(eventDrivenType === "Avatar" ||
                          eventDrivenType === "Video") && (
                          <Button
                            disabled={!textToSpeechEnabled}
                            variant="contained"
                            color="primary"
                            className="text-white"
                            onClick={() => downloadbutton("FEMALE")}
                          >
                            Convert Text to Speech
                          </Button>
                        )}
                      </div>
                    </Col>
                  )}
                  {eventDrivenType === "Video" && (
                    <Col lg={6} md={6} sm={6}>
                      {voiceOverHumanIntro && (
                        <DropZonePlaceHolder
                          holder={
                            voiceOverHumanIntro
                              ? ENV_MEDIA_URL + voiceOverHumanIntro.url
                              : ""
                          }
                          type="audio"
                          fileName={
                            voiceOverHumanIntro ? voiceOverHumanIntro.name : ""
                          }
                          fileSizeText=""
                          labelName="Voice Over"
                          ButtonText=""
                          changeselectedmedia={(media) =>
                            changeMedia("area_intro_audio_human", media)
                          }
                          category={eventCategory ? eventCategory : "all"}
                        />
                      )}
                    </Col>
                  )}
                </Row>

                {eventDrivenType === "Avatar" && (
                  <Row className="pt-4">
                    {voiceOverFemaleIntro && (
                      <React.Fragment>
                        <Col lg={6} md={6} sm={6}>
                          <FormGroup className="material-group">
                            <FormControl variant="outlined">
                              <InputLabel
                                htmlFor="AvatarFemale"
                                className="label-caption"
                              >
                                Female Avatar
                              </InputLabel>
                              <OutlinedInput
                                id="AvatarFemale"
                                type="text"
                                name=""
                                defaultValue="Claudia"
                                className="material"
                                disabled
                                inputRef={register({ required: false })}
                              />
                            </FormControl>
                          </FormGroup>
                        </Col>
                        <Col lg={6} md={6} sm={6} className="pb-4">
                          <DropZonePlaceHolder
                            holder={
                              voiceOverFemaleIntro
                                ? ENV_MEDIA_URL + voiceOverFemaleIntro.url
                                : ""
                            }
                            type="audio"
                            fileName={
                              voiceOverFemaleIntro
                                ? voiceOverFemaleIntro.name
                                : ""
                            }
                            fileSizeText=""
                            labelName="Voice Over Female"
                            ButtonText=""
                            changeselectedmedia={(media) =>
                              changeMedia("area_intro_audio_female", media)
                            }
                            category={eventCategory ? eventCategory : "all"}
                          />
                        </Col>
                      </React.Fragment>
                    )}
                    {voiceOverMaleIntro && (
                      <React.Fragment>
                        <Col lg={6} md={6} sm={6}>
                          <FormGroup className="material-group">
                            <FormControl variant="outlined">
                              <InputLabel
                                htmlFor="AvatarMale"
                                className="label-caption"
                              >
                                Male Avatar
                              </InputLabel>
                              <OutlinedInput
                                id="AvatarMale"
                                type="text"
                                name=""
                                defaultValue="Eric"
                                className="material"
                                disabled
                                inputRef={register({ required: false })}
                              />
                            </FormControl>
                          </FormGroup>
                        </Col>
                        <Col lg={6} md={6} sm={6}>
                          <DropZonePlaceHolder
                            holder={
                              voiceOverMaleIntro
                                ? ENV_MEDIA_URL + voiceOverMaleIntro.url
                                : ""
                            }
                            type="audio"
                            fileName={
                              voiceOverMaleIntro ? voiceOverMaleIntro.name : ""
                            }
                            fileSizeText=""
                            labelName="Voice Over Male"
                            ButtonText=""
                            changeselectedmedia={(media) =>
                              changeMedia("area_intro_audio_male", media)
                            }
                            category={eventCategory ? eventCategory : "all"}
                          />
                        </Col>
                      </React.Fragment>
                    )}
                  </Row>
                )}

                {eventDrivenType === "Live" && (
                  <Row className="pt-4">
                    <Col lg={12} md={12} sm={12}>
                      <p className="presenter_title">Presenter configuration</p>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                      <FormGroup className="material-group">
                        <FormControl variant="outlined">
                          <InputLabel
                            htmlFor="PresenterName"
                            className="label-caption"
                          >
                            Name
                          </InputLabel>
                          <OutlinedInput
                            id="PresenterName"
                            type="text"
                            value={
                              eventData.base_configuration[0]
                                .event_configuration.intro_configuration
                                .presenter_configuration.name
                            }
                            className="material"
                            disabled
                          />
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                      <FormGroup className="material-group">
                        <FormControl variant="outlined">
                          <InputLabel
                            htmlFor="PresenterEmail"
                            className="label-caption"
                          >
                            Email
                          </InputLabel>
                          <OutlinedInput
                            id="PresenterEmail"
                            type="email"
                            value={
                              eventData.base_configuration[0]
                                .event_configuration.intro_configuration
                                .presenter_configuration.email
                            }
                            className="material"
                            disabled
                          />
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                      <FormGroup className="material-group m-0">
                        <FormControl variant="outlined">
                          <InputLabel
                            htmlFor="WebxURL"
                            className="label-caption"
                          >
                            WebeX Meeting URL
                          </InputLabel>
                          <OutlinedInput
                            id="WebxURL"
                            type="text"
                            name="details.meeting_url"
                            className="material"
                            inputRef={register({
                              required: {
                                value: false,
                              },
                            })}
                          />
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                      <FormGroup className="material-group">
                        <FormControl variant="outlined">
                          <InputLabel
                            htmlFor="CohostName"
                            className="label-caption"
                          >
                            Cohost Name
                          </InputLabel>
                          <OutlinedInput
                            id="CohostName"
                            type="text"
                            value={
                              eventData.base_configuration[0]
                                .event_configuration.intro_configuration
                                .presenter_configuration.cohost_name
                            }
                            className="material"
                            disabled
                          />
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                      <FormGroup className="material-group">
                        <FormControl variant="outlined">
                          <InputLabel
                            htmlFor="CohostEmail"
                            className="label-caption"
                          >
                            Cohost Email
                          </InputLabel>
                          <OutlinedInput
                            id="CohostEmail"
                            type="email"
                            value={
                              eventData.base_configuration[0]
                                .event_configuration.intro_configuration
                                .presenter_configuration.cohost_email
                            }
                            className="material"
                            disabled
                          />
                        </FormControl>
                      </FormGroup>
                    </Col>
                  </Row>
                )}
              </div>
            </AccordionDetails>
          </Accordion>

          {eventDrivenType === "Live" && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <h2 className="accorion_title">Presenter Video</h2>
              </AccordionSummary>
              <AccordionDetails>
                <div className="cover_edit_options w-100">
                  <Row>
                    <Col xl={6} lg={6}>
                      <ul className="carosel_message_ul">
                        {presenterVideoList.map((item, i) => (
                          <React.Fragment>
                            <DropZonePlaceHolder
                              key={"li_" + i}
                              holder={item ? ENV_MEDIA_URL + item.url : ""}
                              type="video"
                              fileName={item ? item.name : ""}
                              fileSizeText=""
                              labelName={"Video " + (i + 1)}
                              ButtonText="Browse"
                              changeselectedmedia={(media) =>
                                changeMedia("presentation_videos", media, i)
                              }
                              removeMedia={() => removePresenterVideo(item, i)}
                              category={eventCategory ? eventCategory : "all"}
                            />
                          </React.Fragment>
                        ))}
                      </ul>
                      <p className="text-right mt-4">
                        <Button
                          variant="outlined"
                          color="primary"
                          className=""
                          onClick={() => handleAddMorePresenterVideo()}
                        >
                          Add More
                        </Button>
                      </p>
                    </Col>
                  </Row>
                </div>
              </AccordionDetails>
            </Accordion>
          )}

          {areaCard.description && areaCard.description.is_fixed_wallpaper && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <h2 className="accorion_title">Wallpaper and Branding </h2>
              </AccordionSummary>
              <AccordionDetails>
                <div className="cover_edit_options w-100">
                  <Row>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <DropZonePlaceHolder
                        holder={
                          wallpaper && wallpaper.formats
                            ? ENV_MEDIA_URL + wallpaper.formats.thumbnail.url
                            : ""
                        }
                        type="image"
                        fileName={wallpaper ? wallpaper.name : ""}
                        fileSizeText=""
                        labelName="Wallpaper"
                        ButtonText="Browse"
                        changeselectedmedia={(media) =>
                          changeMedia("wallpaper", media)
                        }
                        removeMedia={() => changeMedia("wallpaper", null)}
                        category={eventCategory ? eventCategory : "all"}
                      />
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <DropZonePlaceHolder
                        holder={
                          brandingImage && brandingImage.formats
                            ? ENV_MEDIA_URL +
                              brandingImage.formats.thumbnail.url
                            : ""
                        }
                        type="image"
                        fileName={brandingImage ? brandingImage.name : ""}
                        fileSizeText=""
                        labelName="Branding Images"
                        ButtonText="Browse"
                        changeselectedmedia={(media) =>
                          changeMedia("branding_image", media)
                        }
                        removeMedia={() => changeMedia("branding_image", null)}
                        category={eventCategory ? eventCategory : "all"}
                      />
                    </Col>
                  </Row>
                </div>
              </AccordionDetails>
            </Accordion>
          )}
        </div>
      </Container>
    </section>
  );
};
