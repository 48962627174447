import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useState, useEffect } from "react";
import { Modal, Col, Form, Row } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { MediaTagActions } from "../../actions/auth";
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import { FormGroup } from "@material-ui/core";
import LoaderGif from "../modules/loader";
import UserService from "../../services/user.service";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import category_list_json from "../../assets/json/category_list";
import { statusErrorSuccess } from "../../App";
const ENV_MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100 + "%",
    maxWidth: 100 + "%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const PreviewEdit = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [loader, setLoader] = useState(false);

  const categoryList = category_list_json;

  let category_seleted;
  if (categoryList) {
    category_seleted = categoryList.filter(
      (i) => props.previewMediaTag.industry === i.value
    );
  }
  const media_id = props.previewMediaTag.id;
  const [tag, setTag] = React.useState(
    props.previewMediaTag.tags
      ? props.previewMediaTag.tags.split(",").map(function (el) {
          return el.trim();
        })
      : []
  );
  const [eventCategory, setEventCategory] = useState(category_seleted[0]);
  const [tagArray, setTagArray] = useState([]);

  const handleChange = (event) => {
    setTag(event.target.value);
  };

  const [mediatagsLoad, setMediatagsLoad] = useState(false);

  useEffect(() => {
    if (!mediatagsLoad) {
      UserService.getShowcaseList().then(
        (response) => {
          setMediatagsLoad(true);
          if (response.status === 200) {
            // let tag_array = Object.keys(response.data).map((key) => response.data[key].tags);
            let tag_array = new Array();
            response.data.map((i) => tag_array.push(i.showcase_name.trim()));
            setTagArray(tag_array);
          }
        },
        (error) => {}
      );
    }
  });

  const dispatch = useDispatch();

  const updateTagMedia = () => {
    if (media_id) {
      setLoader(true);
      let data = {
        tags: tag.join(", "),
        industry: eventCategory.value ? eventCategory.value : "",
      };
      dispatch(MediaTagActions(media_id, data, "EDIT"))
        .then(() => {
          setLoader(false);
          props.onHide(false);
          statusErrorSuccess("editMediaSuccess", "success");
          window.location.href = "/admin/media-library";
        })
        .catch(() => {
          setLoader(false);
          statusErrorSuccess("editMediaFailed", "danger");
        });
    } else {
      let type;
      switch (props.mediaTypeValue) {
        case "image":
          type = "Image";
          break;
        case "video":
          type = "Video";
          break;
        case "pdf":
          type = "Pdf";
          break;
        case "audio":
          type = "Audio";
          break;
        default:
          type = "Other";
      }
      let data = {
        media_id: props.previewMedia.id,
        tags: tag.join(", "),
        type: type,
        industry:
          eventCategory && eventCategory.value ? eventCategory.value : "",
      };
      dispatch(MediaTagActions(0, data, "ADD"))
        .then(() => {
          setLoader(false);
          props.onHide(false);
          window.location.href = "/admin/media-library";
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };
  return (
    <React.Fragment>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="ad-area-modal upload-assets-popup edit_preview_popup"
      >
        <Modal.Header>
          <h4 className="popup_title">Edit Media</h4>
          <p className="popup_close_icon" onClick={props.onHide}>
            <img className="img-fluid" src="/images/close.svg" alt="" />
          </p>
        </Modal.Header>
        <Modal.Body>
          <div className="preview_detail">
            <div className="preview_img">
              {
                {
                  image: (
                    <React.Fragment>
                      <img
                        className="img-fluid"
                        src={
                          props.previewMedia.formats
                            ? ENV_MEDIA_URL +
                              props.previewMedia.formats.thumbnail.url
                            : ENV_MEDIA_URL + props.previewMedia.url
                        }
                        alt=""
                      />
                      <label className="file_type">
                        {props.previewMedia.mime.split("/")[0]}
                      </label>
                    </React.Fragment>
                  ),
                  video: (
                    <React.Fragment>
                      <video className="media_video_lib for_video_lib" controls>
                        <source
                          src={ENV_MEDIA_URL + props.previewMedia.url}
                          type="video/mp4"
                        />
                      </video>
                      <label className="file_type">
                        {props.previewMedia.mime.split("/")[0]}
                      </label>
                    </React.Fragment>
                  ),
                  audio: (
                    <div className="cover_audio_bg">
                      <audio className="media_video_lib" controls>
                        <source
                          src={ENV_MEDIA_URL + props.previewMedia.url}
                          type="audio/mp3"
                        />
                      </audio>
                      <label className="file_type">
                        {props.previewMedia.mime.split("/")[0]}
                      </label>
                    </div>
                  ),
                  application: (
                    <React.Fragment>
                      <img
                        className="img-fluid"
                        src="/images/pdf-card.png"
                        alt=""
                      />
                      <label className="file_type">
                        {props.previewMedia.mime.split("/")[1]}
                      </label>
                    </React.Fragment>
                  ),
                }[props.previewMedia.mime.split("/")[0]]
              }
            </div>
            <div className="middle_preview pt-4">
              <Row>
                <Col lg={12}>
                  <FormGroup className="material-group w-100">
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="outlined-age-native-simple">
                        Tag Showcase
                      </InputLabel>
                      <Select
                        labelId="outlined-age-native-simple"
                        id="outlined-age-native-simple"
                        multiple
                        value={tag}
                        className="remove_check_bg"
                        onChange={handleChange}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={(selected) => (
                          <div className={classes.chips}>
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value}
                                className={classes.chip}
                              />
                            ))}
                          </div>
                        )}
                        MenuProps={MenuProps}
                      >
                        {tagArray.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={tag.indexOf(name.trim()) > -1} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </FormGroup>
                </Col>
                <Col lg={12}>
                  <Form.Group className="material-group w-100">
                    {categoryList.length > 0 && (
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <Autocomplete
                          id="demo-simple-select-outlined-1"
                          options={categoryList}
                          value={eventCategory ? eventCategory : ""}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setEventCategory(newValue);
                            }
                          }}
                          onInputChange={(event, newInputValue) => {}}
                          getOptionLabel={(option) =>
                            option.label ? option.label : ""
                          }
                          getOptionSelected={(option, value) =>
                            option.value === value.value
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Industry Vertical"
                              variant="outlined"
                            />
                          )}
                          name="event_category"
                        />
                      </FormControl>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <div className="modal_footer flex_end_footer border-0">
              <div className="upload_section">
                <Button
                  variant="outlined"
                  color="primary"
                  className="remove_btn mr-2"
                  onClick={props.onHide}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => updateTagMedia()}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {loader === true && <LoaderGif />}
    </React.Fragment>
  );
};
export default PreviewEdit;
