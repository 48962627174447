import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from "react-router-dom";

const BreadCrumb = ({ CurrentPage, prevPageName, prevPageURL }) => {
  return (
    <Breadcrumb>
     <li className="breadcrumb-item"><Link to="/">Events - Home</Link></li>
     {prevPageName !== "" && (<li className="breadcrumb-item"><Link to={prevPageURL}>{prevPageName}</Link></li>)}
     <Breadcrumb.Item active>{CurrentPage}</Breadcrumb.Item>
    </Breadcrumb>
  );
}  
export default BreadCrumb;
