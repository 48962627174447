import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Redirect, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import BreadCrumb from "../modules/breadcrumb";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import UserService from "../../services/user.service";
import AddCollaborators from "../popups/ad-collaborators";
import CollaboratorsList from "../popups/collaborators-list";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { editEvent } from "../../actions/auth";
import LoaderGif from "../modules/loader";
import makeAnimated from "react-select/animated";
import AddShowcase from "../popups/add-showcase";
import ReadMoreAndLess from "react-read-more-less";
import { statusErrorSuccess } from "../../App";

const ENV_MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
const ENV_ADMIN_ID = process.env.REACT_APP_ADMIN_ID;
const ENV_EVENT_URL = process.env.REACT_APP_EVENT_URL;

const ViewMasterEvent = ({ match }) => {
  const {
    params: { eventType, eventId },
  } = match;
  const { session } = useSelector((state) => state.auth);
  const [eventCard, setEventCard] = useState(new Map());
  const [eventCardLoaded, setEventCardLoaded] = useState(false);
  const [finalCollabList, setFinalCollabList] = useState([]);
  const [collabIds, setCollabIds] = useState();
  const [showcaseList, setShowcaseList] = useState();
  const [showcasesLoaded, setShowcasesLoaded] = useState(false);
  const [areaImage, setareaImage] = useState({});
  const [areaImageLoaded, setAreaImageLoaded] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (!eventCardLoaded) {
      setLoader(true);
      UserService.getExperiencesList(eventId, eventType).then(
        (response) => {
          if (response.status === 200) {
            let event = {};
            if (response.data.length > 0) {
              event = response.data[0];
              setEventCard(event);
              setEventCardLoaded(true);

              UserService.getCollaboratorsList().then(
                (response1) => {
                  setLoader(false);
                  if (response1.status === 200) {
                    let collabList = event.collaborator_ids.split(",");
                    let finalList = response1.data.filter((i) =>
                      collabList.includes(i.id.toString())
                    );
                    setFinalCollabList(finalList);
                    setCollabIds(event.collaborator_ids);
                  }
                },
                (error) => {}
              );
            }
          }
        },
        (error) => {}
      );
    }

    if (!showcasesLoaded) {
      setShowcasesLoaded(true);
      UserService.getShowcaseList().then(
        (response) => {
          if (response.status === 200) {
            setShowcaseList(response.data);
          }
        },
        (error) => {}
      );
    }

    if (!areaImageLoaded) {
      UserService.getAreaList().then(
        (response) => {
          if (response.status === 200) {
            setareaImage(response.data);
            setAreaImageLoaded(true);
          }
        },
        (error) => {}
      );
    }
  });

  if (!session) {
    return <Redirect push to="/" />;
  }

  return (
    <React.Fragment>
      {eventCard.access_id && areaImageLoaded && (
        <React.Fragment>
          <Helmet>
            <title>{eventCard.event_name + "  | Infosys Living Labs"}</title>
            <meta name="description" content="" />
          </Helmet>
          <BreadCrumb
            CurrentPage={eventCard.event_name}
            prevPageName=""
            prevPageURL=""
          />
          <ViewEventBar
            session={session}
            eventCard={eventCard}
            finalCollabList={finalCollabList}
            collabIds={collabIds}
            eventType={eventType}
            loader={loader}
            setLoader={setLoader}
          />
          <ViewEventList
            session={session}
            eventId={eventId}
            eventCard={eventCard}
            showcaseList={showcaseList}
            eventType={eventType}
            areaImage={areaImage}
            loader={loader}
            setLoader={setLoader}
          />
        </React.Fragment>
      )}
      {loader && <LoaderGif />}
    </React.Fragment>
  );
};

export default ViewMasterEvent;

const ViewEventBar = ({
  session,
  eventCard,
  finalCollabList,
  collabIds,
  eventType,
  loader,
  setLoader,
}) => {
  const [adCollaborators, setADCollaborators] = useState(false);
  const [collaboratorsListPopup, setCollaboratorsListPopup] = useState(false);
  const dispatch = useDispatch();

  function shortName(name) {
    var matches = name.match(/\b(\w)/g);
    return matches.join("");
  }

  const updateEventStatus = (id, data) => {
    setLoader(true);
    dispatch(editEvent(data, eventType, id))
      .then(() => {
        setLoader(false);
        statusErrorSuccess("experienceUpdatedSuccess", "success");
        window.location.href =
          "/admin/view-master-event/" + eventType + "/" + eventCard.access_id;
      })
      .catch((error) => {
        setLoader(false);
        statusErrorSuccess("experienceUpdatedFailed", "danger");
        window.location.href =
          "/admin/view-master-event/" + eventType + "/" + eventCard.access_id;
      });
  };

  const eventArchive = (id) => {
    let eventDataStatus = {};
    eventDataStatus.event_status = "Archived";
    updateEventStatus(id, eventDataStatus);
  };

  const eventDraft = (id) => {
    let eventDataStatus = {};
    eventDataStatus.event_status = "Draft";
    updateEventStatus(id, eventDataStatus);
  };

  const eventPublish = (id) => {
    let eventDataStatus = {};
    eventDataStatus.event_status = "Published";
    updateEventStatus(id, eventDataStatus);
  };

  return (
    <React.Fragment>
      {adCollaborators === true && (
        <AddCollaborators
          eventCard={eventCard}
          setADCollaborators={setADCollaborators}
          show={adCollaborators}
          onHide={() => setADCollaborators(false)}
          finalCollabList={finalCollabList}
          eventType={eventType}
        />
      )}
      {collaboratorsListPopup === true && (
        <CollaboratorsList
          setCollaboratorsListPopup={setCollaboratorsListPopup}
          show={collaboratorsListPopup}
          onHide={() => setCollaboratorsListPopup(false)}
          finalCollabList={finalCollabList}
          collabIds={collabIds}
          eventCardId={eventCard.id}
          eventId={eventCard.access_id}
          eventType={eventType}
          createdUser={eventCard.event_collaborators[0].email}
          session={session}
        />
      )}
      <section className="event_bar_section">
        <Container fluid>
          <div className="events_cover">
            <div>
              <h4 className="events_title d-flex align-items-center">
                <span>{eventCard.event_name}</span>
              </h4>
              {eventCard.base_configuration &&
                eventCard.base_configuration.length > 0 &&
                eventCard.base_configuration[0].event_configuration && (
                  <p className="event_bar_url">
                    {eventCard.event_category ? (
                      <span className="category_span mr-2">
                        {"Category: " + eventCard.event_category}
                      </span>
                    ) : (
                      ""
                    )}
                    {eventCard.access_id &&
                      eventCard.base_configuration.length > 0 &&
                      eventCard.base_configuration[0].language && (
                        <span>
                          {ENV_EVENT_URL +
                            eventCard.access_id +
                            "&lang=" +
                            eventCard.base_configuration[0].language}
                        </span>
                      )}
                  </p>
                )}
            </div>
            <div className="view_event_right_bar">
              <div className="avtar_chip mr-3">
                <AvatarGroup
                  max={4}
                  onClick={() => setCollaboratorsListPopup(true)}
                >
                  {finalCollabList.map((collab, i) => (
                    <Avatar
                      key={"avatar_colab_" + i}
                      className={"avtar_bg_" + (i + 1)}
                      title={collab.name}
                    >
                      {shortName(collab.name)}
                    </Avatar>
                  ))}
                </AvatarGroup>
              </div>
              {session.user.role.id.toString() === ENV_ADMIN_ID &&
                session.user.email.toLowerCase() ===
                  eventCard.event_collaborators[0].email.toLowerCase() && (
                  <React.Fragment>
                    <Button
                      variant="outlined"
                      color="primary"
                      className="mr-2"
                      onClick={() => setADCollaborators(true)}
                    >
                      Add Collaborators
                    </Button>
                    {eventCard.event_status === "Published" && (
                      <React.Fragment>
                        <Button
                          variant="contained"
                          color="primary"
                          className="mr-2"
                          onClick={() => eventDraft(eventCard.id)}
                        >
                          Unpublish
                        </Button>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => eventArchive(eventCard.id)}
                        >
                          Archive
                        </Button>
                      </React.Fragment>
                    )}
                    {eventCard.event_status === "Draft" && (
                      <React.Fragment>
                        <Button
                          variant="contained"
                          color="primary"
                          className="mr-2"
                          onClick={() => eventPublish(eventCard.id)}
                        >
                          Publish
                        </Button>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => eventArchive(eventCard.id)}
                        >
                          Archive
                        </Button>
                      </React.Fragment>
                    )}
                    {eventCard.event_status === "Archived" && (
                      <React.Fragment>
                        <Button
                          variant="contained"
                          color="primary"
                          className="mr-2"
                          onClick={() => eventPublish(eventCard.id)}
                        >
                          Publish
                        </Button>
                        <Button
                          variant="outlined"
                          color="secondary"
                          className="warning_btn"
                          onClick={() => eventDraft(eventCard.id)}
                        >
                          Draft
                        </Button>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
            </div>
          </div>
        </Container>
      </section>
      {loader === true && <LoaderGif />}
    </React.Fragment>
  );
};

const ViewEventList = ({
  eventId,
  eventCard,
  showcaseList,
  eventType,
  areaImage,
  session,
  loader,
  setLoader,
}) => {
  const [checked, setChecked] = useState(false);
  const [filteredBy, setFilteredBy] = React.useState(0);
  const dispatch = useDispatch();
  // const [loader, setLoader] = useState(false);
  const [filteredAreaList, setFilteredAreaList] = useState(areaImage);

  const handleChangeFilterBy = (event) => {
    setFilteredBy(event.target.value);
    let temp = [];
    eventCard.base_configuration[0].event_configuration.area_configuration.map(
      (area, i) => {
        if (
          event.target.value === 0 ||
          (event.target.value === 10 &&
            state["area_activated_" + area.area_id] === true) ||
          (event.target.value === 20 &&
            state["area_activated_" + area.area_id] === false)
        ) {
          temp.push(area);
        }
      }
    );
    setFilteredAreaList(temp);
  };

  const animatedComponents = makeAnimated();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  // Enable Toggle
  const [state, setState] = useState();
  const [stateLoaded, setStateLoaded] = useState(false);

  if (!stateLoaded && showcaseList) {
    setStateLoaded(true);
    let data = {};
    showcaseList.map((showcase, i) => {
      data["showcaseData_" + showcase.default_area_id] = showcase;
    });
    eventCard.base_configuration[0].event_configuration.area_configuration.map(
      (area, i) => {
        data["area_" + area.area_id] = area;
        data["area_activated_" + area.area_id] = area.activated_for_event;
        let sc = showcaseList.filter((i) => i.showcase_id === area.showcase_id);
        data["showcaseData_" + area.area_id] = sc.length > 0 ? sc[0] : {};
      }
    );
    setState(data);
  }
  const handleChangeAreaActivated = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });

    let area_id = event.target.name.replace("area_activated_", "");
    let finalData = eventCard;
    eventCard.base_configuration[0].event_configuration.area_configuration.map(
      (area, i) => {
        if (area.area_id.toString() === area_id) {
          let temp = area;
          temp.activated_for_event = event.target.checked;
          finalData.base_configuration[0].event_configuration.area_configuration[
            i
          ] = temp;
        }
      }
    );

    setLoader(true);
    dispatch(editEvent(finalData, eventType, finalData.id))
      .then(() => {
        setLoader(false);
        statusErrorSuccess("experienceUpdatedSuccess", "success");
      })
      .catch((error) => {
        setLoader(false);
        statusErrorSuccess("experienceUpdatedFailed", "danger");
      });
  };

  const [showCasesPopup, setShowCasesPopup] = useState(false);
  const [eventIdName, setEventIdName] = useState();

  const handleShowcaseChange = (event) => {
    if (event.target.value === "custom") {
      setShowCasesPopup(true);
      setEventIdName(event.target.name);
    } else {
      let area_id = event.target.name.replace("showcaseData_", "");
      let finalData = eventCard;
      eventCard.base_configuration[0].event_configuration.area_configuration.map(
        (area, i) => {
          if (area.area_id.toString() === area_id) {
            let temp = area;
            temp.showcase_id = event.target.value;
            finalData.base_configuration[0].event_configuration.area_configuration[
              i
            ] = temp;
          }
        }
      );
      setLoader(true);
      dispatch(editEvent(finalData, eventType, finalData.id))
        .then(() => {
          setLoader(false);
          statusErrorSuccess("experienceUpdatedSuccess", "success");
          window.location.href =
            "/admin/view-master-event/" + eventType + "/" + eventId;
        })
        .catch((error) => {
          setLoader(false);
          statusErrorSuccess("experienceUpdatedFailed", "danger");
          window.location.href =
            "/admin/view-master-event/" + eventType + "/" + eventId;
        });
    }
  };

  const callBackShowCase = (showcaseId) => {
    let area_id = eventIdName.replace("showcaseData_", "");
    let finalData = eventCard;
    eventCard.base_configuration[0].event_configuration.area_configuration.map(
      (area, i) => {
        if (area.area_id.toString() === area_id) {
          let temp = area;
          temp.showcase_id = showcaseId;
          finalData.base_configuration[0].event_configuration.area_configuration[
            i
          ] = temp;
        }
      }
    );
    setLoader(true);
    dispatch(editEvent(finalData, eventType, finalData.id))
      .then(() => {
        setLoader(false);
        window.location.href =
          "/admin/view-master-event/" + eventType + "/" + eventId;
      })
      .catch(() => {
        setLoader(false);
        statusErrorSuccess("experienceUpdatedFailed", "danger");
        window.location.href =
          "/admin/view-master-event/" + eventType + "/" + eventId;
      });
  };

  return (
    <React.Fragment>
      {showCasesPopup === true && (
        <AddShowcase
          show={showCasesPopup}
          onHide={() => setShowCasesPopup(false)}
          showCasesAction={"ADD"}
          addCustome={true}
          callBackShowCase={callBackShowCase}
        />
      )}
      <section className="event_setting_section">
        <Container fluid>
          <div className="List_experiences_head">
            <div className="d-flex align-items-center">
              <h4 className="list_exp_title">List of Experiences</h4>
              <Link
                className="ml-4 link_text"
                to={"/admin/event-setting/" + eventType + "/" + eventCard.id}
              >
                <span className="mr-2 mb-1">
                  <img
                    className="img-fluid"
                    src="/images/settings_selected.svg"
                    alt=""
                  />
                </span>
                <span>Event Settings</span>
              </Link>
              <Link
                className="ml-4 link_text"
                to={"/admin/event-access/" + eventType + "/" + eventCard.id}
              >
                <span className="mr-2 mb-1">
                  <img
                    className="img-fluid"
                    src="/images/event_access.svg"
                    alt=""
                  />
                </span>
                <span>Event Access</span>
              </Link>
            </div>
            <div className="d-flex align-items-center">
              <FormControl className="filter_by_control">
                <span className="filter_text_caption">Filtered by:</span>
                <Select
                  value={filteredBy}
                  onChange={handleChangeFilterBy}
                  displayEmpty
                  inputProps={{ "aria-label": "Filtered by" }}
                >
                  <MenuItem value={0}>All</MenuItem>
                  <MenuItem value={10}>Enabled Areas</MenuItem>
                  <MenuItem value={20}>Disabled Area</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="cover_tabel">
            <Table responsive="xl" responsive="lg" className="layout_tabel">
              <thead>
                <tr>
                  <th className="row-1 row_name">
                    <h2 className="table_title">Area Snapshot</h2>
                  </th>
                  <th className="row-2 row_id">
                    <h2 className="table_title">Area ID</h2>
                  </th>
                  <th className="row-3 row_showcases">
                    <h2 className="table_title">Showcases</h2>
                  </th>
                  <th className="row-4 row_placard">
                    <h2 className="table_title">Name on Placard</h2>
                  </th>
                  <th className="row-5 row_description">
                    <h2 className="table_title">Area Description</h2>
                  </th>
                  <th className="row-6 row_order">
                    <h2 className="table_title">Display Order</h2>
                  </th>
                  <th className="row-7 row_enabled">
                    <h2 className="table_title">Area Enabled</h2>
                  </th>
                  {eventType === "others" && (
                    <th className="row-8 row_empty"></th>
                  )}
                </tr>
              </thead>
              <tbody>
                {eventCard.base_configuration &&
                filteredAreaList.length !== 0 &&
                state !== undefined ? (
                  <React.Fragment>
                    {filteredAreaList.map((area, i) => (
                      <React.Fragment key={"area_" + i}>
                        <tr className="spacer">
                          <td>
                            {area.area_snapshot ? (
                              <img
                                className="img-fluid table_snapshot"
                                src={
                                  area.area_snapshot.formats
                                    ? ENV_MEDIA_URL +
                                      area.area_snapshot.formats.thumbnail.url
                                    : ENV_MEDIA_URL + area.area_snapshot.url
                                }
                                alt=""
                              />
                            ) : (
                              <img
                                className="img-fluid table_snapshot"
                                src="/images/default_area_snapshot.png"
                                alt=""
                              />
                            )}
                          </td>
                          <td>
                            <p className="tabel_caption">{area.area_id}</p>
                          </td>
                          <td className="showcase_dropdown_width">
                            {state["showcaseData_" + area.area_id] ? (
                              <React.Fragment>
                                {state["showcaseData_" + area.area_id]
                                  .is_fixed_showcase ? (
                                  <React.Fragment>
                                    <h4 className="tabel_showcase_title">
                                      {
                                        state["showcaseData_" + area.area_id]
                                          .showcase_name
                                      }
                                    </h4>
                                    <p className="tabel_showcase_caption">
                                      {state["showcaseData_" + area.area_id]
                                        .is_fixed_showcase
                                        ? "This area has a fixed showcase."
                                        : ""}
                                    </p>
                                  </React.Fragment>
                                ) : (
                                  <FormControl variant="outlined">
                                    <Select
                                      components={animatedComponents}
                                      onChange={handleShowcaseChange}
                                      value={
                                        state["showcaseData_" + area.area_id]
                                          .showcase_id
                                      }
                                      name={"showcaseData_" + area.area_id}
                                      placeholder="Showcase"
                                      classnameprefix="react-select"
                                      disabled={
                                        state["area_" + area.area_id] &&
                                        session.user.role.id.toString() ===
                                          ENV_ADMIN_ID
                                          ? false
                                          : true
                                      }
                                    >
                                      {showcaseList
                                        .filter((i) => !i.is_fixed_showcase)
                                        .map((showcase_list, i) => (
                                          <MenuItem
                                            key={"showcase_option_" + i}
                                            value={showcase_list.showcase_id}
                                          >
                                            {showcase_list.showcase_name}
                                          </MenuItem>
                                        ))}
                                      {session.user.role.id.toString() ===
                                        ENV_ADMIN_ID && (
                                        <MenuItem value="custom">
                                          Custom
                                        </MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                )}
                              </React.Fragment>
                            ) : (
                              <h4 className="tabel_showcase_title create_showcase_title">
                                Please create showcase for this area.
                              </h4>
                            )}
                          </td>
                          <td className="name_on_place">
                            <p className="tabel_caption">
                              {state["area_" + area.area_id]
                                ? state["area_" + area.area_id].ui_configuration
                                    .placard_name
                                : ""}
                            </p>
                          </td>
                          <td
                            className={
                              !area.area_description
                                ? ""
                                : area.area_description.length >= 100
                                ? "if_content_is_more"
                                : ""
                            }
                          >
                            <div className="tabel_caption">
                              <ReadMoreAndLess
                                className="tabel_caption"
                                charLimit={100}
                                readMoreText="Read more"
                                readLessText="Read less"
                              >
                                {area.area_description
                                  ? area.area_description
                                  : ""}
                              </ReadMoreAndLess>
                            </div>
                          </td>
                          <td>
                            <p className="tabel_caption">
                              {state["area_" + area.area_id]
                                ? state["area_" + area.area_id].ui_configuration
                                    .display_order
                                : ""}
                            </p>
                          </td>
                          {state["area_" + area.area_id] ? (
                            <td>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      state["area_activated_" + area.area_id]
                                        ? true
                                        : false
                                    }
                                    onChange={handleChangeAreaActivated}
                                    name={"area_activated_" + area.area_id}
                                    color="primary"
                                    disabled={
                                      eventType === "master" &&
                                      session.user.role.id.toString() ===
                                        ENV_ADMIN_ID
                                        ? false
                                        : true
                                    }
                                  />
                                }
                                label={
                                  state["area_activated_" + area.area_id]
                                    ? "Enabled"
                                    : "Disabled"
                                }
                              />
                            </td>
                          ) : (
                            <td>
                              {eventType === "master" &&
                                session.user.role.id.toString() ===
                                  ENV_ADMIN_ID && (
                                  <React.Fragment>
                                    {state["showcaseData_" + area.area_id] && (
                                      <p className="add_tag text-center">
                                        <Link
                                          to={
                                            "/admin/add-experience/" +
                                            eventType +
                                            "/" +
                                            eventId +
                                            "/" +
                                            area.area_id
                                          }
                                          className="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary"
                                        >
                                          <span className="MuiButton-label">
                                            Add Showcase
                                          </span>
                                          <span className="MuiTouchRipple-root"></span>
                                        </Link>
                                      </p>
                                    )}
                                  </React.Fragment>
                                )}
                            </td>
                          )}
                          {state["area_" + area.area_id] ? (
                            <td>
                              <p className="edit_delete_tag">
                                <Link
                                  to={
                                    "/admin/edit-experience/" +
                                    eventType +
                                    "/" +
                                    eventId +
                                    "/" +
                                    area.area_id
                                  }
                                >
                                  <img
                                    className="img-fluid edit_icon"
                                    src="/images/edit.svg"
                                    alt=""
                                  />
                                </Link>
                              </p>
                            </td>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <tr>
                      <td colSpan="9">
                        <p className="tabel_caption text-center">
                          No Experiences.
                        </p>
                      </td>
                    </tr>
                  </React.Fragment>
                )}
              </tbody>
            </Table>
          </div>
        </Container>
      </section>
      {loader === true && <LoaderGif />}
    </React.Fragment>
  );
};
