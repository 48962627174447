import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useState, useEffect, useCallback } from "react";
import { Modal, Col } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import UserService from "../../services/user.service";
import { useDropzone } from "react-dropzone";
import category_list_json from "../../assets/json/category_list";
import UploadAssets from "../popups/upload-assets";
import { statusErrorSuccess } from "../../App";
import { InputLabel, OutlinedInput } from "@material-ui/core";

const ENV_UPLOAD_MEDIA_SIZE = process.env.REACT_APP_UPLOAD_MEDIA_SIZE;
const ENV_MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

const MediaLibraryPopup = (props) => {
  const [filteredBy, setFilteredBy] = React.useState(props.type);
  const [seachFilter,setSearchFilter] = React.useState("");
  const [sortedBy, setSortedBy] = React.useState("RECENT");
  const [catFilteredBy, setCatFilteredBy] = React.useState(props.category);
  const [mediaList, setMediaList] = useState([]);
  const [orgMediaList, setOrgMediaList] = useState([]);
  const [mediaListLoaded, setMediaListLoaded] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState();
  const [mediaTagList, setMediaTagList] = useState(new Map());
  const [uploadAssets, setuploadassets] = useState(false);
  const [dropzoneType, setDropzoneType] = React.useState(
    "image/JPEG, image/JPG, image/PNG, image/jpeg, image/jpg, image/png, video/*, audio/*, application/pdf"
  );
  const [uploadFileType, setUploadFileType] = useState(false);
  const [selectedMediaRadio, setselectedMediaRadio] = useState();

  useEffect(() => {
    if (!mediaListLoaded) {
      setMediaListLoaded(true);
      UserService.getMediaFiles().then(
        (response) => {
          if (response.status === 200) {
            let mapMediaData = new Map();
            response.data.map((i) => mapMediaData.set(i.id, i));
            //setMediaList(mapMediaData);
            setOrgMediaList(mapMediaData);
          }
        },
        (error) => {}
      );

      UserService.getMediaTages().then(
        (response) => {
          if (response.status === 200) {
            let mapMediaTag = new Map();
            response.data.map((i) => mapMediaTag.set(i.media_id, i));
            setMediaTagList(mapMediaTag);
          }
        },
        (error) => {}
      );
    }
  });

  // For Done
  const handleMediaLibDone = () => {
    props.changeselectedmedia(JSON.parse(selectedMedia));
    props.onHide();
  };

  const handleSearchFilter = (event) => {
    setSearchFilter(event.target.value);
  };

  const handleChangeFilterBy = (event) => {
    setFilteredBy(event.target.value);
  };

  const handleChangeSortBy = (event) => {
    setSortedBy(event.target.value);
  };

  const handleChangeCatFilterBy = (event) => {
    setCatFilteredBy(event.target.value);
  };

  const handleRadioChange = (imageFullValue) => {
    setselectedMediaRadio(JSON.parse(imageFullValue).id);
    setSelectedMedia(imageFullValue);
  };

  useEffect(() => {
    if (mediaTagList.size > 0 && orgMediaList.size > 0) {
      let tempList = [...orgMediaList];

      if (props.type !== "all")
        tempList = tempList.filter((i) =>
          i[1].mime
            .split("/")[0]
            .toLowerCase()
            .includes(filteredBy.toLowerCase())
        );

      if (props.category !== "all") {
        let temp = [...mediaTagList];
        temp = temp
          .filter((i) => i[1].industry === catFilteredBy)
          .map(function (item) {
            return item[0];
          });
        tempList = tempList.filter((i) => temp.includes(i[0]));
      }

      setMediaList(tempList);
      setOrgMediaList(tempList);
    }
  }, [mediaTagList, orgMediaList]);

  useEffect(() => {
    let tempList = [...orgMediaList];
    if (filteredBy !== "all") {
      tempList = tempList.filter((i) =>
        i[1].mime.split("/")[0].toLowerCase().includes(filteredBy.toLowerCase())
      );
    }

    if(seachFilter!=""){
      tempList = tempList.filter((obj) =>{
        const object = obj[1].name.toLowerCase();
        if(object.includes(seachFilter.toLowerCase())){
            return obj;
        }
        return;
    })
    }

    if (catFilteredBy !== "all") {
      let temp = [...mediaTagList];
      temp = temp
        .filter((i) => i[1].industry === catFilteredBy)
        .map(function (item) {
          return item[0];
        });
      tempList = tempList.filter((i) => temp.includes(i[0]));
    }

    if (sortedBy !== "RECENT") {
      switch (sortedBy) {
        case "OLDEST":
          tempList.sort((a, b) =>
            new Date(a[1].created_at) > new Date(b[1].created_at) ? 1 : -1
          );
          break;
        case "A2Z":
          tempList.sort((a, b) =>
            a[1].name.toLowerCase() > b[1].name.toLowerCase() ? 1 : -1
          );
          break;
        case "Z2A":
          tempList.sort((a, b) =>
            a[1].name.toLowerCase() < b[1].name.toLowerCase() ? 1 : -1
          );
          break;
        default:
          break;
      }
    }

    setMediaList(tempList);
  }, [filteredBy, sortedBy, catFilteredBy, seachFilter]);

  const [myFiles, setMyFiles] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setMyFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setuploadassets(true);
      // props.onHide();
    },
    [myFiles]
  );

  if (filteredBy && !uploadFileType) {
    setUploadFileType(true);
    if (filteredBy === "image") {
      setDropzoneType(
        "image/JPEG, image/JPG, image/PNG, image/jpeg, image/jpg, image/png"
      );
    } else if (filteredBy === "video") {
      setDropzoneType("video/*");
    } else if (filteredBy === "audio") {
      setDropzoneType("audio/*");
    } else if (filteredBy === "application") {
      setDropzoneType("application/pdf");
    }
  }

  const onDropRejected = useCallback((acceptedFiles) => {
    statusErrorSuccess("uploadMediaErrorMessage", "danger");
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: ENV_UPLOAD_MEDIA_SIZE,
    accept: dropzoneType,
    onDropRejected,
  });

  const files = [];

  const newmediauploaded = () => {
    UserService.getMediaFiles().then(
      (response) => {
        if (response.status === 200) {
          let mapMediaData = new Map();
          response.data.map((i) => mapMediaData.set(i.id, i));
          setOrgMediaList(mapMediaData);
        }
      },
      (error) => {}
    );
    UserService.getMediaTages().then(
      (response) => {
        if (response.status === 200) {
          let mapMediaTag = new Map();
          response.data.map((i) => mapMediaTag.set(i.media_id, i));
          setMediaTagList(mapMediaTag);
        }
      },
      (error) => {}
    );
    setuploadassets(false);
  };
  return (
    <React.Fragment>
      {uploadAssets === true && (
        <UploadAssets
          setuploadassets={setuploadassets}
          show={uploadAssets}
          onHide={() => setuploadassets(false)}
          newmediauploaded={newmediauploaded}
          uploadFiles={myFiles}
          category={props.category}
          filteredBy={filteredBy}
        />
      )}
      {uploadAssets === false && (
        <Modal
          show={props.show}
          onHide={props.onHide}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="ad-area-modal upload-assets-popup for_media_library"
        >
          <Modal.Header>
            <div>
              <h4 className="popup_title">Media Library</h4>
              <p className="selected_assets_caption">
                Manage the assets before adding them to the Media Library
              </p>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <div className="selected_assets mr-5 mb-0">
                  <Button variant="outlined" color="primary">
                    Add More Assets
                  </Button>
                </div>
              </div>
              <p className="popup_close_icon" onClick={props.onHide}>
                <img className="img-fluid" src="/images/close.svg" alt="" />
              </p>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="default_assets mb-0">
              <Col lg={12} className="p-0 pb-4 d-flex align-items-center">
              <div className="mr-3">
                <FormControl variant="outlined">
                        <InputLabel
                          htmlFor="NamePlacard"
                          className="label-caption"
                        >
                          Search
                        </InputLabel>
                        <OutlinedInput
                          id="SearchFilter"
                          type="text"
                          name="search_filter"
                          className="material"
                          value={seachFilter}
                          onChange={handleSearchFilter}
                        />
                      </FormControl>
                </div>
                <div className="mr-4">
                  <FormControl className="filter_by_control">
                    <span className="filter_text_caption">Sort by:</span>
                    <span>
                      <Select
                        value={sortedBy}
                        onChange={handleChangeSortBy}
                        displayEmpty
                        inputProps={{ "aria-label": "Sorted by" }}
                      >
                        <MenuItem value={"RECENT"}>
                          Most Recent Uploads
                        </MenuItem>
                        <MenuItem value={"OLDEST"}>Oldest Uploads</MenuItem>
                        <MenuItem value={"A2Z"}>
                          Alphabetical Order (A to Z)
                        </MenuItem>
                        <MenuItem value={"Z2A"}>
                          Alphabetical Order (Z to A)
                        </MenuItem>
                      </Select>
                    </span>
                  </FormControl>
                </div>
                <div className="mr-4">
                  <FormControl className="filter_by_control">
                    <span className="filter_text_caption">Type of Asset:</span>
                    <span>
                      <Select
                        disabled={props.type === "all" ? false : true}
                        value={filteredBy}
                        onChange={handleChangeFilterBy}
                        displayEmpty
                        inputProps={{ "aria-label": "Filtered by" }}
                      >
                        <MenuItem value={"all"}>All</MenuItem>
                        <MenuItem value={"image"}>Image</MenuItem>
                        <MenuItem value={"video"}>Video</MenuItem>
                        <MenuItem value={"audio"}>Audio</MenuItem>
                        <MenuItem value={"application"}>File</MenuItem>
                      </Select>
                    </span>
                  </FormControl>
                </div>
                <div className="filter_by_div_control">
                  <FormControl className="filter_by_control">
                    <span className="filter_text_caption">
                      Filter by Category:
                    </span>
                    <span>
                      <Select
                        disabled={props.category === "all" ? false : true}
                        value={catFilteredBy}
                        onChange={handleChangeCatFilterBy}
                        displayEmpty
                        inputProps={{ "aria-label": "Filtered by" }}
                      >
                        <MenuItem value={"all"}>All</MenuItem>
                        {category_list_json.map((category, i) => (
                          <MenuItem key={i + "category"} value={category.value}>
                            {category.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </span>
                  </FormControl>
                </div>
              </Col>
              <ul
                className={
                  mediaList.length <= 4
                    ? "media_library_ul"
                    : "media_library_ul media_lib_with_height"
                }
              >
                {files}
                {Array.from(mediaList).map(([index, media], i) => (
                  <li
                    className={
                      selectedMediaRadio === media.id
                        ? `media_library_item selectedImage`
                        : `media_library_item`
                    }
                    key={media.id}
                    onClick={() => handleRadioChange(JSON.stringify(media))}
                  >
                    <div className="cover_uploaded_img">
                      {media.mime.split("/")[0] === "image" ? (
                        <React.Fragment>
                          <img
                            className="img-fluid"
                            src={
                              media.formats
                                ? ENV_MEDIA_URL + media.formats.thumbnail.url
                                : ENV_MEDIA_URL + media.url
                            }
                            alt=""
                          />
                          <label className="file_type">
                            {media.mime.split("/")[0]}
                          </label>
                        </React.Fragment>
                      ) : media.mime.split("/")[0] === "application" ? (
                        <React.Fragment>
                          <img
                            className="img-fluid"
                            src="/images/pdf-card.png"
                            alt=""
                          />
                          <label className="file_type">
                            {media.mime.split("/")[1]}
                          </label>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <video className="video_bg_cover" controls>
                            <source
                              src={ENV_MEDIA_URL + media.url}
                              type="video/mp4"
                            />
                          </video>
                          <label className="file_type">
                            {media.mime.split("/")[0]}
                          </label>
                        </React.Fragment>
                      )}
                      {/* <div className="fixed_radio_top">
                    <input
                      type="radio"
                      className="radio_btn"
                      color="primary"
                      value={JSON.stringify(media)}
                      name="selectedImage"
                      checked={selectedMediaRadio === media.id}
                      onChange={(e) => handleRadioChange(e)}
                  />
                </div> */}
                      {/* <div className="fixed_radio_top">
                  <Radio
                    type="radio"
                    color="primary"
                    value={JSON.stringify(media)}
                    name="selectedImage"
                    onChange={(e) => handleRadioChange(e)}
                  />
                </div> */}
                    </div>
                    <h5 className="uploader_name">{media.name}</h5>
                    <p className="uploader_file_size">
                      {(media.size / 1024).toFixed(2) + " MB"}
                    </p>
                    {mediaTagList.get(media.id) && (
                      <React.Fragment>
                        <p className="category_label mb-3 mt-2">
                          {mediaTagList.get(media.id).industry
                            ? "Category: " + mediaTagList.get(media.id).industry
                            : ""}
                        </p>
                        <p className="tag_label">
                          {mediaTagList
                            .get(media.id)
                            .tags.split(", ")
                            .map(
                              (tag, i) =>
                                tag !== "" && (
                                  <span
                                    key={i + "label_tag"}
                                    className="label_tag_span"
                                  >
                                    {tag}
                                  </span>
                                )
                            )}
                        </p>
                      </React.Fragment>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div className="modal_footer flex_end_footer">
              <div className="upload_section">
                <Button
                  variant="outlined"
                  color="primary"
                  className="remove_btn mr-2"
                  onClick={props.onHide}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleMediaLibDone()}
                >
                  Done
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  );
};
export default MediaLibraryPopup;
