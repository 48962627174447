import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Modal, Form, Col, Row } from "react-bootstrap";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import DropZonePlaceHolder from "../modules/dropzone-placeholder";
import { useDispatch } from "react-redux";
import { areaActions } from "../../actions/auth";
import LoaderGif from "../modules/loader";

import { statusErrorSuccess } from "../../App";

const ENV_MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

const AddArea = (props) => {
  let editAreaData;
  let areaChecked = false;
  let areaId;
  let getAreaSnapshot;
  if (props.areaaction === "EDIT") {
    editAreaData = props.arearow;
    areaChecked = props.arearow.is_fixed_wallpaper;
    areaId = props.arearow.id;
    getAreaSnapshot = editAreaData.area_snapshot;
  }
  const { register, handleSubmit, setValue, errors } = useForm({
    defaultValues: editAreaData,
  });

  const [checked, setChecked] = useState(areaChecked);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [areaSnapshot, setAreaSnapshot] = useState(getAreaSnapshot);
  const changeMedia = (changeField, media) => {
    switch (changeField) {
      case "area_snapshot":
        setAreaSnapshot(media);
        setValue("area_snapshot", media);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    register("area_snapshot");
  });

  const onSubmitAreaAction = async (data) => {
    data.is_fixed_wallpaper = checked;
    if (data.area_snapshot && data.area_snapshot.id) {
      data.area_snapshot = data.area_snapshot.id;
    }
    setLoader(true);
    dispatch(areaActions(areaId, data, props.areaaction))
      .then(() => {
        if (props.areaaction === "EDIT") {
          statusErrorSuccess("editAreaSuccess", "success");
        } else {
          statusErrorSuccess("addAreaSuccess", "success");
        }
        setLoader(false);
        props.onHide();
        //  window.location.href = "/admin/areas";
      })
      .catch((error) => {
        if (props.areaaction === "EDIT") {
          statusErrorSuccess("editAreaFailed", "danger");
        } else {
          statusErrorSuccess("addAreaFailed", "danger");
        }
        setLoader(false);
      });
  };

  return (
    <React.Fragment>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="ad-area-modal area_global_popup add-showcase-modal"
      >
        <Modal.Header>
          {props.areaaction === "EDIT" ? (
            <h4 className="popup_title">Edit Area</h4>
          ) : (
            <h4 className="popup_title">Add Area</h4>
          )}
          <p className="popup_close_icon" onClick={props.onHide}>
            <img className="img-fluid" src="/images/close.svg" alt="" />
          </p>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmitAreaAction)}>
            <Row>
              <Col xl={4} lg={4}>
                <FormGroup className="material-group">
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="AreaNumber " className="label-caption">
                      Area Number
                    </InputLabel>
                    <OutlinedInput
                      id="AreaNumber"
                      type="number"
                      name="area_id"
                      className="material"
                      min="0"
                      onChange={(event) =>
                        event.target.value < 0
                          ? (event.target.value = 0)
                          : event.target.value
                      }
                      inputRef={register({
                        required: true,
                      })}
                    />
                    {errors.area_id?.type === "required" && (
                      <p className="error_msg">Please enter area number.</p>
                    )}
                  </FormControl>
                </FormGroup>
              </Col>
              <Col xl={8} lg={8} md={8} sm={8}>
                <FormGroup className="material-group">
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="AreaName " className="label-caption">
                      Area Name
                    </InputLabel>
                    <OutlinedInput
                      id="AreaName"
                      type="text"
                      name="area_name"
                      className="material"
                      inputRef={register({
                        required: true,
                      })}
                    />
                    {errors.area_name?.type === "required" && (
                      <p className="error_msg">Please enter area name.</p>
                    )}
                  </FormControl>
                </FormGroup>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <FormGroup className="material-group">
                  <FormControl variant="outlined">
                    <InputLabel
                      htmlFor="AreaDescription "
                      className="label-caption"
                    >
                      Area Description
                    </InputLabel>
                    <OutlinedInput
                      id="AreaDescription"
                      type="text"
                      name="area_description"
                      className="material"
                      multiline
                      rows={5}
                      inputRef={register({
                        required: false,
                      })}
                    />
                  </FormControl>
                </FormGroup>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <DropZonePlaceHolder
                  holder={
                    areaSnapshot
                      ? areaSnapshot.formats
                        ? ENV_MEDIA_URL + areaSnapshot.formats.thumbnail.url
                        : ENV_MEDIA_URL + areaSnapshot.url
                      : ""
                  }
                  type="image"
                  fileName={areaSnapshot ? areaSnapshot.name : ""}
                  fileSizeText=""
                  labelName="Area Snapshot"
                  ButtonText="Browse"
                  changeselectedmedia={(media) =>
                    changeMedia("area_snapshot", media)
                  }
                  removeMedia={() => changeMedia("area_snapshot", null)}
                  category={"all"}
                />
              </Col>
              <Col lg={6} md={6} sm={6} className="d-block checkbox_label">
                <FormGroup className="material-group d-inline-block">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        name="is_fixed_wallpaper"
                        color="primary"
                        value={true}
                      />
                    }
                    label="Allow / Disallow branding / wallpaper options."
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col lg={12} className="mr-auto text-right">
                <FormGroup className="material-group d-inline-block">
                  {props.areaaction === "EDIT" ? (
                    <Button type="submit" variant="contained" color="primary">
                      Update
                    </Button>
                  ) : (
                    <Button type="submit" variant="contained" color="primary">
                      Create
                    </Button>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      {loader === true && <LoaderGif />}
    </React.Fragment>
  );
};
export default AddArea;
