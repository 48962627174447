import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { Helmet } from "react-helmet";
import BreadCrumb from "../modules/breadcrumb";
import AddEventAccessPopup from "../popups/add-event-access";
import moment from "moment-timezone";
import LoaderGif from "../modules/loader";
import UserService from "../../services/user.service";
import DeleteConfirmation from "../popups/delete-confirmation";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useMediaQuery } from "react-responsive";
import ReactHtmlParser from "react-html-parser";

const ENV_ADMIN_ID = process.env.REACT_APP_ADMIN_ID;
const ENV_VPN_CONNECT = process.env.REACT_APP_VPN_CONNECT;

const EventAccess = ({ match }) => {
  const {
    params: { eventType, eventId },
  } = match;
  const { session } = useSelector((state) => state.auth);

  const [eventAccess, setEventAccess] = useState();
  const [eventAccessList, setEventAccessList] = useState(new Map());
  const [eventAccessArray, setEventAccessArray] = useState();
  const [eventAccessListLength, setEventAccessListLength] = useState(null);
  const [eventAccessListLoaded, setEventAccessListLoaded] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (!eventAccessListLoaded) {
      setEventAccessListLoaded(true);
      setLoader(true);
      UserService.getEventSetting(eventId, eventType).then(
        (response) => {
          setLoader(false);
          if (response.status === 200) {
            setEventAccess(response.data);
            setEventAccessArray(response.data.event_users);
            var APIData = response.data.event_users;
            let APIDataMap = new Map();
            APIData.map((i) => APIDataMap.set(i.id, i));
            setEventAccessList(APIDataMap);
            setEventAccessListLength(APIData.length);
          }
        },
        (error) => {}
      );
    }
  });

  if (!session) {
    return <Redirect push to="/" />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Event Access | Infosys Living Labs</title>
        <meta name="description" content="" />
      </Helmet>
      {eventAccess && (
        <React.Fragment>
          <BreadCrumb
            CurrentPage="Event Access"
            prevPageName={eventAccess.event_name}
            prevPageURL={
              eventType === "master" &&
              session.user.role.id.toString() === ENV_ADMIN_ID
                ? "/admin/view-master-event/" +
                  eventType +
                  "/" +
                  eventAccess.access_id
                : "/admin/view-event/" + eventType + "/" + eventAccess.access_id
            }
          />
          <EventAccessBar
            eventAccess={eventAccess}
            eventAccessList={eventAccessList}
            eventAccessArray={eventAccessArray}
            eventAccessListLength={eventAccessListLength}
            eventType={eventType}
            eventId={eventId}
            session={session}
            loader={loader}
            setLoader={setLoader}
          />
          <div className="collaborator_admin_msg event_user_info">
            {ReactHtmlParser(ENV_VPN_CONNECT)}
          </div>
          <AccessContent
            eventAccess={eventAccess}
            eventAccessList={eventAccessList}
            eventAccessArray={eventAccessArray}
            eventAccessListLength={eventAccessListLength}
            eventType={eventType}
            eventId={eventId}
            session={session}
            loader={loader}
            setLoader={setLoader}
          />
        </React.Fragment>
      )}
      {loader && <LoaderGif />}
    </React.Fragment>
  );
};

export default EventAccess;

const EventAccessBar = ({
  eventAccess,
  eventAccessList,
  eventAccessArray,
  eventAccessListLength,
  eventType,
  eventId,
  session,
}) => {
  const [addEventAccess, setAddEventAccess] = useState(false);
  const [eventAccessOrgValue, setEventAccessOrgValue] = useState(eventAccess);

  return (
    <React.Fragment>
      {addEventAccess && (
        <AddEventAccessPopup
          setAddEventAccess={setAddEventAccess}
          show={addEventAccess}
          areaaction={"ADD"}
          eventAccessOrgValue={eventAccessOrgValue}
          eventType={eventType}
          onHide={() => setAddEventAccess(false)}
        />
      )}
      <section className="event_bar_section">
        <Container fluid>
          <div className="events_cover">
            <div className="d-flex align-items-center">
              <div>
                <h4 className="events_title">Event Access</h4>
                <p className="event_bar_url">
                  {eventAccessListLength === null ? "0" : eventAccessListLength}{" "}
                  Members
                </p>
              </div>
            </div>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setAddEventAccess(true)}
            >
              Add Member
            </Button>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

const AccessContent = ({
  eventAccess,
  eventAccessList,
  eventAccessArray,
  eventAccessListLength,
  eventType,
  eventId,
  session,
  loader,
  setLoader,
}) => {
  // const [loader, setLoader] = useState(false);
  const [deleteConfirmationState, setDeleteConfirmationState] = useState(false);
  const [deleteConfirmationValue, setDeleteConfirmationValue] = useState("");
  const [eventAccessOrgValue, setEventAccessOrgValue] = useState(eventAccess);
  // const [eventAccessType, setEventAccessType] = useState(eventId);

  const [eventStatusMessage, setEventStatusMessage] = useState("Updated");
  const dispatch = useDispatch();

  // delete
  const deleteCon = (selected_user) => {
    setDeleteConfirmationValue(selected_user);
    setEventAccessOrgValue(eventAccess);
    setDeleteConfirmationState(true);
  };

  const optionsPage = {
    sizePerPage: 10,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: true,
  };

  function iconsEdit(content, row, rowIndex, formatExtraData) {
    return (
      <React.Fragment>
        <p className="text-right delete_row">
          <img
            className="img-fluid"
            src="/images/delete.svg"
            alt=""
            onClick={() => deleteCon(row)}
          />
        </p>
      </React.Fragment>
    );
  }

  const isDesktopOrLaptop = useMediaQuery({
    query: "(max-device-width: 1400px)",
  });

  const columns = [
    {
      dataField: "user_name",
      text: "Name",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return isDesktopOrLaptop ? { width: "150px" } : { width: "250px" };
      },
    },
    {
      dataField: "user_email",
      text: "Email Address",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return isDesktopOrLaptop ? { width: "250px" } : { width: "300px" };
      },
    },
    {
      dataField: "iconEdit",
      text: "",
      formatter: iconsEdit,
      headerStyle: (colum, colIndex) => {
        return isDesktopOrLaptop ? { width: "130px" } : { width: "140px" };
      },
    },
  ];

  return (
    <React.Fragment>
      {deleteConfirmationState === true && (
        <DeleteConfirmation
          setDeleteConfirmationState={setDeleteConfirmationState}
          show={deleteConfirmationState}
          onHide={() => setDeleteConfirmationState(false)}
          delete={() => deleteCon()}
          deleteConfirmationValue={deleteConfirmationValue}
          eventAccessOrgValue={eventAccessOrgValue}
          page="event-access-page"
          eventType={eventType}
          deleteMessage={`Are you sure you want to delete this member? /n This action will delete the member from this event.`}
        />
      )}
      {eventAccessArray && (
        <section className="media_tag_section">
          <Container fluid>
            <div className="cover_tabel_area media_tag_table">
              <BootstrapTable
                key={"event_access_sno_"}
                bootstrap4
                keyField="id"
                data={eventAccessArray}
                columns={columns}
                pagination={paginationFactory(optionsPage)}
              />
            </div>
          </Container>
        </section>
      )}
      {loader === true && <LoaderGif />}
    </React.Fragment>
  );
};
