import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Redirect } from "react-router-dom";
import { Container, Form, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Button from "@material-ui/core/Button";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormGroup from "@material-ui/core/FormGroup";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import BreadCrumb from "../modules/breadcrumb";
import UserService from "../../services/user.service";
import moment from "moment-timezone";
import LoaderGif from "../modules/loader";
import { Base64 } from "js-base64";
import { googleTextToSpeech, uploadMedia } from "../../actions/auth";
import makeAnimated from "react-select/animated";
import DropZonePlaceHolder from "../modules/dropzone-placeholder";
import { editEvent } from "../../actions/auth";
import DeleteConfirmation from "../popups/delete-confirmation";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { ErrorMessage } from "@hookform/error-message";
import { statusErrorSuccess } from "../../App";

import category_list_json from "../../assets/json/category_list";
import timezone_list_json from "../../assets/json/timezone_list";
import cms_button_details_json from "../../assets/json/cms_button_details";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

const ENV_MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
const ENV_EVENT_URL = process.env.REACT_APP_EVENT_URL;
const ENV_DEFAULT_LANG = process.env.REACT_APP_DEFAULT_LANG;
const ENV_ADMIN_ID = process.env.REACT_APP_ADMIN_ID;

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 250,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const EventSetting = ({ match }) => {
  const {
    params: { eventType, eventId },
  } = match;
  const { session } = useSelector((state) => state.auth);
  const [eventSetting, setEventSetting] = useState();
  const [eventSettingLoaded, setEventSettingLoaded] = useState(false);

  const [industryZoneList, setIndustryZoneList] = useState(0);
  const [industryZoneListLoaded, setIndustryZoneListLoaded] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (!eventSettingLoaded) {
      setEventSettingLoaded(true);
      setLoader(true);
      UserService.getEventSetting(eventId, eventType).then(
        (response) => {
          setLoader(false);
          if (response.status === 200) {
            let events_head = response.data;
            // Modify data as per UI needs
            const tzone = events_head.base_configuration[0].event_configuration
              .event_details_configuration.timezone
              ? events_head.base_configuration[0].event_configuration
                  .event_details_configuration.timezone
              : moment.tz.guess();
            const sd =
              events_head.base_configuration[0].event_configuration
                .event_details_configuration.start_date;
            events_head.base_configuration[0].event_configuration.event_details_configuration.start_date =
              sd
                ? moment.tz(sd, tzone).format("D MMM, yyyy hh:mm a")
                : moment().format("D MMM, yyyy hh:mm a");
            const ed =
              events_head.base_configuration[0].event_configuration
                .event_details_configuration.end_date;
            events_head.base_configuration[0].event_configuration.event_details_configuration.end_date =
              ed
                ? moment.tz(ed, tzone).format("D MMM, yyyy hh:mm a")
                : moment().format("D MMM, yyyy hh:mm a");

            const accessConfig =
              events_head.base_configuration[0].event_configuration
                .access_configuration;
            if (accessConfig) {
              events_head.base_configuration[0].event_configuration.access_configuration.allowed_emails =
                accessConfig.allowed_emails
                  ? accessConfig.allowed_emails.replace(/,/g, "\n")
                  : "";
              events_head.base_configuration[0].event_configuration.access_configuration.allowed_email_domains =
                accessConfig.allowed_email_domains
                  ? accessConfig.allowed_email_domains.replace(/,/g, "\n")
                  : "";
              events_head.base_configuration[0].event_configuration.access_configuration.disallowed_emails =
                accessConfig.disallowed_emails
                  ? accessConfig.disallowed_emails.replace(/,/g, "\n")
                  : "";
              events_head.base_configuration[0].event_configuration.access_configuration.disallowed_email_domains =
                accessConfig.disallowed_email_domains
                  ? accessConfig.disallowed_email_domains.replace(/,/g, "\n")
                  : "";
            }
            setEventSetting(events_head);
          }
        },
        (error) => {}
      );
    }

    if (!industryZoneListLoaded) {
      setIndustryZoneListLoaded(true);
      UserService.getIndustryZoneList().then(
        (response) => {
          if (response.status === 200) {
            setIndustryZoneList(response.data);
          }
        },
        (error) => {}
      );
    }
  });

  if (!session) {
    return <Redirect push to="/" />;
  }

  return (
    <React.Fragment>
      {eventSetting && industryZoneList && (
        <React.Fragment>
          <EventSettingForm
            eventSetting={eventSetting}
            eventType={eventType}
            eventId={eventId}
            session={session}
            industryZoneList={industryZoneList}
            loader={loader}
            setLoader={setLoader}
          />
        </React.Fragment>
      )}
      {loader && <LoaderGif />}
    </React.Fragment>
  );
};

export default EventSetting;

const EventSettingForm = ({
  eventSetting,
  eventType,
  eventId,
  session,
  industryZoneList,
  loader,
  setLoader,
}) => {
  const { register, handleSubmit, setValue, errors } = useForm({
    defaultValues: eventSetting,
  });
  const dispatch = useDispatch();
  const [introType, setIntroType] = React.useState(
    eventSetting.base_configuration[0].event_configuration.intro_configuration
      .intro_type
  );
  const [avatarChange, setAvatarChange] = React.useState("");
  const [languageVal, setLanguageVal] = useState(
    eventSetting.base_configuration[0].language
      ? eventSetting.base_configuration[0].language
      : ENV_DEFAULT_LANG
  );
  const [eventIndustryZone, setEventIndustryZone] = useState(
    eventSetting.iez_id ? eventSetting.iez_id : "0"
  );

  const [eventStatus, setEventStatus] = useState(eventSetting.event_status);
  const [eventStatusMessage, setEventStatusMessage] = useState("Updated");
  const carousel_messages = eventSetting.base_configuration[0]
    .event_configuration.loading_configuration
    ? eventSetting.base_configuration[0].event_configuration
        .loading_configuration.carousel_messages
    : undefined;
  const carouselMessages = carousel_messages
    ? carousel_messages.substring(1, carousel_messages.length - 1).split(">, <")
    : [];
  const [carouselList, setCarouselList] = useState(carouselMessages);
  const [validationState, setValidationState] = useState(false);

  const setAvatar = (avatar_val) => {
    setAvatarChange(avatar_val);
  };

  const setIntro = (intro_val) => {
    setIntroType(intro_val);
  };

  const eventStatusChange = (eventStatus, messageStatus) => {
    setEventStatus(eventStatus);
    setEventStatusMessage(messageStatus);
  };

  const onSubmitEditEventSetting = async (data) => {
    const orgIntroType =
      eventSetting.base_configuration[0].event_configuration.intro_configuration
        .intro_type;
    let finalData = { ...eventSetting };
    let t1 = { ...eventSetting.base_configuration[0] };
    let t2 = {
      ...t1.event_configuration.access_configuration,
      ...data.base_configuration[0].event_configuration.access_configuration,
    };
    t1.event_configuration.access_configuration = t2;

    const accessConfig = t1.event_configuration.access_configuration;
    /*if (accessConfig) {
      t1.event_configuration.access_configuration.allowed_emails = accessConfig ? accessConfig.allowed_emails.replace(/\n/g, ",") : null;
      t1.event_configuration.access_configuration.allowed_email_domains = accessConfig ? accessConfig.allowed_email_domains.replace(/\n/g, ",") : null;
      t1.event_configuration.access_configuration.disallowed_emails = accessConfig ? accessConfig.disallowed_emails.replace(/\n/g, ",") : null;
      t1.event_configuration.access_configuration.disallowed_email_domains = accessConfig ? accessConfig.disallowed_email_domains.replace(/\n/g, ",") : null;
    }*/

    let t3 = {
      ...t1.event_configuration.event_details_configuration,
      ...data.base_configuration[0].event_configuration
        .event_details_configuration,
    };
    t1.event_configuration.event_details_configuration = t3;

    let t4 = {
      ...t1.event_configuration.loading_configuration,
      ...data.base_configuration[0].event_configuration.loading_configuration,
    };
    t1.event_configuration.loading_configuration = t4;
    t1.event_configuration.loading_configuration.carousel_messages =
      carouselList.length > 0 ? "<" + carouselList.join(">, <") + ">" : null;

    let t5 = {
      ...t1.event_configuration.ui_configuration,
      ...data.base_configuration[0].event_configuration.ui_configuration,
    };

    t1.event_configuration.ui_configuration = t5;
    t1.event_configuration.meeting_url =
      data.base_configuration[0].event_configuration.meeting_url;
    t1.event_configuration.intro_configuration.intro_type = introType;
    t1.language = languageVal;

    finalData.event_name = data.event_name;
    finalData.iez_id = data.iez_id;
    finalData.event_description = data.event_description;
    finalData.base_configuration = [t1];
    finalData.event_category = data.event_category
      ? data.event_category
      : eventSetting.event_category;

    let temp =
      data.base_configuration[0].event_configuration
        .event_details_configuration;
    let temp1 =
      data.base_configuration[0].event_configuration.intro_configuration;

    // event start and end date
    var tzone = temp.timezone
      ? temp.timezone
      : eventSetting.base_configuration[0].event_configuration
          .event_details_configuration.timezone;
    if (!tzone) {
      tzone = moment.tz.guess();
    }

    let startDate = temp.start_date
      ? temp.start_date
      : moment
          .tz(
            eventSetting.base_configuration[0].event_configuration
              .event_details_configuration.start_date,
            tzone
          )
          .format("D MMM, yyyy hh:mm a");
    let endDate = temp.end_date
      ? temp.end_date
      : moment
          .tz(
            eventSetting.base_configuration[0].event_configuration
              .event_details_configuration.end_date,
            tzone
          )
          .format("D MMM, yyyy hh:mm a");

    finalData.base_configuration[0].event_configuration.event_details_configuration.start_date =
      moment.tz(startDate, "D MMM, yyyy hh:mm a", tzone).toISOString();
    finalData.base_configuration[0].event_configuration.event_details_configuration.end_date =
      moment.tz(endDate, "D MMM, yyyy hh:mm a", tzone).toISOString();
    finalData.base_configuration[0].event_configuration.event_details_configuration.timezone =
      tzone;
    finalData.base_configuration[0].event_configuration.event_details_configuration.event_url =
      eventSetting.access_id + "&lang=" + languageVal;
    finalData.base_configuration[0].event_configuration.event_details_configuration.event_name =
      data.event_name;
    finalData.base_configuration[0].event_configuration.event_details_configuration.event_description =
      data.event_description;

    /** Event Image */
    if (temp.event_image && temp.event_image.id) {
      finalData.base_configuration[0].event_configuration.event_details_configuration.event_image =
        temp.event_image.id;
    } else {
      finalData.base_configuration[0].event_configuration.event_details_configuration.event_image =
        null;
    }

    if (introType === "Live") {
      // Live Presenter
      finalData.base_configuration[0].event_configuration.intro_configuration.presenter_configuration =
        temp1.presenter_configuration;

      // Reset Video and Audio
      finalData.base_configuration[0].event_configuration.intro_configuration.video_configuration =
        null;
      finalData.base_configuration[0].event_configuration.intro_configuration.avatar_configuration =
        null;
    } else if (introType === "Video") {
      // Video
      if (temp1.video_configuration && temp1.video_configuration.intro_video) {
        if (
          !finalData.base_configuration[0].event_configuration
            .intro_configuration.video_configuration
        )
          finalData.base_configuration[0].event_configuration.intro_configuration.video_configuration =
            {
              intro_video: {},
            };
        finalData.base_configuration[0].event_configuration.intro_configuration.video_configuration.intro_video =
          temp1.video_configuration.intro_video.id;
      } else {
        if (
          !finalData.base_configuration[0].event_configuration
            .intro_configuration.video_configuration
        ) {
          finalData.base_configuration[0].event_configuration.intro_configuration.video_configuration =
            {};
        }
        finalData.base_configuration[0].event_configuration.intro_configuration.video_configuration.intro_video =
          null;
      }

      // Reset Live and Audio
      finalData.base_configuration[0].event_configuration.intro_configuration.presenter_configuration =
        null;
      finalData.base_configuration[0].event_configuration.intro_configuration.avatar_configuration =
        null;
    } else if (introType === "Avatar") {
      // Avatar
      finalData.base_configuration[0].event_configuration.intro_configuration.avatar_configuration =
        {};
      finalData.base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_text =
        temp1.avatar_configuration.voice_over_text;
      if (temp1.avatar_configuration.voice_over_female) {
        finalData.base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_female =
          temp1.avatar_configuration.voice_over_female.id;
      } else {
        finalData.base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_female =
          null;
      }
      if (temp1.avatar_configuration.voice_over_male) {
        finalData.base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_male =
          temp1.avatar_configuration.voice_over_male.id;
      } else {
        finalData.base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_male =
          null;
      }

      // Reset Live and Video
      finalData.base_configuration[0].event_configuration.intro_configuration.presenter_configuration =
        null;
      finalData.base_configuration[0].event_configuration.intro_configuration.video_configuration =
        null;
    }

    /** Reset Area Image */
    let areaConfigs =
      finalData.base_configuration[0].event_configuration.area_configuration;
    areaConfigs.map((area, i) => {
      if (area.branding_images.length > 0) {
        areaConfigs[i].branding_images[0] = area.branding_images[0]
          ? area.branding_images[0].id
          : area.branding_images[0];
      }
      if (area.media_configuration) {
        if (introType === orgIntroType) {
          areaConfigs[i].media_configuration.area_intro_audio_female = area
            .media_configuration.area_intro_audio_female
            ? area.media_configuration.area_intro_audio_female.id
            : area.media_configuration.area_intro_audio_female;
          areaConfigs[i].media_configuration.area_intro_audio_male = area
            .media_configuration.area_intro_audio_male
            ? area.media_configuration.area_intro_audio_male.id
            : area.media_configuration.area_intro_audio_male;
          areaConfigs[i].media_configuration.area_intro_audio_human = area
            .media_configuration.area_intro_audio_human
            ? area.media_configuration.area_intro_audio_human.id
            : area.media_configuration.area_intro_audio_human;
          areaConfigs[i].media_configuration.area_intro_text = area
            .media_configuration.area_intro_text
            ? area.media_configuration.area_intro_text
            : null;
        } else {
          areaConfigs[i].media_configuration.area_intro_audio_female = null;
          areaConfigs[i].media_configuration.area_intro_audio_male = null;
          areaConfigs[i].media_configuration.area_intro_audio_human = null;
          areaConfigs[i].media_configuration.area_intro_text = "";
        }
        if (area.media_configuration.pdf_file) {
          areaConfigs[i].media_configuration.pdf_file =
            area.media_configuration.pdf_file.id;
        }
        if (area.media_configuration.video_configuration) {
          areaConfigs[i].media_configuration.video_configuration.preview_video =
            area.media_configuration.video_configuration.preview_video
              ? area.media_configuration.video_configuration.preview_video.id
              : area.media_configuration.video_configuration.preview_video;
          areaConfigs[i].media_configuration.video_configuration.full_video =
            area.media_configuration.video_configuration.full_video
              ? area.media_configuration.video_configuration.full_video.id
              : area.media_configuration.video_configuration.full_video;
        }
        if (
          area.media_configuration.wallpaper_configuration &&
          area.media_configuration.wallpaper_configuration.wallpaper
        ) {
          areaConfigs[i].media_configuration.wallpaper_configuration.wallpaper =
            area.media_configuration.wallpaper_configuration.wallpaper.id;
        }
      }

      if (area.ui_configuration && area.ui_configuration.display_banner) {
        areaConfigs[i].ui_configuration.display_banner =
          area.ui_configuration.display_banner.id;
      }
    });
    finalData.base_configuration[0].event_configuration.area_configuration =
      areaConfigs;

    delete finalData.created_at;
    delete finalData.updated_at;
    delete finalData.published_at;

    finalData.iez_id = eventIndustryZone;
    finalData.event_status = eventStatus;
    /*let jsonString = JSON.stringify(finalData);
    jsonString = jsonString.replace(/\"id\":[0-9]+\,/gi, "");
    finalData = JSON.parse(jsonString); */

    setLoader(true);
    dispatch(editEvent(finalData, eventType, eventId))
      .then(() => {
        setLoader(false);
        statusErrorSuccess("event" + eventStatusMessage + "Success", "success");
        window.location.href =
          "/admin/event-setting/" + eventType + "/" + eventId;
      })
      .catch(() => {
        setLoader(false);
        statusErrorSuccess("event" + eventStatusMessage + "Failed", "danger");
      });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Event Settings | Infosys Living Labs</title>
        <meta name="description" content="" />
      </Helmet>
      {languageVal && (
        <React.Fragment>
          <BreadCrumb
            CurrentPage="Event Settings"
            prevPageName={eventSetting.event_name}
            prevPageURL={
              eventType === "master" &&
              session.user.role.id.toString() === ENV_ADMIN_ID
                ? "/admin/view-master-event/" +
                  eventType +
                  "/" +
                  eventSetting.access_id
                : "/admin/view-event/" +
                  eventType +
                  "/" +
                  eventSetting.access_id
            }
          />
          <Form onSubmit={handleSubmit(onSubmitEditEventSetting)}>
            <EventSettingBar
              eventSetting={eventSetting}
              eventType={eventType}
              eventStatusChange={eventStatusChange}
              session={session}
            />
            <EventSettingContent
              eventSetting={eventSetting}
              validationState={validationState}
              register={register}
              errors={errors}
              setValue={setValue}
              languageVal={languageVal}
              setLanguageVal={setLanguageVal}
              setAvatar={setAvatar}
              setIntro={setIntro}
              setCarouselList={setCarouselList}
              carouselList={carouselList}
              setLoader={setLoader}
              industryZoneList={industryZoneList}
              eventIndustryZone={eventIndustryZone}
              setEventIndustryZone={setEventIndustryZone}
            />
          </Form>
        </React.Fragment>
      )}
      {loader === true && <LoaderGif />}
    </React.Fragment>
  );
};

const EventSettingBar = ({
  eventSetting,
  eventType,
  eventStatusChange,
  session,
}) => {
  const [deleteConfirmationState, setDeleteConfirmationState] = useState(false);
  const [deleteConfirmationValue, setDeleteConfirmationValue] = useState("");
  return (
    <React.Fragment>
      {deleteConfirmationState === true && (
        <DeleteConfirmation
          setDeleteConfirmationState={setDeleteConfirmationState}
          show={deleteConfirmationState}
          onHide={() => setDeleteConfirmationState(false)}
          delete={() => setDeleteConfirmationState(false)}
          deleteConfirmationValue={deleteConfirmationValue}
          page=""
          deleteMessage="Are you sure you want to remove this collaborator from this event? "
        />
      )}
      <section className="event_bar_section">
        <Container fluid>
          <div className="events_cover">
            <div className="d-flex align-items-center">
              <div>
                <h4 className="events_title mb-1">Event Settings</h4>
                <div className="d-flex align-items-center">
                  <p className="mr-3">
                    <span className="mr-2">
                      <img
                        className="img-fluid"
                        src="/images/time.svg"
                        alt=""
                      />
                    </span>
                    <span>
                      Last Updated{" "}
                      {moment
                        .tz(eventSetting.updated_at, moment.tz.guess())
                        .format("D MMM, yyyy hh:mm A z")}
                    </span>
                  </p>
                  {eventType === "master" && (
                    <p>
                      <span className="mr-2">
                        <img
                          className="img-fluid"
                          src="/images/user.svg"
                          alt=""
                        />
                      </span>
                      <span>
                        by{" "}
                        {eventSetting.event_collaborators &&
                          eventSetting.event_collaborators[0].name}
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="view_event_right_bar">
              {(eventType === "others" ||
                (eventType === "master" &&
                  session.user.role.id.toString() === ENV_ADMIN_ID)) && (
                <React.Fragment>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="mr-2 btn_green"
                  >
                    Save
                  </Button>
                  {eventSetting.event_status === "Published" && (
                    <React.Fragment>
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        className="mr-2"
                        onClick={() =>
                          eventStatusChange("Draft", "Unpublished")
                        }
                      >
                        Unpublish
                      </Button>
                      <Button
                        variant="outlined"
                        type="submit"
                        color="secondary"
                        onClick={() =>
                          eventStatusChange("Archived", "Archived")
                        }
                      >
                        Archive
                      </Button>
                    </React.Fragment>
                  )}
                  {eventSetting.event_status === "Draft" && (
                    <React.Fragment>
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        className="mr-2"
                        onClick={() =>
                          eventStatusChange("Published", "Published")
                        }
                      >
                        Publish
                      </Button>
                      <Button
                        variant="outlined"
                        type="submit"
                        color="secondary"
                        onClick={() =>
                          eventStatusChange("Archived", "Archived")
                        }
                      >
                        Archive
                      </Button>
                    </React.Fragment>
                  )}
                  {eventSetting.event_status === "Archived" && (
                    <React.Fragment>
                      <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        className="mr-2"
                        onClick={() =>
                          eventStatusChange("Published", "Published")
                        }
                      >
                        Publish
                      </Button>
                      <Button
                        variant="outlined"
                        type="submit"
                        color="secondary"
                        className="warning_btn"
                        onClick={() => eventStatusChange("Draft", "Draft")}
                      >
                        Draft
                      </Button>
                    </React.Fragment>
                  )}
                  {/* <Button variant="outlined" color="secondary" onClick={() => setDeleteConfirmationState(true)}>Delete</Button> */}
                </React.Fragment>
              )}
            </div>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

const EventSettingContent = ({
  eventSetting,
  register,
  errors,
  setValue,
  languageVal,
  setLanguageVal,
  setIntro,
  setCarouselList,
  carouselList,
  setLoader,
  industryZoneList,
  eventIndustryZone,
  setEventIndustryZone,
}) => {
  const timezoneList = timezone_list_json;
  const categoryList = category_list_json;
  delete category_list_json[1];
  const [stateLoaded, setStateLoaded] = useState(false);
  const e_category = eventSetting.event_category
    ? eventSetting.event_category
    : {};
  const [eventCategory, setEventCategory] = useState(
    categoryList.filter((i) => i.value === e_category)[0]
  );

  const handleIndustryZoneChange = (event) => {
    setEventIndustryZone(event.target.value);
  };

  const animatedComponents = makeAnimated();
  let setAvatarValue;
  if (
    eventSetting.base_configuration[0].event_configuration &&
    eventSetting.base_configuration[0].event_configuration.intro_configuration
      .avatar_configuration &&
    eventSetting.base_configuration[0].event_configuration.intro_configuration
      .avatar_configuration.id
  ) {
    setAvatarValue =
      eventSetting.base_configuration[0].event_configuration.intro_configuration
        .avatar_configuration.id;
  }
  const [introType, setIntroType] = React.useState(
    eventSetting.base_configuration[0].event_configuration
      ? eventSetting.base_configuration[0].event_configuration
          .intro_configuration.intro_type
      : ""
  );
  const [avatarChange, setAvatarChange] = React.useState(setAvatarValue);
  const tzone = eventSetting.base_configuration[0].event_configuration
    .event_details_configuration.timezone
    ? eventSetting.base_configuration[0].event_configuration
        .event_details_configuration.timezone
    : moment.tz.guess();
  const [timezoneValue, setTimezoneValue] = useState(
    timezoneList.filter((i) => i.value === tzone)[0]
  );
  /*const [startDate, setStartDate] = useState(
    eventSetting.access_id
      ? moment
          .tz(
            eventSetting.base_configuration[0].event_configuration
              .event_details_configuration.start_date
          )
          .format("D MMM, yyyy hh:mm a")
      : new Date()
  );
  const [endDate, setEndDate] = useState(
    eventSetting.access_id
      ? moment
          .tz(
            eventSetting.base_configuration[0].event_configuration
              .event_details_configuration.end_date,
            tzone
          )
          .format("D MMM, yyyy hh:mm a")
      : new Date()
  );*/

  const [startDate, setStartDate] = useState(
    eventSetting.access_id
      ? eventSetting.base_configuration[0].event_configuration
          .event_details_configuration.start_date
      : new Date()
  );
  const [endDate, setEndDate] = useState(
    eventSetting.access_id
      ? eventSetting.base_configuration[0].event_configuration
          .event_details_configuration.end_date
      : new Date()
  );

  const [eventImage, setEventImage] = useState(
    eventSetting.base_configuration[0].event_configuration
      .event_details_configuration
      ? eventSetting.base_configuration[0].event_configuration
          .event_details_configuration.event_image
      : undefined
  );

  const [voiceOverFemale, setVoiceOverFemale] = useState(
    eventSetting.base_configuration[0].event_configuration.intro_configuration
      .avatar_configuration
      ? eventSetting.base_configuration[0].event_configuration
          .intro_configuration.avatar_configuration.voice_over_female
      : undefined
  );
  const [voiceOverMale, setVoiceOverMale] = useState(
    eventSetting.base_configuration[0].event_configuration.intro_configuration
      .avatar_configuration
      ? eventSetting.base_configuration[0].event_configuration
          .intro_configuration.avatar_configuration.voice_over_male
      : undefined
  );
  const [introVideo, setIntroVideo] = useState(
    eventSetting.base_configuration[0].event_configuration.intro_configuration
      .video_configuration
      ? eventSetting.base_configuration[0].event_configuration
          .intro_configuration.video_configuration.intro_video
      : undefined
  );
  const { textToSpeechMedia, uploadMediaData } = useSelector(
    (state) => state.auth
  );
  const [audioGender, setAudioGender] = useState("");
  const [textToSpeechEnabled, setTextToSpeechEnabled] = useState(false);
  const [voiceOverTextValue, setVoiceOverText] = useState(
    eventSetting.base_configuration[0].event_configuration.intro_configuration
      .avatar_configuration
      ? eventSetting.base_configuration[0].event_configuration
          .intro_configuration.avatar_configuration.voice_over_text
      : null
  );

  const handleLanguageChange = (event) => {
    setLanguageVal(event.target.value);
  };

  const handleIntroChange = (event) => {
    setIntroType(event.target.value);
    setIntro(event.target.value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const [checked, setChecked] = useState(true);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const setTimezoneToForm = (val) => {
    setValue(
      "base_configuration[0].event_configuration.event_details_configuration.timezone",
      val
    );
  };

  const setEventCategoryToForm = (val) => {
    setValue("event_category", val);
  };

  const changeMedia = (changeField, media) => {
    switch (changeField) {
      case "event_image":
        setEventImage(media);
        setValue(
          "base_configuration[0].event_configuration.event_details_configuration.event_image",
          media
        );
        break;
      case "voice_over_female":
        setVoiceOverFemale(media);
        setValue(
          "base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_female",
          media
        );
        break;
      case "voice_over_male":
        setVoiceOverMale(media);
        setValue(
          "base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_male",
          media
        );
        break;
      case "intro_video":
        setIntroVideo(media);
        setValue(
          "base_configuration[0].event_configuration.intro_configuration.video_configuration.intro_video",
          media
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!stateLoaded) {
      register(
        "base_configuration[0].event_configuration.event_details_configuration.event_image"
      );
      register(
        "base_configuration[0].event_configuration.intro_configuration.intro_type"
      );
      register(
        "base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_female"
      );
      register(
        "base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_male"
      );
      register(
        "base_configuration[0].event_configuration.intro_configuration.video_configuration.intro_video"
      );
      register(
        "base_configuration[0].event_configuration.event_details_configuration.timezone"
      );
      register("event_category");

      setValue(
        "base_configuration[0].event_configuration.event_details_configuration.event_image",
        eventSetting.base_configuration[0].event_configuration
          .event_details_configuration.event_image
      );
      if (
        eventSetting.base_configuration[0].event_configuration
          .intro_configuration.avatar_configuration
      ) {
        setValue(
          "base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_female",
          eventSetting.base_configuration[0].event_configuration
            .intro_configuration.avatar_configuration.voice_over_female
        );
        setValue(
          "base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_male",
          eventSetting.base_configuration[0].event_configuration
            .intro_configuration.avatar_configuration.voice_over_male
        );
      }
      if (
        eventSetting.base_configuration[0].event_configuration
          .intro_configuration.video_configuration
      ) {
        setValue(
          "base_configuration[0].event_configuration.intro_configuration.video_configuration.intro_video",
          eventSetting.base_configuration[0].event_configuration
            .intro_configuration.video_configuration.intro_video
        );
      }
      setStateLoaded(true);
    }
  });

  const removeCarousel = (val, index) => {
    setCarouselList(carouselList.filter((item, i) => i !== index));
  };

  const handleAddMoreCarousel = () => {
    let temp = [...carouselList];
    temp = temp.concat("");
    setCarouselList(temp);
  };

  const onChangeCarousel = (event, i) => {
    carouselList[i] = event.target.value;
  };

  // after get response from API
  const createFileFromBase64 = (base64File) => {
    let byteCharacters = Base64.atob(base64File);
    var sliceSize = 512;

    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {
      type: "audio/mp3",
    }); //statement which creates the blob

    let fileName = "";
    const timemillis = new Date().getTime();
    if (audioGender === "FEMALE") {
      fileName = "AVO_F_" + timemillis + ".mp3";
    } else {
      fileName = "AVO_M_" + timemillis + ".mp3";
    }
    let file = new File([blob], fileName, { type: "audio/mp3" });

    return file;
  };

  const uploadAudioFile = (file) => {
    var formdata = new FormData();
    formdata.append("files", file, file.name);
    dispatch(uploadMedia(formdata))
      .then(() => {})
      .catch(() => {});
  };

  useEffect(() => {
    if (textToSpeechMedia) {
      let base64File = textToSpeechMedia.audioContent;
      let file = createFileFromBase64(base64File);
      uploadAudioFile(file);
    }
  }, [textToSpeechMedia]);

  useEffect(() => {
    if (uploadMediaData) {
      if (audioGender === "FEMALE") {
        setVoiceOverFemale(uploadMediaData[0]);
        setValue(
          "base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_female",
          uploadMediaData[0]
        );
        downloadbutton("MALE");
      } else if (audioGender === "MALE") {
        setVoiceOverMale(uploadMediaData[0]);
        setValue(
          "base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_male",
          uploadMediaData[0]
        );
        setAudioGender("");
        setLoader(false);
      }
    }
  }, [uploadMediaData]);

  const voiceOverTextChange = (e) => {
    setVoiceOverText(e.target.value);
    setTextToSpeechEnabled(true);
    setVoiceOverFemale(undefined);
    setVoiceOverMale(undefined);
  };

  const setGoogleLanguage = (
    gender,
    voiceOverData,
    langCode,
    langNameFemale,
    langNameMale
  ) => {
    if (gender === "FEMALE") {
      voiceOverData.voice = {
        languageCode: langCode,
        name: langNameFemale,
      };
    } else if (gender === "MALE") {
      voiceOverData.voice = {
        languageCode: langCode,
        name: langNameMale,
      };
    }

    return voiceOverData;
  };
  const dispatch = useDispatch();
  const downloadbutton = (gender) => {
    setAudioGender(gender);
    setTextToSpeechEnabled(false);
    setLoader(true);
    let voiceOverData = {
      audioConfig: {
        audioEncoding: "MP3",
        pitch: 0,
        speakingRate: 1,
      },
      input: {
        text: voiceOverTextValue,
      },
      voice: {},
    };

    if (languageVal === "DE") {
      voiceOverData = setGoogleLanguage(
        gender,
        voiceOverData,
        "de-DE",
        "de-DE-Wavenet-C",
        "de-DE-Wavenet-B"
      );
    } else if (languageVal === "FR") {
      voiceOverData = setGoogleLanguage(
        gender,
        voiceOverData,
        "fr-FR",
        "fr-FR-Wavenet-C",
        "fr-FR-Wavenet-D"
      );
    } else {
      voiceOverData = setGoogleLanguage(
        gender,
        voiceOverData,
        "en-US",
        "en-US-Wavenet-G",
        "en-US-Wavenet-I"
      );
    }

    dispatch(googleTextToSpeech(voiceOverData))
      .then(() => {})
      .catch((error) => {
        setLoader(false);
      });
  };

  return (
    <section className="edit-experiences-section event-setting-section">
      <Container>
        <div className="accoridon_section">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h2 className="accorion_title with_info_icon">
                Event Languages & Category{" "}
                <HtmlTooltip
                  title={
                    <Typography color="inherit">
                      {
                        cms_button_details_json.event_language_category_title_detail
                      }
                    </Typography>
                  }
                >
                  <img className="" src="/images/info_text_icon.svg" alt="" />
                </HtmlTooltip>
              </h2>
            </AccordionSummary>
            <AccordionDetails>
              <div className="cover_edit_options w-100">
                <Row>
                  <Col xl={6} lg={6} md={6} sm={12}>
                    <FormGroup className="material-group mb-0">
                      <FormControl variant="outlined">
                        <InputLabel id="demo-simple-select-outlined-label">
                          Event Languages
                        </InputLabel>
                        <Select
                          components={animatedComponents}
                          onChange={handleLanguageChange}
                          value={languageVal}
                          name="base_configuration[0].language"
                          placeholder="Event Languages"
                          classnameprefix="react-select"
                        >
                          <MenuItem value={"EN"}>{"English"}</MenuItem>
                          <MenuItem value={"DE"}>{"German"}</MenuItem>
                          <MenuItem value={"FR"}>{"French"}</MenuItem>
                          <MenuItem value={"ZH"}>{"Mandarin"}</MenuItem>
                          <MenuItem value={"ES"}>{"Spanish"}</MenuItem>
                        </Select>
                      </FormControl>
                      <HtmlTooltip
                        title={
                          <Typography color="inherit">
                            {
                              cms_button_details_json.event_language_field_detail
                            }
                          </Typography>
                        }
                      >
                        <p className="field_info_text">
                          <img
                            className=""
                            src="/images/info_text_icon.svg"
                            alt=""
                          />
                        </p>
                      </HtmlTooltip>
                    </FormGroup>
                  </Col>
                  <Col lg={6} md={6} sm={6}>
                    <FormGroup className="material-group mb-0">
                      {categoryList.length > 0 && (
                        <FormControl variant="outlined">
                          <Autocomplete
                            id="demo-simple-select-outlined-1"
                            options={categoryList}
                            value={eventCategory}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setEventCategory(newValue);
                                setEventCategoryToForm(newValue.value);
                              }
                            }}
                            onInputChange={(event, newInputValue) => {}}
                            getOptionLabel={(option) => option.label}
                            getOptionSelected={(option, value) =>
                              option.value === value.value
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Industry Vertical"
                                variant="outlined"
                              />
                            )}
                            name="event_category"
                          />
                          <HtmlTooltip
                            title={
                              <Typography color="inherit">
                                {
                                  cms_button_details_json.industry_vertical_field_detail
                                }
                              </Typography>
                            }
                          >
                            <p className="field_info_text">
                              <img
                                className=""
                                src="/images/info_text_icon.svg"
                                alt=""
                              />
                            </p>
                          </HtmlTooltip>
                        </FormControl>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h2 className="accorion_title with_info_icon">
                Event Details{" "}
                <HtmlTooltip
                  title={
                    <Typography color="inherit">
                      {cms_button_details_json.event_details_title_detail}
                    </Typography>
                  }
                >
                  <img className="" src="/images/info_text_icon.svg" alt="" />
                </HtmlTooltip>
              </h2>
            </AccordionSummary>
            <AccordionDetails>
              <div className="cover_edit_options w-100">
                <Row>
                  <Col xl={6} lg={6} md={6} sm={12}>
                    <FormGroup className="material-group">
                      <FormControl variant="outlined">
                        <InputLabel
                          htmlFor="EventName"
                          className="label-caption"
                        >
                          Event Name
                        </InputLabel>
                        <OutlinedInput
                          id="EventName"
                          type="text"
                          name="event_name"
                          className="material"
                          inputRef={register({
                            required: true,
                          })}
                        />
                        {errors.event_name?.type === "required" && (
                          <p className="error_msg">Please enter event name.</p>
                        )}
                      </FormControl>
                      <HtmlTooltip
                        title={
                          <Typography color="inherit">
                            {cms_button_details_json.event_name_field_detail}
                          </Typography>
                        }
                      >
                        <p className="field_info_text">
                          <img
                            className=""
                            src="/images/info_text_icon.svg"
                            alt=""
                          />
                        </p>
                      </HtmlTooltip>
                    </FormGroup>
                    <FormGroup className="material-group">
                      <FormControl variant="outlined">
                        <InputLabel
                          htmlFor="EventDescription"
                          className="label-caption"
                        >
                          Event Description
                        </InputLabel>
                        <OutlinedInput
                          id="EventDescription"
                          type="text"
                          name="event_description"
                          className="material"
                          inputRef={register({
                            required: false,
                          })}
                        />
                      </FormControl>
                      <HtmlTooltip
                        title={
                          <Typography color="inherit">
                            {
                              cms_button_details_json.event_description_field_detail
                            }
                          </Typography>
                        }
                      >
                        <p className="field_info_text">
                          <img
                            className=""
                            src="/images/info_text_icon.svg"
                            alt=""
                          />
                        </p>
                      </HtmlTooltip>
                    </FormGroup>
                    <FormGroup className="material-group">
                      <FormControl variant="outlined">
                        <InputLabel htmlFor="URL" className="label-caption">
                          Event URL
                        </InputLabel>
                        <OutlinedInput
                          id="URL"
                          type="text"
                          name="event_urls"
                          value={
                            ENV_EVENT_URL +
                            eventSetting.access_id +
                            "&lang=" +
                            languageVal
                          }
                          className="material"
                          inputRef={register({
                            required: true,
                          })}
                          disabled
                        />
                      </FormControl>
                      <HtmlTooltip
                        title={
                          <Typography color="inherit">
                            {cms_button_details_json.event_url_field_detail}
                          </Typography>
                        }
                      >
                        <p className="field_info_text">
                          <img
                            className=""
                            src="/images/info_text_icon.svg"
                            alt=""
                          />
                        </p>
                      </HtmlTooltip>
                    </FormGroup>
                    <FormGroup className="material-group">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="d-flex align-items-center">
                          <div>
                            <KeyboardDateTimePicker
                              margin="normal"
                              inputVariant="outlined"
                              id="date-picker-start"
                              label="Start Date"
                              format="dd MMM, yyyy hh:mm a"
                              value={startDate}
                              minDate={moment().format("D MMM, yyyy hh:mm a")}
                              onChange={handleStartDateChange}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              name="base_configuration[0].event_configuration.event_details_configuration.start_date"
                              className="mt-0 mb-0 mr-4 full_clickable"
                              inputRef={register({
                                required: {
                                  value: true,
                                  message: "Please select start date.",
                                },
                              })}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="base_configuration[0].event_configuration.event_details_configuration.start_date"
                              as={<p className="error_msg"></p>}
                            />
                            <HtmlTooltip
                              title={
                                <Typography color="inherit">
                                  {
                                    cms_button_details_json.start_date_field_detail
                                  }
                                </Typography>
                              }
                            >
                              <p className="field_info_text">
                                <img
                                  className=""
                                  src="/images/info_text_icon.svg"
                                  alt=""
                                />
                              </p>
                            </HtmlTooltip>
                          </div>
                          <div>
                            <KeyboardDateTimePicker
                              margin="normal"
                              inputVariant="outlined"
                              id="date-picker-end"
                              label="End Date"
                              format="dd MMM, yyyy hh:mm a"
                              value={endDate}
                              onChange={handleEndDateChange}
                              minDate={moment().format("D MMM, yyyy hh:mm a")}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              name="base_configuration[0].event_configuration.event_details_configuration.end_date"
                              className="m-0 full_clickable"
                              inputRef={register({
                                required: {
                                  value: true,
                                  message: "Please select end date.",
                                },
                              })}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="base_configuration[0].event_configuration.event_details_configuration.end_date"
                              as={<p className="error_msg"></p>}
                            />
                            <HtmlTooltip
                              title={
                                <Typography color="inherit">
                                  {
                                    cms_button_details_json.end_date_field_detail
                                  }
                                </Typography>
                              }
                            >
                              <p className="field_info_text">
                                <img
                                  className=""
                                  src="/images/info_text_icon.svg"
                                  alt=""
                                />
                              </p>
                            </HtmlTooltip>
                          </div>
                        </div>
                      </MuiPickersUtilsProvider>
                    </FormGroup>
                    <FormGroup className="material-group timezone_field">
                      <FormControl variant="outlined">
                        <Autocomplete
                          id="demo-simple-select-outlined-1"
                          options={timezoneList}
                          value={timezoneValue}
                          onChange={(event, newValue) => {
                            setTimezoneValue(newValue);
                            setTimezoneToForm(newValue.value);
                          }}
                          onInputChange={(event, newInputValue) => {}}
                          getOptionLabel={(option) => option.label}
                          getOptionSelected={(option, value) =>
                            option.value === value.value
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Timezone"
                              variant="outlined"
                            />
                          )}
                          name="base_configuration[0].event_configuration.event_details_configuration.timezone"
                        />
                      </FormControl>
                      <HtmlTooltip
                        title={
                          <Typography color="inherit">
                            {cms_button_details_json.timezone_field_detail}
                          </Typography>
                        }
                      >
                        <p className="field_info_text">
                          <img
                            className=""
                            src="/images/info_text_icon.svg"
                            alt=""
                          />
                        </p>
                      </HtmlTooltip>
                      <ErrorMessage
                        errors={errors}
                        name="base_configuration[0].event_configuration.event_details_configuration.timezone"
                        as={<p className="error_msg"></p>}
                      />
                    </FormGroup>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12}>
                    <DropZonePlaceHolder
                      holder={
                        eventImage && eventImage.formats
                          ? ENV_MEDIA_URL + eventImage.formats.thumbnail.url
                          : ""
                      }
                      type="image"
                      fileName={eventImage ? eventImage.name : ""}
                      fileSizeText=""
                      labelName="Event Image"
                      ButtonText="Browse"
                      changeselectedmedia={(media) =>
                        changeMedia("event_image", media)
                      }
                      removeMedia={() => changeMedia("event_image", null)}
                      category={
                        eventCategory && eventCategory.value
                          ? eventCategory.value
                          : "all"
                      }
                    />
                    <HtmlTooltip
                      title={
                        <Typography color="inherit">
                          {cms_button_details_json.event_image_field_detail}
                        </Typography>
                      }
                    >
                      <p className="field_info_text">
                        <img
                          className=""
                          src="/images/info_text_icon.svg"
                          alt=""
                        />
                      </p>
                    </HtmlTooltip>
                  </Col>
                </Row>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h2 className="accorion_title with_info_icon">
                Meeting URL{" "}
                <HtmlTooltip
                  title={
                    <Typography color="inherit">
                      {cms_button_details_json.meeting_url_title_detail}
                    </Typography>
                  }
                >
                  <img className="" src="/images/info_text_icon.svg" alt="" />
                </HtmlTooltip>
              </h2>
            </AccordionSummary>
            <AccordionDetails>
              <div className="cover_edit_options w-100">
                <Row>
                  <Col xl={6} lg={6} md={6} sm={12}>
                    <FormGroup className="material-group m-0">
                      <FormControl variant="outlined">
                        <InputLabel
                          htmlFor="MeetingURL"
                          className="label-caption"
                        >
                          WebeX Meeting URL
                        </InputLabel>
                        <OutlinedInput
                          id="MeetingURL"
                          type="text"
                          name="base_configuration[0].event_configuration.meeting_url"
                          className="material"
                          disabled={introType === "Video" ? true : false}
                          inputRef={register({
                            required: false,
                          })}
                        />
                      </FormControl>
                      <HtmlTooltip
                        title={
                          <Typography color="inherit">
                            {cms_button_details_json.meeting_url_field_detail}
                          </Typography>
                        }
                      >
                        <p className="field_info_text">
                          <img
                            className=""
                            src="/images/info_text_icon.svg"
                            alt=""
                          />
                        </p>
                      </HtmlTooltip>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h2 className="accorion_title with_info_icon">
                Loading{" "}
                <HtmlTooltip
                  title={
                    <Typography color="inherit">
                      {cms_button_details_json.loading_title_detail}
                    </Typography>
                  }
                >
                  <img className="" src="/images/info_text_icon.svg" alt="" />
                </HtmlTooltip>
              </h2>
            </AccordionSummary>
            <AccordionDetails>
              <div className="cover_edit_options w-100">
                <Row>
                  <Col xl={6} lg={6} md={6} sm={12}>
                    <FormGroup className="material-group m-0">
                      <FormControl variant="outlined">
                        <InputLabel
                          htmlFor="WelComeMsg"
                          className="label-caption"
                        >
                          Welcome Message
                        </InputLabel>
                        <OutlinedInput
                          id="WelComeMsg"
                          type="text"
                          name="base_configuration[0].event_configuration.loading_configuration.welcome_message"
                          className="material"
                          inputRef={register({
                            required: false,
                          })}
                        />
                      </FormControl>
                      <HtmlTooltip
                        title={
                          <Typography color="inherit">
                            {
                              cms_button_details_json.welcome_message_field_detail
                            }
                          </Typography>
                        }
                      >
                        <p className="field_info_text">
                          <img
                            className=""
                            src="/images/info_text_icon.svg"
                            alt=""
                          />
                        </p>
                      </HtmlTooltip>
                    </FormGroup>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={12}>
                    <div className="carosel_message">
                      <h5 className="carosel_title mb-2">
                        Carousel Messages{" "}
                        <HtmlTooltip
                          title={
                            <Typography color="inherit">
                              {
                                cms_button_details_json.carousel_messsage_title_detail
                              }
                            </Typography>
                          }
                        >
                          <p className="field_info_text">
                            <img
                              className=""
                              src="/images/info_text_icon.svg"
                              alt=""
                            />
                          </p>
                        </HtmlTooltip>
                      </h5>
                      <ul className="carosel_message_ul">
                        {carouselList.map((item, i) => (
                          <li
                            key={"li_" + i}
                            className="list_items_message d-flex align-items-center mb-2"
                          >
                            <p className="carosel_caption">
                              {i + 1}.
                              <input
                                key={"input_" + i + "_" + item}
                                defaultValue={item}
                                className="carousel_input"
                                onChange={(e) => onChangeCarousel(e, i)}
                              />
                            </p>
                            <p className="carosel_close_icon ml-4">
                              <img
                                className="img-fluid"
                                src="/images/close.svg"
                                alt=""
                                onClick={() => removeCarousel(item, i)}
                              />
                            </p>
                          </li>
                        ))}
                      </ul>
                      <p className="text-right mt-4 mr-4">
                        <Button
                          variant="outlined"
                          color="primary"
                          className="mr-3"
                          onClick={() => handleAddMoreCarousel()}
                        >
                          Add More
                        </Button>
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h2 className="accorion_title with_info_icon">
                UI{" "}
                <HtmlTooltip
                  title={
                    <Typography color="inherit">
                      {cms_button_details_json.ui_title_detail}
                    </Typography>
                  }
                >
                  <img className="" src="/images/info_text_icon.svg" alt="" />
                </HtmlTooltip>
              </h2>
            </AccordionSummary>
            <AccordionDetails>
              <div className="cover_edit_options w-100">
                <Row>
                  <Col lg={4} md={4} sm={4}>
                    <FormGroup className="material-group">
                      <FormControl variant="outlined">
                        <InputLabel
                          htmlFor="HomeButtonName"
                          className="label-caption"
                        >
                          Home Button Name
                        </InputLabel>
                        <OutlinedInput
                          id="HomeButtonName"
                          type="text"
                          name="base_configuration[0].event_configuration.ui_configuration.home_button_name"
                          className="material"
                          inputRef={register({
                            required: false,
                          })}
                        />
                      </FormControl>
                      <HtmlTooltip
                        title={
                          <Typography color="inherit">
                            {
                              cms_button_details_json.home_button_name_field_detail
                            }
                          </Typography>
                        }
                      >
                        <p className="field_info_text">
                          <img
                            className=""
                            src="/images/info_text_icon.svg"
                            alt=""
                          />
                        </p>
                      </HtmlTooltip>
                    </FormGroup>
                  </Col>
                  <Col lg={4} md={4} sm={4}>
                    <FormGroup className="material-group">
                      <FormControl variant="outlined">
                        <InputLabel
                          htmlFor="MapButtonName"
                          className="label-caption"
                        >
                          Map Button Name
                        </InputLabel>
                        <OutlinedInput
                          id="MapButtonName"
                          type="text"
                          name="base_configuration[0].event_configuration.ui_configuration.map_button_name"
                          className="material"
                          inputRef={register({
                            required: false,
                          })}
                        />
                      </FormControl>
                      <HtmlTooltip
                        title={
                          <Typography color="inherit">
                            {
                              cms_button_details_json.map_button_name_field_detail
                            }
                          </Typography>
                        }
                      >
                        <p className="field_info_text">
                          <img
                            className=""
                            src="/images/info_text_icon.svg"
                            alt=""
                          />
                        </p>
                      </HtmlTooltip>
                    </FormGroup>
                  </Col>
                  <Col lg={4} md={4} sm={4}>
                    <FormGroup className="material-group">
                      <FormControl variant="outlined">
                        <InputLabel
                          htmlFor="ShowcaseButtonName"
                          className="label-caption"
                        >
                          Showcase Button Name
                        </InputLabel>
                        <OutlinedInput
                          id="ShowcaseButtonName"
                          type="text"
                          name="base_configuration[0].event_configuration.ui_configuration.showcase_button_name"
                          className="material"
                          inputRef={register({
                            required: false,
                          })}
                        />
                      </FormControl>
                      <HtmlTooltip
                        title={
                          <Typography color="inherit">
                            {
                              cms_button_details_json.showcase_button_name_field_detail
                            }
                          </Typography>
                        }
                      >
                        <p className="field_info_text">
                          <img
                            className=""
                            src="/images/info_text_icon.svg"
                            alt=""
                          />
                        </p>
                      </HtmlTooltip>
                    </FormGroup>
                  </Col>
                  <Col lg={4} md={4} sm={4}>
                    <FormGroup className="material-group">
                      <FormControl variant="outlined">
                        <InputLabel
                          htmlFor="PDFButtonName"
                          className="label-caption"
                        >
                          PDF Button Name
                        </InputLabel>
                        <OutlinedInput
                          id="PDFButtonName"
                          type="text"
                          name="base_configuration[0].event_configuration.ui_configuration.pdf_button_name"
                          className="material"
                          inputRef={register({
                            required: false,
                          })}
                        />
                      </FormControl>
                      <HtmlTooltip
                        title={
                          <Typography color="inherit">
                            {
                              cms_button_details_json.pdf_button_name_field_detail
                            }
                          </Typography>
                        }
                      >
                        <p className="field_info_text">
                          <img
                            className=""
                            src="/images/info_text_icon.svg"
                            alt=""
                          />
                        </p>
                      </HtmlTooltip>
                    </FormGroup>
                  </Col>
                  <Col lg={4} md={4} sm={4}>
                    <FormGroup className="material-group">
                      <FormControl variant="outlined">
                        <InputLabel
                          htmlFor="SMEButtonName"
                          className="label-caption"
                        >
                          SME Button Name
                        </InputLabel>
                        <OutlinedInput
                          id="SMEButtonName"
                          type="text"
                          name="base_configuration[0].event_configuration.ui_configuration.sme_button_name"
                          className="material"
                          inputRef={register({
                            required: false,
                          })}
                        />
                      </FormControl>
                      <HtmlTooltip
                        title={
                          <Typography color="inherit">
                            {
                              cms_button_details_json.sme_button_name_field_detail
                            }
                          </Typography>
                        }
                      >
                        <p className="field_info_text">
                          <img
                            className=""
                            src="/images/info_text_icon.svg"
                            alt=""
                          />
                        </p>
                      </HtmlTooltip>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h2 className="accorion_title with_info_icon">
                Event Driven By{" "}
                <HtmlTooltip
                  title={
                    <Typography color="inherit">
                      {cms_button_details_json.event_driven_by_title_detail}
                    </Typography>
                  }
                >
                  <img className="" src="/images/info_text_icon.svg" alt="" />
                </HtmlTooltip>
              </h2>
            </AccordionSummary>
            <AccordionDetails>
              <div className="cover_edit_options w-100">
                <Row className="border_bottom_hr">
                  <Col lg={6} md={6} sm={6}>
                    <FormGroup className="material-group">
                      <FormControl variant="outlined">
                        <InputLabel id="demo-simple-select-outlined-label">
                          Intro Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          name="base_configuration[0].event_configuration.intro_configuration.avatar_configuration.avatar"
                          value={introType}
                          onChange={handleIntroChange}
                          label="Intro Type"
                        >
                          <MenuItem value={"Avatar"}>Avatar</MenuItem>
                          <MenuItem value={"Video"}>Video</MenuItem>
                          <MenuItem value={"Live"}>Live Presenter</MenuItem>
                        </Select>
                      </FormControl>
                      <HtmlTooltip
                        title={
                          <Typography color="inherit">
                            {cms_button_details_json.intro_type_field_detail}
                          </Typography>
                        }
                      >
                        <p className="field_info_text">
                          <img
                            className=""
                            src="/images/info_text_icon.svg"
                            alt=""
                          />
                        </p>
                      </HtmlTooltip>
                    </FormGroup>
                  </Col>
                  {introType === "Avatar" && (
                    <Col lg={6} md={6} sm={6}>
                      <FormGroup className="material-group">
                        <FormControl
                          variant="outlined"
                          className="cover_with_button"
                        >
                          <InputLabel
                            htmlFor="voiceOverText"
                            className="label-caption"
                          >
                            Voice Over Text
                          </InputLabel>
                          <OutlinedInput
                            id="voiceOverText"
                            type="text"
                            multiline
                            onChange={voiceOverTextChange}
                            rows={5}
                            name="base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_text"
                            className="material"
                            inputRef={register({ required: false })}
                          />
                        </FormControl>
                        <div className="cover_text_speech_btn">
                          <HtmlTooltip
                            title={
                              <Typography color="inherit">
                                {
                                  cms_button_details_json.voice_over_text_field_detail
                                }
                              </Typography>
                            }
                          >
                            <p className="field_info_text">
                              <img
                                className=""
                                src="/images/info_text_icon.svg"
                                alt=""
                              />
                            </p>
                          </HtmlTooltip>
                          <Button
                            disabled={!textToSpeechEnabled}
                            variant="contained"
                            color="primary"
                            className="text-white"
                            onClick={() => downloadbutton("FEMALE")}
                          >
                            Convert Text to Speech
                          </Button>
                        </div>
                      </FormGroup>
                    </Col>
                  )}
                  {introType === "Video" && (
                    <Col lg={6} md={6} sm={6}>
                      <DropZonePlaceHolder
                        holder={
                          introVideo ? ENV_MEDIA_URL + introVideo.url : ""
                        }
                        type="video"
                        fileName={introVideo ? introVideo.name : ""}
                        fileSizeText=""
                        labelName="Video Configuration"
                        ButtonText="Browse"
                        changeselectedmedia={(media) =>
                          changeMedia("intro_video", media)
                        }
                        removeMedia={() => changeMedia("intro_video", null)}
                        category={
                          eventCategory && eventCategory.value
                            ? eventCategory.value
                            : "all"
                        }
                      />
                    </Col>
                  )}
                </Row>

                {introType === "Avatar" && (
                  <Row className="pt-4">
                    {voiceOverFemale && (
                      <React.Fragment>
                        <Col lg={6} md={6} sm={6}>
                          <FormGroup className="material-group">
                            <FormControl variant="outlined">
                              <InputLabel
                                htmlFor="AvatarFemale"
                                className="label-caption"
                              >
                                Female Avatar
                              </InputLabel>
                              <OutlinedInput
                                id="AvatarFemale"
                                type="text"
                                name="base_configuration[0].event_configuration.intro_configuration.avatar_configuration.avatar_female"
                                defaultValue="Claudia"
                                className="material"
                                disabled
                                inputRef={register({ required: false })}
                              />
                            </FormControl>
                          </FormGroup>
                        </Col>
                        <Col lg={6} md={6} sm={6} className="pb-4">
                          <DropZonePlaceHolder
                            holder={
                              voiceOverFemale
                                ? ENV_MEDIA_URL + voiceOverFemale.url
                                : ""
                            }
                            type="audio"
                            fileName={
                              voiceOverFemale ? voiceOverFemale.name : ""
                            }
                            fileSizeText=""
                            labelName="Voice Over Female"
                            ButtonText=""
                            changeselectedmedia={(media) =>
                              changeMedia("voice_over_female", media)
                            }
                            category={
                              eventCategory && eventCategory.value
                                ? eventCategory.value
                                : "all"
                            }
                          />
                        </Col>
                      </React.Fragment>
                    )}
                    {voiceOverMale && (
                      <React.Fragment>
                        <Col lg={6} md={6} sm={6}>
                          <FormGroup className="material-group">
                            <FormControl variant="outlined">
                              <InputLabel
                                htmlFor="AvatarMale"
                                className="label-caption"
                              >
                                Male Avatar
                              </InputLabel>
                              <OutlinedInput
                                id="AvatarMale"
                                type="text"
                                name="base_configuration[0].event_configuration.intro_configuration.avatar_configuration.avatar_male"
                                defaultValue="Eric"
                                className="material"
                                disabled
                                inputRef={register({ required: false })}
                              />
                            </FormControl>
                          </FormGroup>
                        </Col>
                        <Col lg={6} md={6} sm={6}>
                          <DropZonePlaceHolder
                            holder={
                              voiceOverMale
                                ? ENV_MEDIA_URL + voiceOverMale.url
                                : ""
                            }
                            type="audio"
                            fileName={voiceOverMale ? voiceOverMale.name : ""}
                            fileSizeText=""
                            labelName="Voice Over Male"
                            ButtonText=""
                            changeselectedmedia={(media) =>
                              changeMedia("voice_over_male", media)
                            }
                            category={
                              eventCategory && eventCategory.value
                                ? eventCategory.value
                                : "all"
                            }
                          />
                        </Col>
                      </React.Fragment>
                    )}
                  </Row>
                )}

                {introType === "Live" && (
                  <Row className="pt-4">
                    <Col lg={12} md={12} sm={12}>
                      <p className="presenter_title">Presenter configuration</p>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                      <FormGroup className="material-group">
                        <FormControl variant="outlined">
                          <InputLabel
                            htmlFor="PresenterName"
                            className="label-caption"
                          >
                            Name
                          </InputLabel>
                          <OutlinedInput
                            id="PresenterName"
                            type="text"
                            name="base_configuration[0].event_configuration.intro_configuration.presenter_configuration.name"
                            className="material"
                            inputRef={register({
                              required: {
                                value: true,
                                message: "Please enter presenter name.",
                              },
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="base_configuration[0].event_configuration.intro_configuration.presenter_configuration.name"
                            as={<p className="error_msg"></p>}
                          />
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                      <FormGroup className="material-group">
                        <FormControl variant="outlined">
                          <InputLabel
                            htmlFor="PresenterEmail"
                            className="label-caption"
                          >
                            Email
                          </InputLabel>
                          <OutlinedInput
                            id="PresenterEmail"
                            type="email"
                            name="base_configuration[0].event_configuration.intro_configuration.presenter_configuration.email"
                            className="material"
                            inputRef={register({
                              required: {
                                value: true,
                                pattern:
                                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: "Please enter presenter email.",
                              },
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="base_configuration[0].event_configuration.intro_configuration.presenter_configuration.email"
                            as={<p className="error_msg"></p>}
                          />
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                      <FormGroup className="material-group">
                        <FormControl variant="outlined">
                          <InputLabel
                            htmlFor="CohostName"
                            className="label-caption"
                          >
                            Cohost Name
                          </InputLabel>
                          <OutlinedInput
                            id="CohostName"
                            type="text"
                            name="base_configuration[0].event_configuration.intro_configuration.presenter_configuration.cohost_name"
                            className="material"
                            inputRef={register({
                              required: {
                                value: false,
                                message: "Please enter Cohost name.",
                              },
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="base_configuration[0].event_configuration.intro_configuration.presenter_configuration.cohost_name"
                            as={<p className="error_msg"></p>}
                          />
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                      <FormGroup className="material-group">
                        <FormControl variant="outlined">
                          <InputLabel
                            htmlFor="CohostEmail"
                            className="label-caption"
                          >
                            Cohost Email
                          </InputLabel>
                          <OutlinedInput
                            id="CohostEmail"
                            type="email"
                            name="base_configuration[0].event_configuration.intro_configuration.presenter_configuration.cohost_email"
                            className="material"
                            inputRef={register({
                              required: {
                                value: false,
                                pattern:
                                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: "Please enter cohost email.",
                              },
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="base_configuration[0].event_configuration.intro_configuration.presenter_configuration.cohost_email"
                            as={<p className="error_msg"></p>}
                          />
                        </FormControl>
                      </FormGroup>
                    </Col>
                    {/* <Col lg={6} md={6} sm={6}>
                      <FormGroup className="material-group m-0">
                        <FormControl variant="outlined">
                          <InputLabel
                            htmlFor="MeetingURL"
                            className="label-caption"
                          >
                            WebeX Meeting URL
                          </InputLabel>
                          <OutlinedInput
                            id="MeetingURL"
                            type="text"
                            name="base_configuration[0].event_configuration.meeting_url"
                            className="material"
                            inputRef={register({
                              required: false,
                            })}
                          />
                        </FormControl>
                        <HtmlTooltip
                          title={
                            <Typography color="inherit">
                              {cms_button_details_json.meeting_url_field_detail}
                            </Typography>
                          }
                        >
                          <p className="field_info_text">
                            <img
                              className=""
                              src="/images/info_text_icon.svg"
                              alt=""
                            />
                          </p>
                        </HtmlTooltip>
                      </FormGroup>
                    </Col> */}
                  </Row>
                )}
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h2 className="accorion_title with_info_icon">
                Industry Experience Zone{" "}
                <HtmlTooltip
                  title={
                    <Typography color="inherit">
                      {cms_button_details_json.indystry_zone_title_detail}
                    </Typography>
                  }
                >
                  <img className="" src="/images/info_text_icon.svg" alt="" />
                </HtmlTooltip>
              </h2>
            </AccordionSummary>
            <AccordionDetails>
              <div className="cover_edit_options w-100">
                <Row className="border_bottom_hr">
                  <Col lg={6} md={6} sm={6}>
                    <FormGroup className="material-group">
                      <FormControl variant="outlined">
                        <InputLabel id="demo-simple-select-outlined-label">
                          Experience Zone
                        </InputLabel>
                        <Select
                          components={animatedComponents}
                          onChange={handleIndustryZoneChange}
                          value={eventIndustryZone}
                          name="iez_id"
                          placeholder="Experience Zone"
                          classnameprefix="react-select"
                        >
                          <MenuItem value={"0"}>-- None --</MenuItem>
                          {industryZoneList.map((item, i) => (
                            <MenuItem value={item.id}>
                              {item.placard_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* <Accordion>
             <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
              <h2 className="accorion_title with_info_icon">Access <HtmlTooltip title={
                <Typography color="inherit">{cms_button_details_json.access_title_detail}</Typography>
                }>   
                  <img className="" src="/images/info_text_icon.svg" alt="" />
              </HtmlTooltip>   
            </h2>
             </AccordionSummary>
             <AccordionDetails>
              <div className="cover_edit_options w-100">
                <Row>
                 <Col lg={12}>
                  <div className="checkbox_label_primary">
                   <FormGroup className="material-group"> 
                    <FormControlLabel
                     control={
                      <Checkbox
                          checked={checked}
                          onChange={handleChange}
                          name="checkedURL"
                          color="primary"
                      />
                     }
                     label="Anyone with the URL can access the event"
                    />
                    </FormGroup>    
                  </div>      
                 </Col> 
                 <Col lg={6} md={6} sm={6}>
                  <FormGroup className="material-group">
                   <FormControl variant="outlined">
                    <InputLabel htmlFor="AllowedEmails" className="label-caption">Allowed Emails</InputLabel>
                    <OutlinedInput id="AllowedEmails" multiline rows={5} type="text" name="base_configuration[0].event_configuration.access_configuration.allowed_emails" className="material"
                     inputRef={register({
                      required: false,
                     })} />
                   </FormControl>
                  <HtmlTooltip title={
                    <Typography color="inherit">{cms_button_details_json.allowed_emails}</Typography>
                    }>   
                  <p className="field_info_text">
                     <img className="" src="/images/info_text_icon.svg" alt="" />
                  </p>
                  </HtmlTooltip>
                  </FormGroup>
                 </Col>
                 <Col lg={6} md={6} sm={6}>
                  <FormGroup className="material-group">
                   <FormControl variant="outlined">
                    <InputLabel htmlFor="AllowedEmailDomains" className="label-caption">Allowed Email Domains</InputLabel>
                    <OutlinedInput id="AllowedEmailDomains" multiline rows={5} type="text" name="base_configuration[0].event_configuration.access_configuration.allowed_email_domains" className="material"
                      inputRef={register({
                        required: false,
                      })} />
                   </FormControl>
                  <HtmlTooltip title={
                    <Typography color="inherit">{cms_button_details_json.allowed_email_domains}</Typography>
                    }>   
                  <p className="field_info_text">
                     <img className="" src="/images/info_text_icon.svg" alt="" />
                  </p>
                  </HtmlTooltip>
                  </FormGroup>
                 </Col>
                 <Col lg={6} md={6} sm={6}>
                  <FormGroup className="material-group">
                   <FormControl variant="outlined">
                    <InputLabel htmlFor="DisallowedEmails" className="label-caption">Disallowed Emails</InputLabel>
                    <OutlinedInput id="DisallowedEmails" multiline rows={5} type="text" name="base_configuration[0].event_configuration.access_configuration.disallowed_emails" className="material"
                      inputRef={register({
                        required: false,
                      })} />
                   </FormControl>
                  <HtmlTooltip title={
                    <Typography color="inherit">{cms_button_details_json.disallowed_emails}</Typography>
                    }>   
                  <p className="field_info_text">
                     <img className="" src="/images/info_text_icon.svg" alt="" />
                  </p>
                  </HtmlTooltip>
                  </FormGroup>
                 </Col>
                 <Col lg={6} md={6} sm={6}>
                  <FormGroup className="material-group">
                   <FormControl variant="outlined">
                    <InputLabel htmlFor="DisallowedEmailDomains" className="label-caption">Disallowed Email Domains</InputLabel>
                    <OutlinedInput id="DisallowedEmailDomains" multiline rows={5} type="text" name="base_configuration[0].event_configuration.access_configuration.disallowed_email_domains" className="material"
                      inputRef={register({
                        required: false,
                      })} />
                   </FormControl>
                  <HtmlTooltip title={
                    <Typography color="inherit">{cms_button_details_json.disallowed_emails_domains}</Typography>
                    }>   
                  <p className="field_info_text">
                     <img className="" src="/images/info_text_icon.svg" alt="" />
                  </p>
                  </HtmlTooltip>
                  </FormGroup>
                 </Col>
                </Row>
              </div>
             </AccordionDetails>
          </Accordion> */}
        </div>
      </Container>
    </section>
  );
};
