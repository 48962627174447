import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Redirect } from "react-router-dom";
import { Container, Row, Col, Form, Card } from "react-bootstrap";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormGroup from "@material-ui/core/FormGroup";
import { Helmet } from "react-helmet";
import BreadCrumb from "../modules/breadcrumb";
import UserService from "../../services/user.service";
import Button from "@material-ui/core/Button";
import DropZonePlaceHolder from "../modules/dropzone-placeholder";
import { addEditIndustryZone } from "../../actions/auth";
import { statusErrorSuccess } from "../../App";
import LoaderGif from "../modules/loader";

const ENV_MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
const ENV_AUTHENTICATED_ID = process.env.REACT_APP_AUTHENTICATED_ID;

const IndustryZone = ({ match }) => {
  const {
    params: { zoneType, zoneId },
  } = match;
  const { session } = useSelector((state) => state.auth);
  const [industryZoneListArray, setIndustryZoneListArray] = useState();
  const [industryZoneListLoaded, setIndustryZoneListLoaded] = useState(false);
  const [listLength, setListLength] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (!industryZoneListLoaded) {
      setIndustryZoneListLoaded(true);
      if (zoneType === "edit") {
        setLoader(true);
        UserService.getIndustryZoneList().then(
          (response) => {
            setLoader(false);
            if (response.status === 200) {
              setIndustryZoneListArray(
                response.data.filter((data) => data.id === parseInt(zoneId))[0]
              );
              setListLength(response.data.length);
            }
          },
          (error) => {}
        );
      } else {
        let izl = {
          placard_name: "",
          intro_videos: [],
          experience_configuration: [],
        };
        setIndustryZoneListArray(izl);
      }
    }
  });

  if (!session) {
    return <Redirect push to="/" />;
  } else if (session.user.role.id.toString() === ENV_AUTHENTICATED_ID) {
    window.location.href = "/admin";
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Industry Experience Zone | Infosys Living Labs</title>
        <meta name="description" content="" />
      </Helmet>
      {industryZoneListArray && industryZoneListLoaded && (
        <>
          <BreadCrumb
            CurrentPage={
              industryZoneListArray.placard_name
                ? industryZoneListArray.placard_name
                : " Add New"
            }
            prevPageName="Industry Experience Zone"
            prevPageURL="/admin/industry-zone"
          />

          <EditIndustryZoneMain
            industryZoneListArray={industryZoneListArray}
            listLength={listLength}
            zoneType={zoneType}
            zoneId={zoneId}
          />
        </>
      )}
      {loader && <LoaderGif />}
    </React.Fragment>
  );
};

export default IndustryZone;

const EditIndustryZoneMain = ({
  industryZoneListArray,
  listLength,
  zoneType,
  zoneId,
}) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const { register, handleSubmit, errors, setValue } = useForm({
    defaultValues: industryZoneListArray,
  });

  function onSubmitCreateIndustryZone(data) {
    let finalData = {};
    if (zoneType === "edit") {
      finalData["id"] = zoneId;
    }

    finalData["placard_name"] = data["placard_name"];

    let intro_video = JSON.parse(data.intro_video);
    let intro_video_ids = new Array();
    intro_video.map((video, i) => {
      intro_video_ids.push(video.id);
    });
    finalData["intro_videos"] = intro_video_ids;

    let expo_list = JSON.parse(data.expo_list);
    expo_list.map((exp, j) => {
      let exp_video_ids = new Array();
      exp.videos.map((video, i) => {
        exp_video_ids.push(video.id);
      });
      exp.videos = exp_video_ids;
    });

    finalData["experience_configuration"] = expo_list;
    dispatch(addEditIndustryZone(finalData, zoneType, zoneId))
      .then(() => {
        setLoader(false);
        if (zoneType === "add") {
          statusErrorSuccess("industry_zone_created", "success");
          window.location.href = "/admin/industry-zone";
        } else if (zoneType === "edit") {
          statusErrorSuccess("industry_zone_updated", "success");
          window.location.href =
            "/admin/industry-zone-add-edit/" + zoneType + "/" + zoneId;
        }
      })
      .catch(() => {
        statusErrorSuccess("industry_zone_failed", "danger");
        setLoader(false);
      });
  }

  return (
    <Form onSubmit={handleSubmit(onSubmitCreateIndustryZone)}>
      <IndustryZoneBar
        industryZoneListArray={industryZoneListArray}
        listLength={listLength}
        register={register}
        zoneType={zoneType}
      />
      <IndustryZoneContent
        industryZoneListArray={industryZoneListArray}
        listLength={listLength}
        setValue={setValue}
        register={register}
        errors={errors}
      />
    </Form>
  );
};

const IndustryZoneBar = ({ industryZoneListArray, listLength, zoneType }) => {
  return (
    <section className="event_bar_section for_industry_zone_">
      <Container fluid>
        <div className="events_cover">
          <div className="d-flex align-items-center">
            <div>
              <h4 className="events_title mb-1">Industry Experience Zone</h4>
            </div>
          </div>
          <div className="view_event_right_bar">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="mr-2 btn_green"
            >
              {zoneType === "add" ? "Create" : "Save"}
            </Button>
          </div>
        </div>
      </Container>
    </section>
  );
};

const IndustryZoneContent = ({
  industryZoneListArray,
  listLength,
  setValue,
  register,
  errors,
}) => {
  const [introVideoList, setIntroVideoList] = useState(
    industryZoneListArray.intro_videos ? industryZoneListArray.intro_videos : []
  );
  const [expConfigList, setExpConfigList] = useState(
    industryZoneListArray.experience_configuration
      ? industryZoneListArray.experience_configuration
      : []
  );

  const [stateLoaded, setStateLoaded] = useState(false);

  const changeMedia = (changeField, media, pos, expPos) => {
    switch (changeField) {
      case "intro_video":
        let temp = [...introVideoList];
        temp[pos] = media;
        setIntroVideoList(temp);
        break;
      case "exp_config":
        let tempExpo = [...expConfigList];
        tempExpo[expPos].videos[pos] = media;
        setExpConfigList(tempExpo);
        break;
      default:
        break;
    }
  };

  const handleAddMoreIntroVideo = () => {
    let temp = [...introVideoList];
    temp = temp.concat("");
    setIntroVideoList(temp);
  };

  const removeIntroVideo = (val, index) => {
    setIntroVideoList(introVideoList.filter((item, i) => i !== index));
  };

  const handleAddMoreExpConfigVideo = (expPos) => {
    let tempExpo = [...expConfigList];
    let temp = tempExpo[expPos].videos;
    temp = temp.concat("");
    tempExpo[expPos].videos = temp;
    setExpConfigList(tempExpo);
  };

  const removeExpConfigVideo = (val, index, expPos) => {
    let tempExpo = [...expConfigList];
    tempExpo[expPos].videos = tempExpo[expPos].videos.filter(
      (item, i) => i !== index
    );
    setExpConfigList(tempExpo);
  };

  const onNameChange = (e, expPos) => {
    let tempExpo = [...expConfigList];
    tempExpo[expPos].name = e.target.value;
  };

  const handleAddMoreIndustry = () => {
    let tempExpo = [...expConfigList];
    let temp = { name: "", videos: [] };
    tempExpo.push(temp);
    setExpConfigList(tempExpo);
  };

  useEffect(() => {
    setValue("intro_video", JSON.stringify(introVideoList));
    setValue("expo_list", JSON.stringify(expConfigList));
  }, [introVideoList, expConfigList]);

  return (
    <section className="for_industry_zone">
      {industryZoneListArray && (
        <Container fluid>
          <div className="industryzone_panel">
            <ul className="industry_zone_ul">
              <li className="industry_zone_li">
                <div className="industry_zone_body">
                  <Row>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                      <FormGroup className="material-group no_margin">
                        <FormControl variant="outlined">
                          <InputLabel
                            htmlFor="PlacardName"
                            className="label-caption"
                          >
                            Industry Experience Zone Name
                          </InputLabel>
                          <OutlinedInput
                            id="PlacardName"
                            type="text"
                            name="placard_name"
                            defaultValue={industryZoneListArray.placard_name}
                            className="material"
                            inputRef={register({
                              required: true,
                            })}
                          />
                          {errors.placard_name?.type === "required" && (
                            <p className="error_msg">Please provide name.</p>
                          )}
                        </FormControl>
                        {/* <FormControl variant="outlined">
                        <InputLabel htmlFor="PlacardName" className="label-caption">intro_video</InputLabel>
                          <OutlinedInput id="PlacardName" type="text" name="intro_video"
                            className="material"
                            inputRef={register({
                              required: false,
                            })} />
                        </FormControl>  */}
                      </FormGroup>
                      <input
                        type="hidden"
                        name="intro_video"
                        ref={register({ required: false })}
                      />
                      <input
                        type="hidden"
                        name="expo_list"
                        ref={register({ required: false })}
                      />
                    </Col>

                    <Col xl={6} lg={6}>
                      <ul className="carosel_message_ul">
                        {introVideoList.map((item, i) => (
                          <React.Fragment>
                            <DropZonePlaceHolder
                              key={"li_" + i}
                              holder={item ? ENV_MEDIA_URL + item.url : ""}
                              type="video"
                              fileName={item ? item.name : ""}
                              fileSizeText=""
                              labelName={"Intro Video " + (i + 1)}
                              ButtonText="Browse"
                              changeselectedmedia={(media) =>
                                changeMedia("intro_video", media, i)
                              }
                              removeMedia={() => removeIntroVideo(item, i)}
                              category={"Industry Experience Zone"}
                            />
                            {/* <p className="carosel_close_icon ml-4">
                            <img className="img-fluid" src="/images/close.svg" alt="" onClick={() => removeIntroVideo(item, i)} />
                          </p> */}
                          </React.Fragment>
                        ))}
                      </ul>
                      <p className="text-right mt-4">
                        <Button
                          variant="outlined"
                          color="primary"
                          className=""
                          onClick={() => handleAddMoreIntroVideo()}
                        >
                          Add More
                        </Button>
                      </p>
                    </Col>
                  </Row>
                </div>
              </li>
              <li className="industry_zone_li">
                <div className="industry_zone_head">
                  <h5 className="industry_zone_title mr-1">Industry Lists</h5>
                </div>
                {expConfigList && (
                  <React.Fragment>
                    {expConfigList.map((expConfig, j) => (
                      <div className="industry_zone_body">
                        <Row>
                          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <FormGroup className="material-group no_margin">
                              <FormControl variant="outlined">
                                <InputLabel
                                  htmlFor="ExperienceName"
                                  className="label-caption"
                                >
                                  Industry Name {j + 1}
                                </InputLabel>
                                <OutlinedInput
                                  id="ExperienceName"
                                  type="text"
                                  name={"expConfigList[" + j + "].name"}
                                  defaultValue={
                                    expConfig.name ? expConfig.name : ""
                                  }
                                  onChange={(e) => onNameChange(e, j)}
                                  className="material"
                                  inputRef={register({
                                    required: false,
                                  })}
                                />
                              </FormControl>
                            </FormGroup>
                          </Col>
                          <Col xl={6} lg={6}>
                            <ul className="carosel_message_ul">
                              {expConfig.videos.map((item, k) => (
                                <React.Fragment>
                                  <DropZonePlaceHolder
                                    key={"li_" + k}
                                    holder={
                                      item ? ENV_MEDIA_URL + item.url : ""
                                    }
                                    type="video"
                                    fileName={item ? item.name : ""}
                                    fileSizeText=""
                                    labelName={"Full Video " + (k + 1)}
                                    ButtonText="Browse"
                                    changeselectedmedia={(media) =>
                                      changeMedia("exp_config", media, k, j)
                                    }
                                    removeMedia={() =>
                                      removeExpConfigVideo(item, k, j)
                                    }
                                    category={"Industry Experience Zone"}
                                  />
                                </React.Fragment>
                              ))}
                            </ul>
                            <p className="text-right mt-4">
                              <Button
                                variant="outlined"
                                color="primary"
                                className=""
                                onClick={() => handleAddMoreExpConfigVideo(j)}
                              >
                                Add More
                              </Button>
                            </p>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </React.Fragment>
                )}
                <p className="text-right mt-4 pb-4 pr-4">
                  <Button
                    variant="outlined"
                    color="primary"
                    className=""
                    onClick={() => handleAddMoreIndustry()}
                  >
                    Add More Industries
                  </Button>
                </p>
              </li>
            </ul>
          </div>
        </Container>
      )}
    </section>
  );
};
