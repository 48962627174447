import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Modal, Form, Col, Row } from "react-bootstrap";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch } from "react-redux";
import LoaderGif from "../modules/loader";
import UserService from "../../services/user.service";
import { createShowcases, updateShowcases } from "../../actions/auth";
import { statusErrorSuccess } from "../../App";

const AddShowcase = (props) => {
  let editShowCaseData;
  let aresIdData;
  let showCaseChecked = false;

  if (props.showcasesaction === "EDIT") {
    editShowCaseData = props.showcasesrow;
    aresIdData = props.showcasesrow.default_area_id;
    showCaseChecked = props.showcasesrow.is_fixed_showcase;
  }
  const { register, handleSubmit, errors } = useForm({
    defaultValues: editShowCaseData,
  });

  const [value, setValue] = useState(aresIdData);

  const handleChangeDefaultArea = (e) => {
    setValue(e.target.value);
  };
  const [checked, setChecked] = useState(showCaseChecked);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const [areaList, setAreaList] = useState(new Map());
  const [areaListLoaded, setAreaListLoaded] = useState(false);
  const [tagValue, setTagValue] = useState();
  const [tagListLoaded, setTagListLoaded] = useState(false);
  // get area id
  useEffect(() => {
    if (!areaListLoaded) {
      UserService.getAreaList().then(
        (response) => {
          setAreaListLoaded(true);
          if (response.status === 200) {
            let mapAreaData = new Map();
            response.data.map((i) => mapAreaData.set(i.id, i));
            setAreaList(mapAreaData);
          }
        },
        (error) => {}
      );
    }
  });

  useEffect(() => {
    if (!tagListLoaded) {
      UserService.getMediaTages().then(
        (response) => {
          setTagListLoaded(true);
          if (response.status === 200) {
            setTagValue(response.data);
          }
        },
        (error) => {}
      );
    }
  });

  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  const onSubmitCreateShowcases = async (data) => {
    data.default_area_id = value;
    data.is_fixed_showcase = checked;
    setLoader(true);
    if (props.showcasesaction === "EDIT") {
      dispatch(updateShowcases(props.showcasesrow.id, data, "EDIT"))
        .then(() => {
          statusErrorSuccess("editShowcaseSuccess", "success");
          setLoader(false);
          props.onHide();
          window.location.href = "/admin/showcases";
        })
        .catch(() => {
          statusErrorSuccess("editShowcaseFailed", "danger");
          setLoader(false);
        });
    } else {
      dispatch(createShowcases(data))
        .then(() => {
          if (props.addCustome) {
            props.callBackShowCase(data.showcase_id);
          } else {
            statusErrorSuccess("addShowcaseSuccess", "success");
            setLoader(false);
            props.onHide();
            window.location.href = "/admin/showcases";
          }
        })
        .catch(() => {
          statusErrorSuccess("addShowcaseFailed", "danger");
          setLoader(false);
        });
    }
  };

  return (
    <React.Fragment>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="ad-area-modal area_global_popup add-showcase-modal"
      >
        <Modal.Header>
          {props.showcasesaction === "EDIT" ? (
            <h4 className="popup_title">Edit Showcases</h4>
          ) : (
            <h4 className="popup_title">Add Showcases</h4>
          )}
          <p className="popup_close_icon" onClick={props.onHide}>
            <img className="img-fluid" src="/images/close.svg" alt="" />
          </p>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmitCreateShowcases)}>
            <Row>
              <Col xl={6} lg={6}>
                <FormGroup className="material-group">
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="ShowcaseId" className="label-caption">
                      Showcase Id
                    </InputLabel>
                    <OutlinedInput
                      id="ShowcaseId"
                      type="number"
                      name="showcase_id"
                      className="material"
                      onChange={(event) =>
                        event.target.value < 0
                          ? (event.target.value = 0)
                          : event.target.value
                      }
                      inputRef={register({
                        required: true,
                      })}
                    />
                    {errors.showcase_id?.type === "required" && (
                      <p className="error_msg">Please enter showcase id.</p>
                    )}
                  </FormControl>
                </FormGroup>
              </Col>
              <Col xl={6} lg={6}>
                <FormGroup className="material-group">
                  <FormControl variant="outlined">
                    <InputLabel
                      htmlFor="ShowcasesName"
                      className="label-caption"
                    >
                      Showcases Name
                    </InputLabel>
                    <OutlinedInput
                      id="ShowcasesName"
                      type="text"
                      name="showcase_name"
                      className="material"
                      inputRef={register({
                        required: true,
                      })}
                    />
                    {errors.showcase_name?.type === "required" && (
                      <p className="error_msg">Please enter showcase name.</p>
                    )}
                  </FormControl>
                </FormGroup>
              </Col>
              <Col xl={4} lg={4}>
                <FormGroup className="material-group mb-0">
                  <FormControl variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">
                      Default Area ID
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={value ? value : ""}
                      onChange={handleChangeDefaultArea}
                      label="Default Area ID"
                      name="default_area_id"
                    >
                      {Array.from(areaList).map(([index, areaListData], i) => (
                        <MenuItem key={i} value={areaListData.area_id}>
                          {areaListData.area_id +
                            " - " +
                            areaListData.area_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FormGroup>
              </Col>
              <Col
                lg={6}
                md={6}
                sm={6}
                className="d-block checkbox_label pt-3 checkbox_label_for_showcase"
              >
                <FormGroup className="material-group d-inline-block mb-0">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        name="is_fixed_showcase"
                        color="primary"
                        value={true}
                      />
                    }
                    label="This area has a fixed showcase."
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col lg={12} className="mr-auto text-right">
                <FormGroup className="material-group d-inline-block">
                  {props.showcasesaction === "EDIT" ? (
                    <Button type="submit" variant="contained" color="primary">
                      Update
                    </Button>
                  ) : (
                    <Button type="submit" variant="contained" color="primary">
                      Create
                    </Button>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      {loader === true && <LoaderGif />}
    </React.Fragment>
  );
};
export default AddShowcase;
