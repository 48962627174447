import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';

const LoaderGif = () => {
  return (
    <div className="fixed_loader">
        <div className="loader">
          <img className="img-responsive" src="/images/loader.gif" alt=""/>
        </div>
      </div>
  );
}  
export default LoaderGif;

