import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { Container, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import DeleteConfirmation from "../popups/delete-confirmation";
const ENV_ADMIN_ID = process.env.REACT_APP_ADMIN_ID;

const EventBar = ({
  path,
  title,
  setSearchText,
  setFilterEmpty,
  masterEventCard,
  otherEventCard,
  session,
}) => {
  const [deleteConfirmationState, setDeleteConfirmationState] = useState(false);
  const [deleteConfirmationValue, setDeleteConfirmationValue] = useState("");

  const searchField = (e) => {
    setSearchText(e.target.value);
    setFilterEmpty(false);
  };

  return (
    <React.Fragment>
      {deleteConfirmationState === true && (
        <DeleteConfirmation
          setDeleteConfirmationState={setDeleteConfirmationState}
          show={deleteConfirmationState}
          onHide={() => setDeleteConfirmationState(false)}
          delete=""
          deleteConfirmationValue={deleteConfirmationValue}
          page=""
        />
      )}
      <section className="event_bar_section">
        <Container fluid>
          <div className="events_cover">
            <div className="d-flex align-items-center">
              <div>
                <h4 className="events_title">{title}</h4>
                {path === "/admin" && (
                  <p className="event_bar_url">
                    {masterEventCard.size + otherEventCard.size} Events
                  </p>
                )}
              </div>
              {path === "/admin" && (
                <Form inline>
                  <div className="form_group">
                    <input
                      type="search"
                      className="form_input_search"
                      name="event-search"
                      placeholder="Search Events"
                      onChange={(e) => searchField(e)}
                    />
                    <p className="event_search_icon">
                      <img
                        className="img-fluid"
                        src="/images/search.svg"
                        alt=""
                      />
                    </p>
                  </div>
                </Form>
              )}
            </div>
            {session.user.role.id.toString() === ENV_ADMIN_ID &&
              path === "/admin" && (
                <Button variant="contained" color="primary">
                  <Link to="/admin/new-event/master/new">
                    Create Master Event
                  </Link>
                </Button>
              )}
            {session.user.role.id.toString() === ENV_ADMIN_ID &&
              path !== "/admin" && (
                <div className="create_event_div">
                  <Button type="submit" variant="contained" color="primary">
                    Create Event
                  </Button>
                </div>
              )}
            {path !== "/admin" &&
              session.user.role.id.toString() !== ENV_ADMIN_ID && (
                <div className="create_event_div">
                  {/* <Button variant="outlined" color="secondary" onClick={() => setDeleteConfirmationState(true)}>Delete</Button> */}
                  <Button type="submit" variant="contained" color="primary">
                    Create Event
                  </Button>
                </div>
              )}
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default EventBar;
