import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Container, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Button from "@material-ui/core/Button";
import BreadCrumb from "../modules/breadcrumb";
import AddArea from "../popups/add-area";
import UserService from "../../services/user.service";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import DeleteConfirmation from "../popups/delete-confirmation";
import { useDispatch } from "react-redux";
import { areaActions } from "../../actions/auth";
import LoaderGif from "../modules/loader";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useMediaQuery } from "react-responsive";
import ReadMoreAndLess from "react-read-more-less";
import { statusErrorSuccess } from "../../App";

const ENV_MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

const Areas = ({ match }) => {
  const { session } = useSelector((state) => state.auth);
  const [areaList, setAreaList] = useState(new Map());
  const [areaListArray, setareaListArray] = useState();
  const [areaListLoaded, setAreaListLoaded] = useState(false);
  const [listLength, setListLength] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (!areaListLoaded) {
      setAreaListLoaded(true);
      setLoader(true);
      UserService.getAreaList().then(
        (response) => {
          setLoader(false);
          if (response.status === 200) {
            setareaListArray(response.data);
            setListLength(response.data.length);
            let mapAreaData = new Map();
            response.data.map((i) => mapAreaData.set(i.id, i));
            setAreaList(mapAreaData);
          }
        },
        (error) => {}
      );
    }
  });

  if (!session) {
    return <Redirect push to="/" />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Area | Infosys Living Labs</title>
        <meta name="description" content="" />
      </Helmet>
      <BreadCrumb
        CurrentPage="Area"
        prevPageName="Global Settings"
        prevPageURL="/admin/global-settings"
      />
      <AreaBar session={session} listLength={listLength} />
      {areaList && (
        <AreaContent
          session={session}
          areaList={areaList}
          areaListArray={areaListArray}
          loader={loader}
          setLoader={setLoader}
        />
      )}
      {loader && <LoaderGif />}
    </React.Fragment>
  );
};

export default Areas;

const AreaBar = ({ session, listLength }) => {
  const [addArea, setaddarea] = useState(false);
  return (
    <React.Fragment>
      {addArea === true && (
        <AddArea
          setaddarea={setaddarea}
          show={addArea}
          areaaction={"ADD"}
          onHide={() => setaddarea(false)}
        />
      )}
      <section className="event_bar_section">
        <Container fluid>
          <div className="events_cover">
            <div className="d-flex align-items-center">
              <div>
                <h4 className="events_title">Areas</h4>
                <p className="event_bar_url">
                  {listLength === null ? "0" : listLength} Areas
                </p>
              </div>
            </div>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setaddarea(true)}
            >
              Add Area
            </Button>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

const AreaContent = ({
  session,
  areaList,
  areaListArray,
  loader,
  setLoader,
}) => {
  const [deleteConfirmationState, setDeleteConfirmationState] = useState(false);
  const [deleteConfirmationValue, setDeleteConfirmationValue] = useState("");
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (event, id, oldValue) => {
    setLoader(true);
    let data = {};
    data.is_fixed_wallpaper = !oldValue;
    dispatch(areaActions(id, data, "EDIT"))
      .then(() => {
        setLoader(false);
        statusErrorSuccess("editAreaSuccess", "success");
        window.location.href = "/admin/areas";
      })
      .catch((error) => {
        setLoader(false);
        statusErrorSuccess("editAreaFailed", "danger");
      });
  };

  const [addArea, setaddarea] = useState(false);
  const [areaRow, setareaRow] = useState();
  const editArea = (ShowCasesRowData) => {
    setaddarea(true);
    setareaRow(ShowCasesRowData);
  };

  // delete
  const deleteCon = (id) => {
    setDeleteConfirmationValue(id);
    setDeleteConfirmationState(true);
  };

  const options = {
    sizePerPage: 10,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: true,
  };

  function iconsEdit(content, row, rowIndex, formatExtraData) {
    return (
      <React.Fragment>
        <p className="text-right delete_row">
          <img
            className="img-fluid mr-4"
            src="/images/edit.svg"
            alt=""
            onClick={() => editArea(row)}
          />
          <img
            className="img-fluid"
            src="/images/delete.svg"
            alt=""
            onClick={() => deleteCon(row.id)}
          />
        </p>
      </React.Fragment>
    );
  }

  function areaMedia(content, row, rowIndex, formatExtraData) {
    return (
      <p>
        <img
          className="img-fluid table_img"
          src={
            row.area_snapshot
              ? ENV_MEDIA_URL + row.area_snapshot.url
              : "/images/default_area_snapshot.png"
          }
          alt=""
        />
      </p>
    );
  }

  function areaDescription(content, row, rowIndex, formatExtraData) {
    return (
      <ReadMoreAndLess
        className="read-more-content"
        charLimit={100}
        readMoreText="Read more"
        readLessText="Read less"
      >
        {row.area_description}
      </ReadMoreAndLess>
    );
  }

  function fixedWallpaper(content, row, rowIndex, formatExtraData) {
    return (
      <FormGroup className="material-group m-0 d-inline-block">
        <FormControlLabel
          control={
            <Checkbox
              checked={row.is_fixed_wallpaper ? true : false}
              onChange={(e) =>
                handleChange(e.target.value, row.id, row.is_fixed_wallpaper)
              }
              name={"checked" + row.id}
              color="primary"
              value={true}
            />
          }
          label="Allow / Disallow branding / wallpaper options."
        />
      </FormGroup>
    );
  }

  const isDesktopOrLaptop = useMediaQuery({
    query: "(max-device-width: 1400px)",
  });

  const columns = [
    {
      dataField: "areaMedia(test)",
      text: "Snapshot",
      formatter: areaMedia,
      headerStyle: (colum, colIndex) => {
        return {
          width: "150px",
        };
      },
    },
    {
      dataField: "area_id",
      text: "Area Id",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return {
          width: "100px",
        };
      },
    },
    {
      dataField: "area_name",
      text: "Area Name",
      headerStyle: (colum, colIndex) => {
        return isDesktopOrLaptop ? { width: "130px" } : { width: "160px" };
      },
    },
    {
      dataField: "area_description",
      formatter: areaDescription,
      text: "Area Description",
      headerStyle: (colum, colIndex) => {
        return isDesktopOrLaptop ? { width: "30%" } : { width: "30%" };
      },
    },
    {
      dataField: "fixedWallpaper",
      text: "",
      formatter: fixedWallpaper,
      headerStyle: (colum, colIndex) => {
        return { width: "25%" };
      },
    },
    {
      dataField: "iconEdit",
      text: "",
      formatter: iconsEdit,
    },
  ];

  return (
    <React.Fragment>
      {addArea === true && (
        <AddArea
          setaddarea={setaddarea}
          show={addArea}
          arearow={areaRow}
          areaaction={"EDIT"}
          onHide={() => setaddarea(false)}
        />
      )}

      {deleteConfirmationState === true && (
        <DeleteConfirmation
          setDeleteConfirmationState={setDeleteConfirmationState}
          show={deleteConfirmationState}
          onHide={() => setDeleteConfirmationState(false)}
          delete={() => deleteCon()}
          deleteConfirmationValue={deleteConfirmationValue}
          page="area-page"
          deleteMessage=" Are you sure you want to delete this area? /n On deleting this area connected experience will be affected."
        />
      )}
      {areaListArray && (
        <section className="media_tag_section">
          <Container fluid>
            <div className="cover_tabel_area media_tag_table">
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={areaListArray}
                columns={columns}
                pagination={paginationFactory(options)}
              />
            </div>
          </Container>
        </section>
      )}

      {loader === true && <LoaderGif />}
    </React.Fragment>
  );
};
