import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { Component, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Form } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Redirect, Link } from "react-router-dom";
import LoaderGif from "./modules/loader";
import { statusErrorSuccess } from "../App";
import Keycloak from "keycloak-js";
import UserService from "../services/user.service";
import UserInfo from "./UserInfo";

const Home = ({ keycloak }) => {
  const { session, isLoggedIn } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  const [validatUserLoaded, setValidatUserLoaded] = useState(false);
  const [reactToken, setReactToken] = useState();
  const [collaboratorByEmail, setCollaboratorByEmail] = useState(false);

  useEffect(() => {
    let getReactToken = localStorage.getItem("react-token");
    if (!validatUserLoaded && getReactToken) {
      setReactToken(getReactToken);
      setLoader(true);
      setValidatUserLoaded(true);
      UserService.loginValidate(getReactToken).then(
        (response) => {
          if (response.data.jwt) {
            localStorage.setItem("session", JSON.stringify(response.data));
            UserService.getCollaboratorByEmail(
              response.data.user.username.toLowerCase()
            ).then(
              (response1) => {
                setLoader(false);
                if (response1) {
                  setCollaboratorByEmail(true);
                  window.location.href = "/admin";
                }
              },
              (error) => {}
            );
          }
        },
        (error) => {}
      );
    }
  });

  if (isLoggedIn && session) {
    return <Redirect push to="/admin" />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Infosys Living Labs</title>
        <meta name="description" content="" />
      </Helmet>
      <section className="sign_in_form">
        <Container>
          <section className="sign_in_form">
            <Container>
              <Form className="signin_form">
                <div className="UserInfo">
                  {keycloak && <UserInfo keycloak={keycloak} />}
                  {!keycloak && <p>Validating your details.</p>}
                </div>
              </Form>
            </Container>
          </section>
        </Container>
      </section>
      {loader === true && <LoaderGif />}
    </React.Fragment>
  );
};

export default Home;
