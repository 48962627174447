import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Router, Switch, Route, Link } from "react-router-dom";
import { Navbar, Nav, Container, Dropdown } from "react-bootstrap";
import ReactNotification, { store } from "react-notifications-component";
import Avatar from "@material-ui/core/Avatar";
import { Offline, Online, Detector } from "react-detect-offline";

import Keycloak from "keycloak-js";
import Logout from "./components/Logout";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-tabs/style/react-tabs.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/fonts/font-awesome/css/font-awesome.css";
import "./assets/css/nav.css";
import "./assets/css/dashboard.css";
import "./assets/css/form.css";
import "./assets/css/sign-in.css";
import "./assets/css/event-bar.css";
import "./assets/css/card.css";
import "./assets/css/new-event.css";
import "./assets/css/view-event.css";
import "./assets/css/event-setting.css";
import "./assets/css/edit-experience.css";
import "./assets/css/global-settings.css";
import "./assets/css/people.css";
import "./assets/css/media-library.css";
import "./assets/css/industry-zone.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "react-notifications-component/dist/theme.css";

import Home from "./components/Home";
import Events from "./components/admin/index";
import NewEvent from "./components/admin/new-event";
import ViewEvent from "./components/admin/view-event";
import ViewMasterEvent from "./components/admin/view-master-event";
import AddExperiences from "./components/admin/add-experience";
import EditExperiences from "./components/admin/edit-experience";
import MediaLibrary from "./components/admin/media-library";
import EventSetting from "./components/admin/event-setting";
import People from "./components/admin/people";
// import AddPeople from "./components/admin/add-people";
// import EditPeople from "./components/admin/edit-people";
import GlobalSettings from "./components/admin/global-settings";
import Areas from "./components/admin/areas";
import Showcases from "./components/admin/showcases";
import IndustryZone from "./components/admin/industry-zone";
import IndustryZoneAddEdit from "./components/admin/industry-zone-add-edit";
import EventAccess from "./components/admin/event-access";

// import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import { history } from "./helpers/history";

import success_error_message from "./assets/json/success_error_message";
import cms_button_details_json from "./assets/json/cms_button_details";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

const ENV_ADMIN_ID = process.env.REACT_APP_ADMIN_ID;
const ENV_AUTHENTICATED_ID = process.env.REACT_APP_AUTHENTICATED_ID;
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export const statusErrorSuccess = (response, statusType, source = "json") => {
  store.addNotification({
    message: source === "message" ? response : success_error_message[response],
    type: statusType,
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    isMobile: true,
    dismiss: {
      duration: 2000,
      onScreen: true,
      pauseOnHover: true,
      showIcon: true,
      click: true,
      touch: true,
    },
    touchSlidingExit: {
      swipe: {
        duration: 2000,
        timingFunction: "ease-out",
        delay: 0,
      },
    },
  });
};

const App = (props) => {
  const { session } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.message);
  const [keyCloakData, setKeyCloakData] = useState(null);
  const [keyCloakAuthenticated, setKeyCloakAuthenticated] = useState(false);

  useEffect(() => {
    history.listen((location) => {
      dispatch(clearMessage()); // clear message when changing location
    });
  }, [dispatch]);

  useEffect(() => {
    if (!keyCloakData && !keyCloakAuthenticated) {
      const keycloak = Keycloak("/keycloak-llcmsui.json");
      keycloak.init({ onLoad: "login-required" }).then((authenticated) => {
        setKeyCloakData(keycloak);
        setKeyCloakAuthenticated(authenticated);
        localStorage.setItem("react-token", keycloak.token);
        localStorage.setItem("react-refresh-token", keycloak.refreshToken);
      });
    }
  });

  /*const logOut = () => {
    dispatch(logout());
  };*/

  return (
    <Router history={history}>
      <div className="app-container container-fluid">
        <ReactNotification />
      </div>
      <div
        className={history.location.pathname === "/" ? "wrapper" : "wrapper"}
      >
        {history.location.pathname !== "/" &&
          history.location.pathname !== "/forgot-password" &&
          history.location.pathname !== "/reset-password" && (
            <img
              className="img-fluid fixed_bottom_bg"
              src="/images/event_bg.png"
              alt=""
            />
          )}
        <NavigationSection
          session={session}
          history={history}
          keyCloakData={keyCloakData}
        />
        <Detector
          render={({ online }) => (
            <>
              {/* {!online && (
                <div className="container-fluid offline_now">
                  You're offline right now. Check your internet connection.
                </div>
              )} */}
            </>
          )}
        />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/admin" component={Events} />
          <Route
            exact
            path="/admin/new-event/:eventType/:eventId"
            component={NewEvent}
          />
          <Route
            exact
            path="/admin/view-event/:eventType/:eventId"
            component={ViewEvent}
          />
          <Route
            exact
            path="/admin/view-master-event/:eventType/:eventId"
            component={ViewMasterEvent}
          />
          <Route
            exact
            path="/admin/add-experience/:eventType/:eventId/:areaId"
            component={AddExperiences}
          />
          <Route
            exact
            path="/admin/edit-experience/:eventType/:eventId/:areaId"
            component={EditExperiences}
          />
          <Route
            exact
            path="/admin/event-setting/:eventType/:eventId"
            component={EventSetting}
          />
          <Route
            exact
            path="/admin/event-access/:eventType/:eventId"
            component={EventAccess}
          />
          <Route exact path="/admin/media-library" component={MediaLibrary} />
          {/* <Route exact path="/admin/access" component={People} /> */}
          <Route exact path="/admin/industry-zone" component={IndustryZone} />
          <Route
            exact
            path="/admin/industry-zone-add-edit/:zoneType/:zoneId/"
            component={IndustryZoneAddEdit}
          />

          {/* <Route exact path="/admin/add-people" component={AddPeople} />
          <Route
            exact
            path="/admin/edit-people/:peopleId"
            component={EditPeople}
          /> */}
          {/* <Route
            exact
            path="/admin/global-settings"
            component={GlobalSettings}
          /> */}
          <Route exact path="/admin/areas" component={Areas} />
          <Route exact path="/admin/showcases" component={Showcases} />
        </Switch>
      </div>
    </Router>
  );
};

export default App;

const NavigationSection = ({ session, history, keyCloakData }) => {
  function shortName(name) {
    var matches = name.match(/\b(\w)/g);
    return matches.join("");
  }
  const [logoutToken, setLogoutToken] = useState();

  useEffect(() => {
    let getReactToken = localStorage.getItem("react-token");
    if (!logoutToken && getReactToken) {
      setLogoutToken(getReactToken);
    }
  }, [logoutToken]);

  return (
    <React.Fragment>
      {!session && (
        <Container>
          <div className="logo_cover">
            <img className="img-fluid" src="/images/logo.svg" alt="" />
          </div>
        </Container>
      )}
      {session && (
        <Navbar expand="md" bg="dark" variant="dark">
          <Link to="/admin" className="navbar-brand">
            <img
              className="d-inline-block align-top"
              src="/images/infosys-logo.svg"
              alt=""
            />
          </Link>
          <Nav className="mr-auto">
            <HtmlTooltip
              title={
                <Typography color="inherit">
                  {cms_button_details_json.event_menu_detail}
                </Typography>
              }
            >
              <Link
                to="/admin"
                className={`nav-link ${
                  history.location.pathname === "/admin" && "active"
                }`}
              >
                Events
              </Link>
            </HtmlTooltip>
            {(session.user.role.id.toString() === ENV_AUTHENTICATED_ID ||
              session.user.role.id.toString() === ENV_ADMIN_ID) && (
              <HtmlTooltip
                title={
                  <Typography color="inherit">
                    {cms_button_details_json.media_library_menu_detail}
                  </Typography>
                }
              >
                <Link
                  to="/admin/media-library"
                  className={`nav-link ${
                    history.location.pathname === "/admin/media-library" &&
                    "active"
                  }`}
                >
                  Media Library
                </Link>
              </HtmlTooltip>
            )}
            {session.user.role.id.toString() === ENV_ADMIN_ID && (
              <HtmlTooltip
                title={
                  <Typography color="inherit">
                    {cms_button_details_json.industry_zone_menu_detail}
                  </Typography>
                }
              >
                <Link
                  to="/admin/industry-zone"
                  className={`nav-link ${
                    history.location.pathname === "/admin/industry-zone" &&
                    "active"
                  }`}
                >
                  Industry Experience Zone
                </Link>
              </HtmlTooltip>
            )}
            {/* {(session.user.role.id.toString() === ENV_AUTHENTICATED_ID ||
              session.user.role.id.toString() === ENV_ADMIN_ID) && (
              <HtmlTooltip
                title={
                  <Typography color="inherit">
                    {cms_button_details_json.access_menu_detail}
                  </Typography>
                }
              >
                <Link
                  to="/admin/access"
                  className={`nav-link ${
                    (history.location.pathname === "/admin/access" ||
                      history.location.pathname === "/admin/add-people") &&
                    "active"
                  }`}
                >
                  Access
                </Link>
              </HtmlTooltip>
            )} */}
            {/* {(session.user.role.id.toString() === ENV_AUTHENTICATED_ID ||
              session.user.role.id.toString() === ENV_ADMIN_ID) && (
              <HtmlTooltip
                title={
                  <Typography color="inherit">
                    {cms_button_details_json.global_setting_menu_detail}
                  </Typography>
                }
              >
                <Link
                  to="/admin/global-settings"
                  className={`nav-link ${
                    (history.location.pathname === "/admin/global-settings" ||
                      history.location.pathname === "/admin/areas" ||
                      history.location.pathname === "/admin/showcases") &&
                    "active"
                  }`}
                >
                  Global Settings
                </Link>
              </HtmlTooltip>
            )} */}
            {/* <HtmlTooltip title={
                  <Typography color="inherit">{cms_button_details_json.global_setting_menu_detail}</Typography>
                }>                
                <Link to="/admin/event-access" className={`nav-link ${((history.location.pathname === "/admin/event-access")  && ("active"))}`}>Event Access</Link>
              </HtmlTooltip> */}
          </Nav>
          <div>
            <div className="d-flex align-items-center">
              <Dropdown className="dropdown_box">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="user_info"
                >
                  {session.user.username}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <ul className="dropdown-items">
                    <li className="d-flex align-items-center">
                      <div className="mr-3">
                        <Avatar className="avtar_bg_4">
                          {shortName(session.user.username)}
                        </Avatar>
                      </div>
                      <div className="user_information">
                        <h4 className="user_name">{session.user.username}</h4>
                        <p className="user_email">
                          {session.user.email.toLowerCase()}
                        </p>
                      </div>
                    </li>
                    <Dropdown.Divider />
                    {/* <li className="cover_clickable p-0">
                      <Navbar.Text className="nav-link w-100" onClick={logOut}>
                        Logout
                      </Navbar.Text>
                    </li> */}
                    {keyCloakData && (
                      <li className="cover_clickable p-0">
                        <Logout keycloak={keyCloakData} />
                      </li>
                    )}
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Navbar>
      )}
    </React.Fragment>
  );
};
