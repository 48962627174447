import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import MediaLibraryPopup from "../popups/media-library-popup";
import DeleteConfirmation from "../popups/delete-confirmation";

const DropZonePlaceHolder = ({ holder,  type, category, fileName, labelName, ButtonText, fileSizeText, changeselectedmedia, removeMedia }) => {
  const [mediaLibraryState, setmedialibrarystate] = useState(false);
  const [deleteConfirmationState, setDeleteConfirmationState] = useState(false);
  const [deleteConfirmationValue, setDeleteConfirmationValue] = useState("");
  return (
    <React.Fragment>
      {mediaLibraryState === true && (
        <MediaLibraryPopup 
          setmedialibrarystate={setmedialibrarystate}
          show={mediaLibraryState}
          onHide={() => setmedialibrarystate(false)}
          changeselectedmedia={changeselectedmedia}
          type={type}
          category={category}
        />
      )}
      {deleteConfirmationState === true && (
      <DeleteConfirmation 
        setDeleteConfirmationState={setDeleteConfirmationState}
        show={deleteConfirmationState}
        onHide={() => setDeleteConfirmationState(false)}
        delete={()=> removeMedia()}
        deleteConfirmationValue={deleteConfirmationValue}
        page=""
        deleteMessage="Are you sure you want to remove this media?"
       />
      )}
      <div className="placeholder_div mb-2 mr-2">
       <div className="upload_input">
        <label className="material_label">{labelName}</label>
        <div className="d-flex">
            {(holder === "" ||  holder === null) ?
              holder === "" ?
                  <div className="dragdrop_box"> 
                  <img className="img-fluid" src="/images/upload.svg" alt="" />
                  <p className="dragdrop_box_text">Browse to select an asset.</p>
                  </div>
                :
                  <div className="dragdrop_box"> 
                  <img className="img-fluid" src="/images/default_area_snapshot.png" alt="" />
                  </div>
        :
         <React.Fragment>
            {
              {
                    'image':
                      <div className="preview_upload_img">
                      <img key={holder} className="uploaded_file_preview img-fluid" src={holder} alt="" />
                      {removeMedia && (
                      <span onClick={() => setDeleteConfirmationState(true)} className="close_upload_span">
                        <img className="img-fluid close_upload_view" src="/images/ind_close.svg" alt="" />
                      </span>
                      )}
                      </div>,                
                    'video':
                      <div className="preview_upload_img for_video_upload">
                          <video key={holder} controls>
                            <source src={holder} type="video/mp4" />
                          </video>
                        {removeMedia && (
                        <span onClick={() => setDeleteConfirmationState(true)} className="close_upload_span">
                          <img className="img-fluid close_upload_view" src="/images/ind_close.svg" alt="" />
                        </span>
                        )}
                      </div>,
                    'audio':
                      <div className="preview_upload_img for_video_upload audio_bg">
                        <audio key={holder} controls>
                          <source src={holder} type="audio/mp3" />
                        </audio>
                        {removeMedia && (
                        <span onClick={() => setDeleteConfirmationState(true)} className="close_upload_span">
                          <img className="img-fluid close_upload_view" src="/images/ind_close.svg" alt="" />
                        </span>
                        )}
                     </div>,
                    'application':
                      <div className="preview_upload_img">
                        <img className="uploaded_file_preview img-fluid" src={holder} alt="" />
                        {removeMedia && (
                        <span onClick={() => setDeleteConfirmationState(true)} className="close_upload_span">
                          <img className="img-fluid close_upload_view" src="/images/ind_close.svg" alt="" />
                        </span>
                        )}
                      </div>,
              }[type]
            }
         </React.Fragment>
        }
         <div className="preview_img_detail">
          <div className="w-100 text-left">
            <p className="uploaded_file_name">{(!fileName || fileName==="") ? "No files selected." : fileName }</p>
            <p className="maximum_size">{fileSizeText}</p>
          </div>
            {ButtonText === "Browse" && (
              <Button variant="contained" color="primary" onClick={() => setmedialibrarystate(true)}>{ButtonText}</Button>
            )}
            {ButtonText === "Remove" && (
              <Button variant="outlined" color="primary" className="remove_btn">Remove</Button>
            )}
         </div>
        </div>
       </div>
     </div>
    </React.Fragment>
  );
}  
export default DropZonePlaceHolder;
