import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Modal, Form, Col, Row } from "react-bootstrap";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";

// import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import {
  createUser,
  createCollaborator,
  sendEmail,
  editEvent,
} from "../../actions/auth";
import LoaderGif from "../modules/loader";
import UserService from "../../services/user.service";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import { statusErrorSuccess } from "../../App";
const COLLABORATOR_MAIL_TO = process.env.REACT_APP_COLLABORATOR_MAIL_TO;
const REACT_APP_MAIL_FROM = process.env.REACT_APP_MAIL_FROM;
const REACT_APP_MAIL_CREATE_USER_SUBJECT =
  process.env.REACT_APP_MAIL_CREATE_USER_SUBJECT;
const REACT_APP_MAIL_ADD_COLLABORATOR_SUBJECT =
  process.env.REACT_APP_MAIL_ADD_COLLABORATOR_SUBJECT;
const ENV_MAIL_HEADER = process.env.REACT_APP_MAIL_HEADER;
const ENV_MAIL_FOOTER = process.env.REACT_APP_MAIL_FOOTER;
const ENV_VLL_DEMO_URL = process.env.REACT_APP_VLL_DEMO_URL;
const ENV_FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
const ENV_DEFAULT_LANG = process.env.REACT_APP_DEFAULT_LANG;
const ENV_EVENT_URL = process.env.REACT_APP_EVENT_URL;
const ENV_ALLOWED_EMAILS = process.env.REACT_APP_ALLOWED_EMAILS;
const ENV_ALLOWED_EMAILS_MSG = process.env.REACT_APP_ALLOWED_EMAILS_MSG;

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: 500,
//     "& > * + *": {
//       marginTop: theme.spacing(3),
//     },
//   },
// }));

const AddCollaborators = (props) => {
  // const classes = useStyles();
  const { createClbData, createUserError } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const [loader, setLoader] = useState(false);
  // const setvalue = props.eventCard.collaborator_ids.split(',');
  // const [selectedValue, setSelectedValue] = useState(setvalue); // Set exist collaborator
  const [selectedValue, setSelectedValue] = useState(
    props.eventCard.collaborator_ids
      ? props.eventCard.collaborator_ids.split(",")
      : []
  ); // Set exist collaborator

  const [value, setValue] = React.useState([]);
  const [optionsValue, setOptionsValue] = useState([]);
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const [loadUserCreation, setLoadUserCreation] = useState(false);
  const [languageVal, setLanguageVal] = useState(
    props.eventCard.base_configuration[0].language
      ? props.eventCard.base_configuration[0].language
      : ENV_DEFAULT_LANG
  );

  const handleChange = (newValue) => {
    let temp = [];
    newValue.map((val, i) => {
      temp.push(val.value.id.toString());
    });
    setSelectedValue(temp);
  };

  let existCollaborator = props.eventCard.collaborator_ids
    ? props.eventCard.collaborator_ids.split(",")
    : [];
  existCollaborator = new Set(existCollaborator);

  let allowedEmails = ENV_ALLOWED_EMAILS;
  let allowedEmailsArray = allowedEmails.split(",");
  let allowedEmailsMsg = ENV_ALLOWED_EMAILS_MSG + ENV_ALLOWED_EMAILS;

  useEffect(() => {
    if (createClbData) {
      let event_update = {};
      event_update.collaborator_ids =
        selectedValue.join(",") + "," + createClbData.id;
      dispatch(editEvent(event_update, props.eventType, props.eventCard.id))
        .then(() => {
          if (props.eventType === "master") {
            window.location.href =
              "/admin/view-master-event/" +
              props.eventType +
              "/" +
              props.eventCard.access_id;
          } else {
            window.location.href =
              "/admin/view-event/" +
              props.eventType +
              "/" +
              props.eventCard.access_id;
          }
        })
        .catch((error) => {});
    }
  }, [createClbData]);

  useEffect(() => {
    if (loadUserCreation && createUserError) {
      setLoadUserCreation(false);
      statusErrorSuccess(
        createUserError.response.data.message[0].messages[0].message,
        "danger",
        "message"
      );
    }
  }, [createUserError]);

  const validateAllowedEmails = (email, emailList) => {
    if (email !== "") {
      for (let i = 0; i <= allowedEmailsArray.length - 1; i++) {
        if (email.indexOf(allowedEmailsArray[i]) >= 0) {
          return true;
        }
      }
      return false;
    } else {
      for (let j = 0; j < emailList.length; j++) {
        let found = false;
        for (let i = 0; i <= allowedEmailsArray.length - 1; i++) {
          if (emailList[j].indexOf(allowedEmailsArray[i]) >= 0) {
            found = true;
          }
        }
        if (!found) {
          return false;
        }
      }
      return true;
    }
  };

  const onSubmitCreateUser = async (data) => {
    setLoader(true);
    setLoadUserCreation(true);
    if (data.name !== "" && data.email !== "") {
      // Create user
      data.email = data.email.toLowerCase();
      data.username = data.name;
      data.role = 1;
      data.authenticated = ["media_library", "industry_zone", "people"];
      data.confirmed = true;
      data.blocked = false;
      let emailValidationSuccess = false;
      if (allowedEmails) {
        emailValidationSuccess = validateAllowedEmails(data.email, []);
      }
      if (!emailValidationSuccess) {
        setLoader(false);
        statusErrorSuccess(allowedEmailsMsg, "danger", "message");
      } else {
        dispatch(createCollaborator(data, null, "ADD"))
          .then(() => {
            let sendEmailData = {};
            sendEmailData.from = REACT_APP_MAIL_FROM;
            sendEmailData.to = [];
            sendEmailData.to[0] = data.email;
            sendEmailData.subject = REACT_APP_MAIL_ADD_COLLABORATOR_SUBJECT;
            sendEmailData.html =
              ENV_MAIL_HEADER +
              "<tr style='background:#fff; text-align: left;'>\
                  <td style='padding: 16px 30px 30px; text-align: left; font-size: 16px; line-height:1.5;'>\
                     <h4 style='color: #7E7E7E; font-size: 19px; line-height: 1.5; font-weight: 700; margin:16px 0; padding: 0; text-transform:capitalize;'>Hello!</h4>\
                     <p style='color: #696969; font-size: 16px; line-height: 1.5; margin: 16px 0 20px; padding: 0;'>You have been invited as a collaborator to the Virtual Living Labs Event with the unique URL of <span style='color: #7E7E7E; font-weight:bold; font-size:16px; line-height:1.5; '>" +
              props.eventCard.event_name +
              "</span>.</p>\
              <p style='color: #696969; font-size: 16px; line-height: 1.5; margin: 16px 0 20px; padding: 0;'>Please login to the <a class='color:#097DBF;' href=" +
              ENV_FRONTEND_URL +
              ">Virtual Living Labs Content Management System</a> using your Infosys credentials to edit the event.</p>\
                     <p style='color: #696969; font-size: 16px; line-height: 1.5; margin: 16px 0 20px; padding: 0;'>Happy Collaborating!</p>" +
              ENV_MAIL_FOOTER;
            dispatch(sendEmail(sendEmailData))
              .then(() => {
                statusErrorSuccess("addPeopleCollaboratorSuccess", "success");
                setLoader(false);
                props.onHide();
              })
              .catch((error) => {
                statusErrorSuccess("addCollaboratorFailed", "danger");
                setLoader(false);
              });
          })
          .catch((error) => {
            statusErrorSuccess("addPeopleFailed", "danger");
            setLoader(false);
          });
      }
    } else {
      // add existing user only
      let event_update = {};
      event_update.collaborator_ids = selectedValue.join(",");
      let sendMailUserId = selectedValue.filter(
        (x) => !existCollaborator.has(x)
      );

      let sendMailIds = optionsValue.map((cllb, i) => {
        if (sendMailUserId.indexOf(cllb.value.id.toString()) > -1) {
          return cllb.label;
        }
      });

      sendMailIds = sendMailIds.filter(function (element) {
        return element !== undefined;
      });

      let emailValidationSuccess = false;
      if (allowedEmails) {
        emailValidationSuccess = validateAllowedEmails("", sendMailIds);
      }
      if (!emailValidationSuccess) {
        setLoader(false);
        statusErrorSuccess(allowedEmailsMsg, "danger", "message");
      } else {
        dispatch(editEvent(event_update, props.eventType, props.eventCard.id))
          .then(() => {
            if (sendMailIds.length > 0) {
              let sendEmailData = {};
              sendEmailData.from = REACT_APP_MAIL_FROM;
              sendEmailData.to = sendMailIds;
              sendEmailData.subject = REACT_APP_MAIL_ADD_COLLABORATOR_SUBJECT;
              sendEmailData.html =
                ENV_MAIL_HEADER +
                "<tr style='background:#fff; text-align: left;'>\
                  <td style='padding: 16px 30px 30px; text-align: left; font-size: 16px; line-height:1.5;'>\
                     <h4 style='color: #7E7E7E; font-size: 19px; line-height: 1.5; font-weight: 700; margin:16px 0; padding: 0; text-transform:capitalize;'>Hello!</h4>\
                     <p style='color: #696969; font-size: 16px; line-height: 1.5; margin: 16px 0 20px; padding: 0;'>You have been invited as a collaborator to the Virtual Living Labs Event with the unique URL of <span style='color: #7E7E7E; font-weight:bold; font-size:16px; line-height:1.5; '>" +
                props.eventCard.event_name +
                "</span>.</p>\
              <p style='color: #696969; font-size: 16px; line-height: 1.5; margin: 16px 0 20px; padding: 0;'>Please login to the <a class='color:#097DBF;' href=" +
                ENV_FRONTEND_URL +
                ">Virtual Living Labs Content Management System</a> using your Infosys credentials to edit the event.</p>\
                     <p style='color: #696969; font-size: 16px; line-height: 1.5; margin: 16px 0 20px; padding: 0;'>Happy Collaborating!</p>" +
                ENV_MAIL_FOOTER;
              dispatch(sendEmail(sendEmailData))
                .then(() => {
                  setLoader(false);
                  statusErrorSuccess("inviteCollaboratorsSuccess", "success");
                  if (props.eventType === "master") {
                    window.location.href =
                      "/admin/view-master-event/" +
                      props.eventType +
                      "/" +
                      props.eventCard.access_id;
                  } else {
                    window.location.href =
                      "/admin/view-event/" +
                      props.eventType +
                      "/" +
                      props.eventCard.access_id;
                  }
                })
                .catch((error) => {
                  setLoader(false);
                  statusErrorSuccess("inviteCollaboratorsFailed", "danger");
                });
            } else {
              setLoader(false);
              statusErrorSuccess("inviteCollaboratorsSuccess", "success");
              if (props.eventType === "master") {
                window.location.href =
                  "/admin/view-master-event/" +
                  props.eventType +
                  "/" +
                  props.eventCard.access_id;
              } else {
                window.location.href =
                  "/admin/view-event/" +
                  props.eventType +
                  "/" +
                  props.eventCard.access_id;
              }
            }
          })
          .catch((error) => {
            setLoader(false);
            statusErrorSuccess("inviteCollaboratorsFailed", "danger");
          });
      }
    }
  };

  useEffect(() => {
    if (!optionsLoaded) {
      UserService.getCollaboratorsList().then(
        (response) => {
          setOptionsLoaded(true);
          if (response.status === 200) {
            let tempValue = [];

            const reformattedData = response.data.map((data) => {
              if (selectedValue.includes(data.id.toString())) {
                tempValue.push({
                  value: data,
                  label: data.email,
                });
              }
              return {
                value: data,
                label: data.email,
              };
            });
            setOptionsValue(reformattedData);
            setValue(tempValue);
          }
        },
        (error) => {}
      );
    }
  });

  const classes = useStyles();

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const handleDelete = () => {
    console.info("You clicked the delete icon.");
  };

  return (
    <React.Fragment>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="ad-collaborators-modal"
      >
        <Modal.Header>
          <h4 className="popup_title">Add Collaborators</h4>
          <p className="popup_close_icon" onClick={props.onHide}>
            <img className="img-fluid" src="/images/close.svg" alt="" />
          </p>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmitCreateUser)}>
            <div className="cover_multi_input">
              <h4 className="multi_select_title_drop">
                Choose From Existing Users
              </h4>
              <Row className="pb-2">
                <Col lg={12} className="multi_select_dropdown">
                  <FormGroup className="material-group without_border w-100 mb-0">
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Autocomplete
                        multiple
                        id="fixed-tags-demo"
                        value={value}
                        onChange={(event, newValue) => {
                          setValue([...newValue]);
                          handleChange(newValue);
                        }}
                        options={optionsValue}
                        getOptionLabel={(option) => option.label}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              label={option.label}
                              {...getTagProps({ index })}
                              //    disabled={fixedOptions.indexOf(option) !== -1}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label="Choose From Existing Users"
                            variant="outlined"
                            placeholder="Collaborator"
                          />
                        )}
                      />
                      {/* <Select
                        labelId="outlined-age-native-simple"
                        id="outlined-age-native-simple"
                        multiple
                        value={selectedValue}
                        onChange={handleChange}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={(selected) => (
                          <div className={classes.chips}>
                            {selected.map((value, j) => (
                              <React.Fragment key={"Frag_" + value + j}>
                                {optionsValue.filter(
                                  (i) => i.value.id.toString() === value
                                ).length > 0 && (
                                  <Chip
                                    key={value}
                                    onDelete={handleDelete}
                                    label={
                                      optionsValue.filter(
                                        (i) => i.value.id.toString() === value
                                      )[0].label
                                    }
                                    className={classes.chip}
                                  />
                                )}
                              </React.Fragment>
                            ))}
                          </div>
                        )}
                        MenuProps={MenuProps}
                      >
                        {optionsValue.map((name) => (
                          <MenuItem
                            key={name.value.id}
                            value={name.value.id.toString()}
                          >
                            <Checkbox
                              checked={
                                selectedValue.indexOf(
                                  name.value.id.toString()
                                ) > -1
                              }
                            />
                            <ListItemText primary={name.label} />
                          </MenuItem>
                        ))}
                      </Select> */}
                    </FormControl>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="pb-4 text-right">
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </div>
            <p className="or_text">
              <span>OR</span>
            </p>
            <Row>
              <Col xl={4} lg={4}>
                <FormGroup className="material-group">
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="Name" className="label-caption">
                      Name
                    </InputLabel>
                    <OutlinedInput
                      id="Name"
                      type="text"
                      name="name"
                      className="material"
                      inputRef={register({
                        required: false,
                      })}
                    />
                  </FormControl>
                </FormGroup>
              </Col>
              <Col xl={4} lg={4}>
                <FormGroup className="material-group">
                  <FormControl variant="outlined">
                    <InputLabel
                      htmlFor="EmailAddress"
                      className="label-caption"
                    >
                      Email Address
                    </InputLabel>
                    <OutlinedInput
                      id="EmailAddress"
                      type="email"
                      name="email"
                      className="material"
                      inputRef={register({
                        required: false,
                      })}
                    />
                  </FormControl>
                </FormGroup>
              </Col>
              {/* <Col xl={4} lg={4}>
                <FormGroup className="material-group">
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="Password" className="label-caption">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="Password"
                      type={passwordShown ? "text" : "password"}
                      name="password"
                      className="material"
                      inputRef={register({
                        required: false,
                      })}
                    />
                  </FormControl>
                  <span
                    className="fixed_eye_icon"
                    onClick={togglePasswordVisiblity}
                  >
                    <img
                      className="img-fluid"
                      src={
                        passwordShown
                          ? "/images/eye.svg"
                          : "/images/eye-off.svg"
                      }
                      alt=""
                    />
                  </span>
                </FormGroup>
              </Col> */}
            </Row>
            <div className="pb-4 text-right">
              <Button type="submit" variant="contained" color="primary">
                Invite
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {loader === true && <LoaderGif />}
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100 + "%",
    maxWidth: 100 + "%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default AddCollaborators;
