import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from "react-redux";
import { updateEvent } from "../../actions/auth";
import LoaderGif from "../modules/loader";
import DeleteConfirmation from "../popups/delete-confirmation";

const ENV_ADMIN_ID = process.env.REACT_APP_ADMIN_ID;
const ENV_AUTHENTICATED_ID = process.env.REACT_APP_AUTHENTICATED_ID;

const CollaboratorsList = (props) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [deleteConfirmationState, setDeleteConfirmationState] = useState(false);
  const [deleteConfirmationValue, setDeleteConfirmationValue] = useState("");



  // delete
  const deleteCon = (id) => {
    setDeleteConfirmationValue(id);
    setDeleteConfirmationState(true)
  } 

  return(
    <React.Fragment>
    {deleteConfirmationState === true && (
      <DeleteConfirmation 
        setDeleteConfirmationState={setDeleteConfirmationState}
        show={deleteConfirmationState}
        onHide={() => setDeleteConfirmationState(false)}
        delete={() => deleteCon()}
        deleteConfirmationValue={deleteConfirmationValue}
        page="collaborators-page-list"
        collabIds={props.collabIds}
        eventCardId={props.eventCardId}
        eventId={props.eventId}
        deleteMessage="Are you sure you want to remove this collaborator from this event? "
      />
    )} 
    <Modal show={props.show} onHide={props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className= {deleteConfirmationState === true ? "signin-popup ad-collaborators-list delete_popup_open" : "signin-popup ad-collaborators-list"}>
      <Modal.Header className="ad-collaborators-header">
         <h4 className="popup_title">Collaborators</h4>
         {/* <Button variant="contained" color="primary" className="mr-5">Add Collaborators</Button> */}
         <p className="popup_close_icon" onClick={props.onHide}><img className="img-fluid" src="/images/close.svg" alt="" /></p>
      </Modal.Header>
      <Modal.Body className="collaborators_tabel">
       <Table striped size="sm" className="collaborators_tabel_striped">
        <thead>
          <tr>
            <th>No.</th>
            <th>Name</th>
            <th>Email Address</th>
            <th></th>
          </tr>
        </thead>
          <tbody>
            {props.finalCollabList.map((collab, i) => (
          <tr key={i}>
            <td>{i+1}</td>
            <td>{collab.name}</td>
            <td>{collab.email}</td>
            {props.eventType != "master" || (props.session.user.role.id.toString() === ENV_ADMIN_ID && props.session.user.email.toLowerCase() === props.createdUser.toLowerCase())?
              <td><p className="text-right delete_row"><img className="img-fluid" src="/images/delete.svg" alt="" onClick={() => deleteCon(collab.id)} /></p></td>
              :<td></td>
            }
          </tr>
            ))}
        </tbody>
       </Table>
     </Modal.Body>
    </Modal>
      {loader === true && (
        <LoaderGif />
      )}
    </React.Fragment>
  );
}
export default CollaboratorsList;
