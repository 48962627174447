import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Container, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Button from "@material-ui/core/Button";
import BreadCrumb from "../modules/breadcrumb";
import AddShowcase from "../popups/add-showcase";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import { useDispatch } from "react-redux";
import UserService from "../../services/user.service";
import { updateShowcases } from "../../actions/auth";
import LoaderGif from "../modules/loader";
import DeleteConfirmation from "../popups/delete-confirmation";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { statusErrorSuccess } from "../../App";

const Showcases = ({ match }) => {
  const { session } = useSelector((state) => state.auth);
  const [showcaseList, setShowcaseList] = useState(new Map());
  const [showcaseListArray, setShowcaseListArray] = useState();
  const [showcaseListLoaded, setShowcaseListLoaded] = useState(false);
  const [listLength, setListLength] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (!showcaseListLoaded) {
      setLoader(true);
      UserService.getShowcaseList().then(
        (response) => {
          setShowcaseListLoaded(true);
          setLoader(false);
          if (response.status === 200) {
            setShowcaseListArray(response.data);
            setListLength(response.data.length);
            let mapShowcaseData = new Map();
            response.data.map((i) => mapShowcaseData.set(i.id, i));
            setShowcaseList(mapShowcaseData);
          }
        },
        (error) => {}
      );
    }
  });

  if (!session) {
    return <Redirect push to="/" />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Showcases | Infosys Living Labs</title>
        <meta name="description" content="" />
      </Helmet>
      <BreadCrumb
        CurrentPage="Showcases"
        prevPageName="Global Settings"
        prevPageURL="/admin/global-settings"
      />
      <ShowcaseBar session={session} listLength={listLength} />
      {showcaseList && (
        <ShowcasesContent
          session={session}
          showcaseList={showcaseList}
          showcaseListArray={showcaseListArray}
          loader={loader}
          setLoader={setLoader}
        />
      )}
      {loader && <LoaderGif />}
    </React.Fragment>
  );
};

export default Showcases;

const ShowcaseBar = ({ session, listLength }) => {
  const [showCasesPopup, setShowCasesPopup] = useState(false);
  return (
    <React.Fragment>
      {showCasesPopup === true && (
        <AddShowcase
          setshowcasesPopup={setShowCasesPopup}
          show={showCasesPopup}
          onHide={() => setShowCasesPopup(false)}
          showcasesaction={"ADD"}
        />
      )}
      <section className="event_bar_section">
        <Container fluid>
          <div className="events_cover">
            <div className="d-flex align-items-center">
              <div>
                <h4 className="events_title">Showcases</h4>
                <p className="event_bar_url">
                  {listLength === null ? "0" : listLength} Showcase
                </p>
              </div>
            </div>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setShowCasesPopup(true)}
            >
              Add Showcases
            </Button>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

const ShowcasesContent = ({
  session,
  showcaseList,
  showcaseListArray,
  loader,
  setLoader,
}) => {
  const [checked, setChecked] = useState(false);
  const [showCasesPopup, setShowCasesPopup] = useState(false);
  const [showCasesRow, setShowCasesRow] = useState();

  const [deleteConfirmationState, setDeleteConfirmationState] = useState(false);
  const [deleteConfirmationValue, setDeleteConfirmationValue] = useState("");
  const [deleteMediaTagValue, setDeleteMediaTagValue] = useState("");
  const [tagListLoaded, setTagListLoaded] = useState(false);
  const [tagValue, setTagValue] = useState();

  const dispatch = useDispatch();

  const handleChange = (event, id, oldValue) => {
    // setChecked(event.target.checked);
    setLoader(true);
    let data = {};
    data.is_fixed_showcase = !oldValue;
    dispatch(updateShowcases(id, data, "EDIT"))
      .then(() => {
        setLoader(false);
        statusErrorSuccess("editShowcaseSuccess", "success");
        window.location.href = "/admin/showcases";
      })
      .catch(() => {
        statusErrorSuccess("editShowcaseFailed", "danger");
        setLoader(false);
      });
  };

  const editShowCase = (ShowCasesRowData) => {
    setShowCasesPopup(true);
    setShowCasesRow(ShowCasesRowData);
  };

  useEffect(() => {
    if (!tagListLoaded) {
      UserService.getMediaTages().then(
        (response) => {
          setTagListLoaded(true);
          if (response.status === 200) {
            setTagValue(response.data);
          }
        },
        (error) => {}
      );
    }
  });

  // delete
  const deleteCon = (id, delShowCase_id) => {
    setDeleteConfirmationValue(id);
    setDeleteConfirmationState(true);
  };

  const options = {
    sizePerPage: 10,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: true,
    // onSizePerPageChange
  };

  function iconsEdit(content, row, rowIndex, formatExtraData) {
    return (
      <React.Fragment>
        <p className="text-right delete_row">
          <img
            className="img-fluid mr-4"
            src="/images/edit.svg"
            alt=""
            onClick={() => editShowCase(row)}
          />
          <img
            className="img-fluid"
            src="/images/delete.svg"
            alt=""
            onClick={() => deleteCon(row.id, row.showcase_id)}
          />
        </p>
      </React.Fragment>
    );
  }

  function fixedWallpaper(content, row, rowIndex, formatExtraData) {
    return (
      <FormGroup className="material-group m-0 d-inline-block">
        <FormControlLabel
          control={
            <Checkbox
              checked={row.is_fixed_showcase ? true : false}
              onChange={(e) =>
                handleChange(e.target.value, row.id, row.is_fixed_showcase)
              }
              name="checked"
              color="primary"
            />
          }
          label="This area has a fixed showcase."
        />
      </FormGroup>
    );
  }

  const columns = [
    {
      dataField: "showcase_id",
      text: "Showcases ID",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "180px" };
      },
    },
    {
      dataField: "showcase_name",
      text: "Showcase Name",
      headerStyle: (colum, colIndex) => {
        return { width: "30%" };
      },
    },
    {
      dataField: "default_area_id",
      text: "Area ID",
      sort: true,
    },
    {
      dataField: "fixedWallpaper",
      text: "",
      formatter: fixedWallpaper,
      headerStyle: (colum, colIndex) => {
        return { width: "30%" };
      },
    },
    {
      dataField: "iconEdit",
      text: "",
      formatter: iconsEdit,
    },
  ];

  return (
    <React.Fragment>
      {showCasesPopup === true && (
        <AddShowcase
          setshowcasesPopup={setShowCasesPopup}
          show={showCasesPopup}
          onHide={() => setShowCasesPopup(false)}
          showcasesrow={showCasesRow}
          showcasesaction={"EDIT"}
        />
      )}
      {deleteConfirmationState === true && (
        <DeleteConfirmation
          setDeleteConfirmationState={setDeleteConfirmationState}
          show={deleteConfirmationState}
          onHide={() => setDeleteConfirmationState(false)}
          delete={() => deleteCon()}
          deleteConfirmationValue={deleteConfirmationValue}
          deleteMediaTagValue={deleteMediaTagValue}
          page="showcase-page"
          deleteMessage="Are you sure you want to delete this showcase? /n On deleting this showcase connected experience and area will be affected."
        />
      )}
      {showcaseListArray && (
        <section className="media_tag_section">
          <Container fluid>
            <div className="cover_tabel_area media_tag_table">
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={showcaseListArray}
                columns={columns}
                pagination={paginationFactory(options)}
              />
            </div>
          </Container>
        </section>
      )}
    </React.Fragment>
  );
};
