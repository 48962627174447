import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_FAIL,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  CREATE_COLLABRATOR_SUCCESS,
  CREATE_COLLABRATOR_FAIL,
  CREATE_SHOWCASES_SUCCESS,
  CREATE_SHOWCASES_FAIL,
  CREATE_AREA_SUCCESS,
  CREATE_AREA_FAIL,
  CREATE_EDITEVENT_SUCCESS,
  CREATE_EDITEVENT_FAIL,
  CREATE_MEDIATAG_SUCCESS,
  CREATE_MEDIATAG_FAIL,
  UPLOAD_MEDIA_SUCCESS,
  UPLOAD_MEDIA_FAIL,
  DELETE_MEDIA_SUCCESS,
  DELETE_MEDIA_FAIL,
  TEXTTOSPEECH_SUCCESS,
  TEXTTOSPEECH_FAIL,
  SEND_MAIL_SUCCESS,
  SEND_MAIL_FAIL,
  CREATE_EVENT_ACCESS_SUCCESS,
  CREATE_EVENT_ACCESS_FAIL,
  CREATE_EDIT_INDUSTRYZONE_SUCCESS,
  CREATE_EDIT_INDUSTRYZONE_FAIL,
} from "../actions/types";

const session = JSON.parse(localStorage.getItem("session"));
const userCollab = JSON.parse(localStorage.getItem("userCollab"));

const initialState = session
  ? {
      isRegistered: false,
      isLoggedIn: true,
      session,
      userCollab,
      createEventData: null,
      createUserData: null,
      createUserError: null,
      createClbData: null,
      createShowcaseData: null,
      createEditEventData: null,
      createAreaData: null,
      createMediaTagData: null,
      uploadMediaData: null,
      deleteMediaData: null,
      textToSpeechMedia: null,
      sendEmailData: null,
      createEventAccessData: null,
      createEditIndustryZoneData: null,
    }
  : {
      isRegistered: false,
      isLoggedIn: false,
      session: null,
      userCollab: null,
      createEventData: null,
      createUserData: null,
      createUserError: null,
      createClbData: null,
      createShowcaseData: null,
      createEditEventData: null,
      createAreaData: null,
      createMediaTagData: null,
      uploadMediaData: null,
      deleteMediaData: null,
      textToSpeechMedia: null,
      sendEmailData: null,
      createEventAccessData: null,
      createEditIndustryZoneData: null,
    };

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        session: payload.session,
        userCollab: payload.userCollab,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        session: null,
        userCollab: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        session: null,
        userCollab: null,
      };
    case CREATE_EVENT_SUCCESS:
      return {
        ...state,
        createEventData: payload.createEventData,
      };
    case CREATE_EVENT_FAIL:
      return {
        ...state,
        createEventData: payload.createEventData,
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        createUserData: payload.createUserData,
      };
    case CREATE_USER_FAIL:
      return {
        ...state,
        createUserError: payload.createUserError,
      };
    case CREATE_COLLABRATOR_SUCCESS:
      return {
        ...state,
        createClbData: payload.createClbData,
      };
    case CREATE_COLLABRATOR_FAIL:
      return {
        ...state,
        createClbData: payload.createClbData,
      };
    case CREATE_SHOWCASES_SUCCESS:
      return {
        ...state,
        createShowcaseData: payload.createShowcaseData,
      };
    case CREATE_SHOWCASES_FAIL:
      return {
        ...state,
        createShowcaseData: payload.createShowcaseData,
      };
    case CREATE_EDITEVENT_SUCCESS:
      return {
        ...state,
        createEditEventData: payload.createEditEventData,
      };
    case CREATE_EDITEVENT_FAIL:
      return {
        ...state,
        createEditEventData: payload.createEditEventData,
      };
    case CREATE_AREA_SUCCESS:
      return {
        ...state,
        createAreaData: payload.createAreaData,
      };
    case CREATE_AREA_FAIL:
      return {
        ...state,
        createAreaData: payload.createAreaData,
      };
    case CREATE_MEDIATAG_SUCCESS:
      return {
        ...state,
        createMediaTagData: payload.createMediaTagData,
      };
    case CREATE_MEDIATAG_FAIL:
      return {
        ...state,
        createMediaTagData: payload.createMediaTagData,
      };
    case UPLOAD_MEDIA_SUCCESS:
      return {
        ...state,
        uploadMediaData: payload.uploadMediaData,
      };
    case UPLOAD_MEDIA_FAIL:
      return {
        ...state,
        uploadMediaData: payload.uploadMediaData,
      };
    case DELETE_MEDIA_SUCCESS:
      return {
        ...state,
        deleteMediaData: payload.deleteMediaData,
      };
    case DELETE_MEDIA_FAIL:
      return {
        ...state,
        deleteMediaData: payload.deleteMediaData,
      };
    case TEXTTOSPEECH_SUCCESS:
      return {
        ...state,
        textToSpeechMedia: payload.textToSpeechMedia,
      };
    case TEXTTOSPEECH_FAIL:
      return {
        ...state,
        textToSpeechMedia: payload.textToSpeechMedia,
      };
    case SEND_MAIL_SUCCESS:
      return {
        ...state,
        sendEmailData: payload.sendEmailData,
      };
    case SEND_MAIL_FAIL:
      return {
        ...state,
        sendEmailData: payload.sendEmailData,
      };
    case CREATE_EVENT_ACCESS_SUCCESS:
      return {
        ...state,
        createEventAccessData: payload.createEventAccessData,
      };
    case CREATE_EVENT_ACCESS_FAIL:
      return {
        ...state,
        createEventAccessData: payload.createEventAccessData,
      };
    case CREATE_EDIT_INDUSTRYZONE_SUCCESS:
      return {
        ...state,
        createEditIndustryZoneData: payload.createEditIndustryZoneData,
      };
    case CREATE_EDIT_INDUSTRYZONE_FAIL:
      return {
        ...state,
        createEditIndustryZoneData: payload.createEditIndustryZoneData,
      };

    default:
      return state;
  }
}
