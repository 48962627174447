import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import copy from "clipboard-copy";
const ENV_MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
const ENV_ADMIN_ID = process.env.REACT_APP_ADMIN_ID;
const ENV_EVENT_URL = process.env.REACT_APP_EVENT_URL;

const EventCard = ({ eventCard, eventCardType, extra, session }) => {
  function shortName(name) {
    var matches = name.match(/\b(\w)/g);
    return matches.join("");
  }
  const [copied, setCopied] = useState(-1);

  const copiedText = (index, value) => {
    setCopied(index);
    setTimeout(
      function () {
        setCopied(-1);
      }.bind(this),
      1000
    );
  };

  return (
    <React.Fragment>
      {eventCard.size !== 0 ? (
        <React.Fragment>
          {Array.from(eventCard).map(([index, eventList], i) => (
            <li
              key={"event_list_" + eventList.access_id}
              className={
                eventCardType === "others"
                  ? "card_li_" + ((extra.size + i) % 8)
                  : "card_li_" + (i % 8)
              }
            >
              <Card>
                <div className="p-relative card_img">
                  {eventList.base_configuration.length > 0 &&
                  eventList.base_configuration[0].event_configuration
                    .event_details_configuration &&
                  eventList.base_configuration[0].event_configuration
                    .event_details_configuration.event_image &&
                  eventList.base_configuration[0].event_configuration
                    .event_details_configuration.event_image.formats ? (
                    <Card.Img
                      variant="top"
                      className="card_img"
                      src={
                        ENV_MEDIA_URL +
                        eventList.base_configuration[0].event_configuration
                          .event_details_configuration.event_image.formats
                          .thumbnail.url
                      }
                    />
                  ) : (
                    <div className="bg_cover">
                      <h4 className="short_name">
                        {shortName(eventList.event_name)}
                      </h4>
                      <img
                        className="img-fluid fixed_circle"
                        src="/images/card_circle.png"
                        alt=""
                      />
                    </div>
                  )}
                  <div className="view_event view_event_colunm">
                    <Link
                      to={
                        eventCardType === "master" &&
                        session.user.role.id.toString() === ENV_ADMIN_ID
                          ? "/admin/view-master-event/" +
                            eventCardType +
                            "/" +
                            eventList.access_id
                          : "/admin/view-event/" +
                            eventCardType +
                            "/" +
                            eventList.access_id
                      }
                      className="MuiButtonBase-root MuiButton-root MuiButton-outlined mb-3"
                    >
                      <span className="MuiButton-label">View Event</span>
                      <span className="MuiTouchRipple-root"></span>
                    </Link>
                    <Link
                      to={
                        "/admin/new-event/" +
                        eventCardType +
                        "/" +
                        eventList.access_id
                      }
                      className="MuiButtonBase-root MuiButton-root MuiButton-outlined mb-3"
                    >
                      <span className="MuiButton-label">Duplicate</span>
                      <span className="MuiTouchRipple-root"></span>
                    </Link>
                  </div>
                </div>
                <Card.Body>
                  <div className="p-relative">
                    <Card.Title>{eventList.event_name}</Card.Title>
                    {eventCardType === "master" &&
                    eventList.event_status === null ? (
                      <React.Fragment>
                        <span
                          className="status_dot published"
                          data-tip="Published"
                        ></span>
                        <ReactTooltip place="top" type="success" />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {
                          {
                            Draft: (
                              <React.Fragment>
                                <span
                                  className="status_dot draft"
                                  data-tip=""
                                  data-for="Draft"
                                ></span>
                                <ReactTooltip
                                  place="top"
                                  type="warning"
                                  id="Draft"
                                >
                                  {"Draft"}
                                </ReactTooltip>
                              </React.Fragment>
                            ),
                            Archived: (
                              <React.Fragment>
                                <span
                                  className="status_dot archived"
                                  data-tip=""
                                  data-for="Archived"
                                ></span>
                                <ReactTooltip
                                  place="top"
                                  type="error"
                                  id="Archived"
                                >
                                  {"Archived"}
                                </ReactTooltip>
                              </React.Fragment>
                            ),
                            Published: (
                              <React.Fragment>
                                <span
                                  className="status_dot published"
                                  data-tip=""
                                  data-for="Published"
                                ></span>
                                <ReactTooltip
                                  place="top"
                                  type="success"
                                  id="Published"
                                >
                                  {"Published"}
                                </ReactTooltip>
                              </React.Fragment>
                            ),
                          }[eventList.event_status]
                        }
                      </React.Fragment>
                    )}
                  </div>
                  {eventCardType !== "master" &&
                    eventList.access_id &&
                    eventList.base_configuration.length > 0 &&
                    eventList.base_configuration[0].language && (
                      <p className="url_text d-flex">
                        <span className="event_url_base">
                          {ENV_EVENT_URL +
                            eventList.access_id +
                            "&lang=" +
                            eventList.base_configuration[0].language}
                        </span>
                        <span
                          className="ml-3 copy_icon"
                          onClick={() =>
                            copiedText(
                              index,
                              `${
                                ENV_EVENT_URL +
                                eventList.access_id +
                                "&lang=" +
                                eventList.base_configuration[0].language
                              }`
                            )
                          }
                        >
                          <img
                            className="img-fluid"
                            src="/images/copy.svg"
                            alt=""
                            onClick={() =>
                              copy(
                                `${
                                  ENV_EVENT_URL +
                                  eventList.access_id +
                                  "&lang=" +
                                  eventList.base_configuration[0].language
                                }`
                              )
                            }
                          />
                          {copied === index && (
                            <span className="copied_text_span">Copied</span>
                          )}
                        </span>
                      </p>
                    )}
                  {eventCardType === "others" && (
                    <p className="date_text">
                      <span>Start Date:</span>
                      {eventList.base_configuration.length > 0 &&
                        eventList.base_configuration[0].event_configuration
                          .event_details_configuration && (
                          <span className="ml-1">
                            {moment
                              .tz(
                                eventList.base_configuration[0]
                                  .event_configuration
                                  .event_details_configuration.start_date,
                                eventList.base_configuration[0]
                                  .event_configuration
                                  .event_details_configuration.timezone
                                  ? eventList.base_configuration[0]
                                      .event_configuration
                                      .event_details_configuration.timezone
                                  : moment.tz.guess()
                              )
                              .format("D MMM, yyyy hh:ss a z")}
                          </span>
                        )}
                    </p>
                  )}
                </Card.Body>
              </Card>
            </li>
          ))}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};
export default EventCard;
