import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Form, Table } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { Helmet } from "react-helmet";
import { Redirect, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import BreadCrumb from "../modules/breadcrumb";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import UserService from "../../services/user.service";
import AddCollaborators from "../popups/ad-collaborators";
import CollaboratorsList from "../popups/collaborators-list";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { editEvent, updateEvent } from "../../actions/auth";
import LoaderGif from "../modules/loader";
import makeAnimated from "react-select/animated";
import { statusErrorSuccess } from "../../App";
import AddShowcase from "../popups/add-showcase";
import ReadMoreAndLess from "react-read-more-less";
import axios from "axios";
import authHeader from "../../services/auth-header";
const API_URL = process.env.REACT_APP_BACKEND_URL;

const ENV_MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
const ENV_EVENT_URL = process.env.REACT_APP_EVENT_URL;
const ENV_ADMIN_ID = process.env.REACT_APP_ADMIN_ID;

const ViewEvent = ({ match }) => {
  const {
    params: { eventType, eventId },
  } = match;
  const { session, userCollab } = useSelector((state) => state.auth);
  const [eventCard, setEventCard] = useState(new Map());
  const [eventCardLoaded, setEventCardLoaded] = useState(false);
  const [finalCollabList, setFinalCollabList] = useState([]);
  const [collabIds, setCollabIds] = useState();
  const [showcaseList, setShowcaseList] = useState();
  const [showcasesLoaded, setShowcasesLoaded] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (!eventCardLoaded) {
      setLoader(true);
      UserService.getExperiencesList(eventId, eventType).then(
        (response) => {
          if (response.status === 200) {
            let event = {};
            if (response.data.length > 0) {
              event = response.data[0];
              setEventCard(event);
              setEventCardLoaded(true);

              UserService.getCollaboratorsList().then(
                (response1) => {
                  setLoader(false);
                  if (response1.status === 200) {
                    if (eventType === "others" && event.collaborator_ids) {
                      let collabList = event.collaborator_ids.split(",");
                      let finalList = response1.data.filter((i) =>
                        collabList.includes(i.id.toString())
                      );
                      setFinalCollabList(finalList);
                      setCollabIds(event.collaborator_ids);
                    }
                  }
                },
                (error) => {}
              );
            }
          }
        },
        (error) => {}
      );
    }

    if (!showcasesLoaded) {
      setShowcasesLoaded(true);
      UserService.getShowcaseList().then(
        (response) => {
          if (response.status === 200) {
            setShowcaseList(response.data);
          }
        },
        (error) => {}
      );
    }
  });

  if (!session) {
    return <Redirect push to="/" />;
  }

  return (
    <React.Fragment>
      {eventCard.access_id && (
        <React.Fragment>
          <Helmet>
            <title>{eventCard.event_name + "  | Infosys Living Labs"}</title>
            <meta name="description" content="" />
          </Helmet>
          <BreadCrumb
            CurrentPage={eventCard.event_name}
            prevPageName=""
            prevPageURL=""
          />
          <ViewEventBar
            session={session}
            eventCard={eventCard}
            finalCollabList={finalCollabList}
            collabIds={collabIds}
            eventType={eventType}
            loader={loader}
            setLoader={setLoader}
          />
          <ViewEventList
            eventId={eventId}
            eventCard={eventCard}
            showcaseList={showcaseList}
            eventType={eventType}
            loader={loader}
            setLoader={setLoader}
            session={session}
          />
        </React.Fragment>
      )}
      {loader && <LoaderGif />}
    </React.Fragment>
  );
};

export default ViewEvent;

const ViewEventBar = ({
  session,
  eventCard,
  finalCollabList,
  collabIds,
  eventType,
  loader,
  setLoader,
}) => {
  const [adCollaborators, setADCollaborators] = useState(false);
  const [collaboratorsListPopup, setCollaboratorsListPopup] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function shortName(name) {
    var matches = name.match(/\b(\w)/g);
    return matches.join("");
  }

  const dispatch = useDispatch();
  const updateEventStatus = (id, data, status) => {
    setLoader(true);
    dispatch(updateEvent(id, data))
      .then(() => {
        setLoader(false);
        statusErrorSuccess("event" + status + "Success", "success");
        window.location.href =
          "/admin/view-event/" + eventType + "/" + eventCard.access_id;
      })
      .catch((error) => {
        statusErrorSuccess("event" + status + "Failed", "danger");
      });
  };

  const getEventUrl = (event_url) => {
    const params = new URLSearchParams(event_url);
    const isEventTypePresent = params.get("event_type");
    if (isEventTypePresent == null) {
      const event_type = eventCard.base_configuration[0].event_configuration.intro_configuration.intro_type;
      if(event_type == "Avatar"){
        return event_url + "&event_type=avatar";
      } else if(event_type == "Live"){
        return event_url + "&event_type=host-led";
      }else if(event_type == "Video"){
        return event_url + "&event_type=video";
      }
    } else {
      return event_url;
    }
  }

  const eventDelete = async (eventCard) => {
    handleClose();
    setLoader(true);
    let header = authHeader();
    header["Content-Type"] = "application/json";
    try{
    const res = await axios.delete(API_URL + "/events/" + eventCard.id,{ headers: header });
    
      if (res.status === 200) {
        setLoader(false);
        statusErrorSuccess("Event Deleted Successfully", "success","message");
        window.location.href = "/admin";
      } else {
        setLoader(false);
        statusErrorSuccess("Event Deletion Failed", "danger","message");
      } 
    }catch(error){
        setLoader(false);
        statusErrorSuccess("Event Deletion Failed", "danger","message");
      }
  }
  const eventArchive = (id) => {
    let eventDataStatus = {};
    eventDataStatus.event_status = "Archived";
    updateEventStatus(id, eventDataStatus, "Archived");
  };
  const eventDraft = (id) => {
    let eventDataStatus = {};
    eventDataStatus.event_status = "Draft";
    updateEventStatus(id, eventDataStatus, "Draft");
  };
  const eventPublish = (id) => {
    let eventDataStatus = {};
    eventDataStatus.event_status = "Published";
    updateEventStatus(id, eventDataStatus, "Published");
  };
  const eventUnpublish = (id) => {
    let eventDataStatus = {};
    eventDataStatus.event_status = "Draft";
    updateEventStatus(id, eventDataStatus, "Unpublished");
  };

  return (
    <React.Fragment>
      {adCollaborators === true && (
        <AddCollaborators
          eventCard={eventCard}
          setADCollaborators={setADCollaborators}
          show={adCollaborators}
          onHide={() => setADCollaborators(false)}
          finalCollabList={finalCollabList}
          eventType={eventType}
        />
      )}
      {collaboratorsListPopup === true && (
        <CollaboratorsList
          setCollaboratorsListPopup={setCollaboratorsListPopup}
          show={collaboratorsListPopup}
          onHide={() => setCollaboratorsListPopup(false)}
          finalCollabList={finalCollabList}
          collabIds={collabIds}
          eventCardId={eventCard.id}
          eventId={eventCard.access_id}
        />
      )}
      <section className="event_bar_section">
        <Container fluid>
          <div className="events_cover">
            <div>
              <h4 className="events_title d-flex align-items-center">
                <span>{eventCard.event_name}</span>
              </h4>
              {eventCard.base_configuration &&
                eventCard.base_configuration.length > 0 &&
                eventCard.base_configuration[0].event_configuration && (
                  <p className="event_bar_url">
                    {eventCard.event_category ? (
                      <span className="category_span mr-2">
                        {"Category: " + eventCard.event_category}
                      </span>
                    ) : (
                      ""
                    )}
                    {eventCard.access_id &&
                      eventCard.base_configuration.length > 0 &&
                      eventCard.base_configuration[0].language && (
                        <span>
                           {ENV_EVENT_URL + getEventUrl(eventCard.base_configuration[0].event_configuration.event_details_configuration.event_url)
                            }
                        </span>
                      )}
                  </p>
                )}
            </div>
            <div className="view_event_right_bar">
              <div className="avtar_chip mr-3">
                <AvatarGroup
                  max={4}
                  onClick={() => setCollaboratorsListPopup(true)}
                >
                  {finalCollabList.map((collab, i) => (
                    <Avatar
                      key={"avatar_colab_" + i}
                      className={"avtar_bg_" + (i + 1)}
                      title={collab.name}
                    >
                      {shortName(collab.name)}
                    </Avatar>
                  ))}
                </AvatarGroup>
              </div>
              {eventType === "others" && (
                <React.Fragment>
                  <Button
                    variant="outlined"
                    color="primary"
                    className="mr-2"
                    onClick={() => setADCollaborators(true)}
                  >
                    Add Collaborators
                  </Button>
                  {eventCard.event_status === "Published" && (
                    <React.Fragment>
                      <Button
                        variant="contained"
                        color="primary"
                        className="mr-2"
                        onClick={() => eventUnpublish(eventCard.id)}
                      >
                        Unpublish
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => eventArchive(eventCard.id)}
                      >
                        Archive
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleShow()}
                      >
                        Delete
                      </Button>
                    </React.Fragment>
                  )}
                  {eventCard.event_status === "Draft" && (
                    <React.Fragment>
                      <Button
                        variant="contained"
                        color="primary"
                        className="mr-2"
                        onClick={() => eventPublish(eventCard.id)}
                      >
                        Publish
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => eventArchive(eventCard.id)}
                      >
                        Archive
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleShow()}
                      >
                        Delete
                      </Button>
                    </React.Fragment>
                  )}
                  {eventCard.event_status === "Archived" && (
                    <React.Fragment>
                      <Button
                        variant="contained"
                        color="primary"
                        className="mr-2"
                        onClick={() => eventPublish(eventCard.id)}
                      >
                        Publish
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        className="warning_btn"
                        onClick={() => eventDraft(eventCard.id)}
                      >
                        Draft
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleShow()}
                      >
                        Delete
                      </Button>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </Container>
      </section>
      {loader === true && <LoaderGif />}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to delete this event.</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>eventDelete(eventCard)}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

const ViewEventList = ({
  eventId,
  eventCard,
  showcaseList,
  eventType,
  loader,
  setLoader,
  session,
}) => {
  const [checked, setChecked] = useState(false);
  const [filteredBy, setFilteredBy] = React.useState(0);
  const dispatch = useDispatch();
  const [filteredAreaList, setFilteredAreaList] = useState(
    eventCard.base_configuration[0].event_configuration.area_configuration
  );

  const handleChangeFilterBy = (event) => {
    setFilteredBy(event.target.value);
    let temp = [];
    eventCard.base_configuration[0].event_configuration.area_configuration.map(
      (area, i) => {
        if (
          event.target.value === 0 ||
          (event.target.value === 10 &&
            state["area_activated_" + area.area_id] === true) ||
          (event.target.value === 20 &&
            state["area_activated_" + area.area_id] === false)
        ) {
          temp.push(area);
        }
      }
    );
    setFilteredAreaList(temp);
  };

  const animatedComponents = makeAnimated();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  // Enable Toggle
  const [state, setState] = useState();
  const [stateLoaded, setStateLoaded] = useState(false);

  const [areaImage, setareaImage] = useState({});
  const [areaImageLoaded, setAreaImageLoaded] = useState(false);

  if (!stateLoaded && showcaseList) {
    setStateLoaded(true);
    let data = {};
    eventCard.base_configuration[0].event_configuration.area_configuration.map(
      (area, i) => {
        data["area_activated_" + area.area_id] = area.activated_for_event;
        data["showcaseData_" + area.showcase_id] = showcaseList.filter(
          (i) => i.showcase_id === area.showcase_id
        );
      }
    );
    setState(data);
  }
  const handleChangeAreaActivated = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });

    let area_id = event.target.name.replace("area_activated_", "");
    let finalData = eventCard;
    eventCard.base_configuration[0].event_configuration.area_configuration.map(
      (area, i) => {
        if (area.area_id.toString() === area_id) {
          let temp = area;
          temp.activated_for_event = event.target.checked;
          finalData.base_configuration[0].event_configuration.area_configuration[
            i
          ] = temp;
        }
      }
    );

    setLoader(true);
    dispatch(editEvent(finalData, eventType, finalData.id))
      .then(() => {
        setLoader(false);
        statusErrorSuccess("experienceUpdatedSuccess", "success");
      })
      .catch((error) => {
        setLoader(false);
        statusErrorSuccess("experienceUpdatedFailed", "danger");
      });
  };

  const [showCasesPopup, setShowCasesPopup] = useState(false);
  const [eventIdName, setEventIdName] = useState();

  const handleShowcaseChange = (event) => {
    if (event.target.value === "custom") {
      setShowCasesPopup(true);
      setEventIdName(event.target.name);
    } else {
      let area_id = event.target.name.replace("showcaseData_", "");
      let finalData = eventCard;
      eventCard.base_configuration[0].event_configuration.area_configuration.map(
        (area, i) => {
          if (area.id.toString() === area_id) {
            let temp = area;
            temp.showcase_id = event.target.value;
            finalData.base_configuration[0].event_configuration.area_configuration[
              i
            ] = temp;
          }
        }
      );
      setLoader(true);
      dispatch(editEvent(finalData, eventType, finalData.id))
        .then(() => {
          setLoader(false);
          statusErrorSuccess("experienceUpdatedSuccess", "success");
          window.location.href =
            "/admin/view-event/" + eventType + "/" + eventId;
        })
        .catch((error) => {
          setLoader(false);
          statusErrorSuccess("experienceUpdatedFailed", "danger");
          window.location.href =
            "/admin/view-event/" + eventType + "/" + eventId;
        });
    }
  };

  useEffect(() => {
    if (!areaImageLoaded) {
      UserService.getAreaList().then(
        (response) => {
          if (response.status === 200) {
            let finalData = {};
            response.data.map((area, i) => {
              finalData["a" + area.area_id] = {};
              if (area.area_snapshot) {
                finalData["a" + area.area_id].image = area.area_snapshot.formats
                  ? area.area_snapshot.formats.thumbnail.url
                  : area.area_snapshot.url;
              } else {
                finalData["a" + area.area_id].image = "";
              }
              if (area.area_description) {
                finalData["a" + area.area_id].description =
                  area.area_description;
              } else {
                finalData["a" + area.area_id].description = "";
              }
            });
            setareaImage(finalData);
            setAreaImageLoaded(true);
          }
        },
        (error) => {}
      );
    }
  });

  const callBackShowCase = (showcaseData) => {
    let area_id = eventIdName.replace("showcaseData_", "");
    let finalData = eventCard;
    eventCard.base_configuration[0].event_configuration.area_configuration.map(
      (area, i) => {
        if (area.id.toString() === area_id) {
          let temp = area;
          temp.showcase_id = showcaseData;
          finalData.base_configuration[0].event_configuration.area_configuration[
            i
          ] = temp;
        }
      }
    );
    setLoader(true);
    dispatch(editEvent(finalData, eventType, finalData.id))
      .then(() => {
        setLoader(false);
        statusErrorSuccess("experienceUpdatedSuccess", "success");
        window.location.href = "/admin/view-event/" + eventType + "/" + eventId;
      })
      .catch(() => {
        setLoader(false);
        statusErrorSuccess("experienceUpdatedFailed", "danger");
        window.location.href = "/admin/view-event/" + eventType + "/" + eventId;
      });
  };

  return (
    <React.Fragment>
      {showCasesPopup === true && (
        <AddShowcase
          show={showCasesPopup}
          onHide={() => setShowCasesPopup(false)}
          showCasesAction={"ADD"}
          addCustome={true}
          callBackShowCase={callBackShowCase}
        />
      )}
      <section className="event_setting_section">
        <Container fluid>
          <div className="List_experiences_head">
            <div className="d-flex align-items-center">
              <h4 className="list_exp_title">List of Experiences</h4>
              <Link
                className="ml-4 link_text"
                to={"/admin/event-setting/" + eventType + "/" + eventCard.id}
              >
                <span className="mr-2 mb-1">
                  <img
                    className="img-fluid"
                    src="/images/settings_selected.svg"
                    alt=""
                  />
                </span>
                <span>Event Settings</span>
              </Link>
              <Link
                className="ml-4 link_text"
                to={"/admin/event-access/" + eventType + "/" + eventCard.id}
              >
                <span className="mr-2 mb-1">
                  <img
                    className="img-fluid"
                    src="/images/event_access.svg"
                    alt=""
                  />
                </span>
                <span>Event Access</span>
              </Link>
            </div>
            <div className="d-flex align-items-center">
              <FormControl className="filter_by_control">
                <span className="filter_text_caption">Filtered by:</span>
                <Select
                  value={filteredBy}
                  onChange={handleChangeFilterBy}
                  displayEmpty
                  inputProps={{ "aria-label": "Filtered by" }}
                >
                  <MenuItem value={0}>All</MenuItem>
                  <MenuItem value={10}>Enabled Areas</MenuItem>
                  <MenuItem value={20}>Disabled Area</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="cover_tabel">
            <Table responsive="xl" responsive="lg" className="layout_tabel">
              <thead>
                <tr>
                  <th className="row-1 row_name">
                    <h2 className="table_title">Area Snapshot</h2>
                  </th>
                  <th className="row-2 row_id">
                    <h2 className="table_title">Area ID</h2>
                  </th>
                  <th className="row-3 row_showcases">
                    <h2 className="table_title">Showcases</h2>
                  </th>
                  <th className="row-4 row_placard">
                    <h2 className="table_title">Name on Placard</h2>
                  </th>
                  <th className="row-5 row_description">
                    <h2 className="table_title">Area Description</h2>
                  </th>
                  <th className="row-6 row_order">
                    <h2 className="table_title">Display Order</h2>
                  </th>
                  <th className="row-7 row_enabled">
                    <h2 className="table_title">Area Enabled</h2>
                  </th>
                  <th className="row-8 row_empty"></th>
                </tr>
              </thead>
              <tbody>
                {eventCard.base_configuration &&
                filteredAreaList.length !== 0 &&
                state !== undefined ? (
                  <React.Fragment>
                    {filteredAreaList.map((exp_list, i) => (
                      <React.Fragment key={"exp_list_" + exp_list.area_id}>
                        <tr className="spacer">
                          <td>
                            {areaImageLoaded &&
                            areaImage["a" + exp_list.area_id] &&
                            areaImage["a" + exp_list.area_id].image != "" ? (
                              <img
                                className="img-fluid table_snapshot"
                                src={
                                  ENV_MEDIA_URL +
                                  areaImage["a" + exp_list.area_id].image
                                }
                                alt=""
                              />
                            ) : (
                              <img
                                className="img-fluid table_snapshot"
                                src="/images/default_area_snapshot.png"
                                alt=""
                              />
                            )}
                          </td>
                          <td>
                            <p className="tabel_caption">{exp_list.area_id}</p>
                          </td>
                          <td className="showcase_dropdown_width">
                            {state["showcaseData_" + exp_list.showcase_id]
                              .length > 0 &&
                              state["showcaseData_" + exp_list.showcase_id][0]
                                .is_fixed_showcase && (
                                <React.Fragment>
                                  <h4 className="tabel_showcase_title">
                                    {state[
                                      "showcaseData_" + exp_list.showcase_id
                                    ]
                                      ? state[
                                          "showcaseData_" + exp_list.showcase_id
                                        ][0].showcase_name
                                      : ""}
                                  </h4>
                                  <p className="tabel_showcase_caption">
                                    {state[
                                      "showcaseData_" + exp_list.showcase_id
                                    ] &&
                                    state[
                                      "showcaseData_" + exp_list.showcase_id
                                    ][0].is_fixed_showcase
                                      ? "This area has a fixed showcase."
                                      : ""}
                                  </p>
                                </React.Fragment>
                              )}
                            {state["showcaseData_" + exp_list.showcase_id]
                              .length > 0 &&
                              !state["showcaseData_" + exp_list.showcase_id][0]
                                .is_fixed_showcase && (
                                <React.Fragment>
                                  <FormControl variant="outlined">
                                    <Select
                                      components={animatedComponents}
                                      onChange={handleShowcaseChange}
                                      defaultValue={exp_list.showcase_id}
                                      name={"showcaseData_" + exp_list.id}
                                      placeholder="Showcase"
                                      classnameprefix="react-select"
                                      disabled={
                                        eventType === "master" ? true : false
                                      }
                                    >
                                      {showcaseList
                                        .filter((i) => !i.is_fixed_showcase)
                                        .map((showcase_list, i) => (
                                          <MenuItem
                                            key={i + "showcase_list"}
                                            value={showcase_list.showcase_id}
                                          >
                                            {showcase_list.showcase_name}
                                          </MenuItem>
                                        ))}
                                      {session.user.role.id.toString() ===
                                        ENV_ADMIN_ID && (
                                        <MenuItem value="custom">
                                          Custom
                                        </MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </React.Fragment>
                              )}
                          </td>
                          <td className="name_on_place">
                            <p className="tabel_caption">
                              {exp_list.ui_configuration
                                ? exp_list.ui_configuration.placard_name
                                : ""}
                            </p>
                          </td>
                          <td
                            className={
                              !areaImage["a" + exp_list.area_id]
                                ? ""
                                : areaImage["a" + exp_list.area_id].description
                                    .length >= 100
                                ? "if_content_is_more"
                                : ""
                            }
                          >
                            <div className="tabel_caption">
                              <ReadMoreAndLess
                                className="read-more-content"
                                charLimit={100}
                                readMoreText="Read more"
                                readLessText="Read less"
                              >
                                {areaImage["a" + exp_list.area_id]
                                  ? areaImage["a" + exp_list.area_id]
                                      .description
                                  : ""}
                              </ReadMoreAndLess>
                            </div>
                          </td>
                          <td>
                            <p className="tabel_caption">
                              {exp_list.ui_configuration
                                ? exp_list.ui_configuration.display_order
                                : ""}
                            </p>
                          </td>
                          <td>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={
                                    state["area_activated_" + exp_list.area_id]
                                      ? true
                                      : false
                                  }
                                  onChange={handleChangeAreaActivated}
                                  name={"area_activated_" + exp_list.area_id}
                                  color="primary"
                                  disabled={
                                    eventType === "master" ? true : false
                                  }
                                />
                              }
                              label={
                                state["area_activated_" + exp_list.area_id]
                                  ? "Enabled"
                                  : "Disabled"
                              }
                            />
                          </td>
                          <td>
                            <p className="edit_delete_tag">
                              <Link
                                to={
                                  "/admin/edit-experience/" +
                                  eventType +
                                  "/" +
                                  eventId +
                                  "/" +
                                  exp_list.area_id
                                }
                              >
                                <img
                                  className="img-fluid edit_icon"
                                  src="/images/edit.svg"
                                  alt=""
                                />
                              </Link>
                            </p>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <tr>
                      <td colSpan="9">
                        <p className="tabel_caption text-center">
                          No Experiences.
                        </p>
                      </td>
                    </tr>
                  </React.Fragment>
                )}
              </tbody>
            </Table>
          </div>
        </Container>
      </section>
      {loader === true && <LoaderGif />}
    </React.Fragment>
  );
};
