import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Redirect, Link } from "react-router-dom";
import { Container, Form, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import BreadCrumb from "../modules/breadcrumb";
import UserService from "../../services/user.service";
import Button from "@material-ui/core/Button";
import LoaderGif from "../modules/loader";

const ENV_AUTHENTICATED_ID = process.env.REACT_APP_AUTHENTICATED_ID;

const IndustryZone = ({ match }) => {
  const { session } = useSelector((state) => state.auth);
  const [industryZoneList, setIndustryZoneList] = useState(new Map());
  const [industryZoneListArray, setIndustryZoneListArray] = useState();
  const [industryZoneListLoaded, setIndustryZoneListLoaded] = useState(false);
  const [listLength, setListLength] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (!industryZoneListLoaded) {
      setLoader(true);
      UserService.getIndustryZoneList().then(
        (response) => {
          setIndustryZoneListLoaded(true);
          setLoader(false);
          if (response.status === 200) {
            setIndustryZoneListArray(response.data[0]);
            setListLength(response.data.length);
            let mapIndustryZoneData = new Map();
            response.data.map((i) => mapIndustryZoneData.set(i.id, i));
            setIndustryZoneList(mapIndustryZoneData);
          }
        },
        (error) => {}
      );
    }
  });

  if (!session) {
    return <Redirect push to="/" />;
  } else if (session.user.role.id.toString() === ENV_AUTHENTICATED_ID) {
    window.location.href = "/admin";
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Industry Experience Zone | Infosys Living Labs</title>
        <meta name="description" content="" />
      </Helmet>
      <BreadCrumb
        CurrentPage="Industry Experience Zone"
        prevPageName=""
        prevPageURL=""
      />
      {industryZoneListLoaded && (
        <EditIndustryZoneMain
          industryZoneList={industryZoneList}
          industryZoneListArray={industryZoneListArray}
          listLength={listLength}
        />
      )}
      {loader && <LoaderGif />}
    </React.Fragment>
  );
};

export default IndustryZone;

const EditIndustryZoneMain = ({
  industryZoneList,
  industryZoneListArray,
  listLength,
}) => {
  const { register, handleSubmit, errors, setValue } = useForm({
    defaultValues: industryZoneListArray,
  });

  function onSubmitCreateIndustryZone(data) {}

  return (
    <Form onSubmit={handleSubmit(onSubmitCreateIndustryZone)}>
      <IndustryZoneBar
        industryZoneList={industryZoneList}
        industryZoneListArray={industryZoneListArray}
        listLength={listLength}
        register={register}
      />
      <IndustryZoneContent
        industryZoneList={industryZoneList}
        industryZoneListArray={industryZoneListArray}
        listLength={listLength}
        setValue={setValue}
        register={register}
        errors={errors}
      />
    </Form>
  );
};

const IndustryZoneBar = ({
  industryZoneList,
  industryZoneListArray,
  listLength,
}) => {
  return (
    <section className="event_bar_section for_industry_zone_">
      <Container fluid>
        <div className="events_cover">
          <div className="d-flex align-items-center">
            <div>
              <h4 className="events_title mb-1">Industry Experience Zone</h4>
              <p className="event_bar_url">
                {listLength === null ? "0" : listLength} entries found
              </p>
            </div>
          </div>
          <div className="view_event_right_bar">
            <Button variant="contained" color="primary">
              <Link to="/admin/industry-zone-add-edit/add/0">
                Add New Industry Zone
              </Link>
            </Button>
          </div>
        </div>
      </Container>
    </section>
  );
};

const IndustryZoneContent = ({
  industryZoneList,
  industryZoneListArray,
  listLength,
  setValue,
  register,
  errors,
}) => {
  return (
    <section className="for_industry_zone">
      {industryZoneListArray && (
        <Container fluid>
          <ul className="card_ul for_event_page">
            {Array.from(industryZoneList).map(([index, izList], i) => (
              <li
                key={"iz_list_" + izList.id}
                className={"card_li_" + ((industryZoneList.size + i) % 8)}
              >
                <Card>
                  <div className="p-relative card_img">
                    <div className="bg_cover">
                      <img
                        className="img-fluid fixed_circle"
                        src="/images/card_circle.png"
                        alt=""
                      />
                    </div>
                    <div className="view_event view_event_colunm">
                      <Link
                        to={"/admin/industry-zone-add-edit/edit/" + izList.id}
                        className="MuiButtonBase-root MuiButton-root MuiButton-outlined mb-3"
                      >
                        <span className="MuiButton-label">View / Edit</span>
                        <span className="MuiTouchRipple-root"></span>
                      </Link>
                    </div>
                  </div>
                  <Card.Body>
                    <div className="p-relative">
                      <Card.Title>{izList.placard_name}</Card.Title>
                    </div>
                  </Card.Body>
                </Card>
              </li>
            ))}
          </ul>
        </Container>
      )}
    </section>
  );
};
