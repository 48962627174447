import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import EventBar from "../modules/event-bar";
import EventCard from "../modules/event-card";
import UserService from "../../services/user.service";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import LoaderGif from "../modules/loader";
import ReactHtmlParser from "react-html-parser";

const ENV_ADMIN_ID = process.env.REACT_APP_ADMIN_ID;
const ENV_AUTHENTICATED_ID = process.env.REACT_APP_AUTHENTICATED_ID;
const ENV_NO_COLLABORATOR = process.env.REACT_APP_NO_COLLABORATOR;

const Dashboard = ({ match }) => {
  const { session, userCollab } = useSelector((state) => state.auth);
  const [eventCardLoaded, setEventCardLoaded] = useState(false);
  const [masterEventCard, setMasterEventCard] = useState(new Map());
  const [otherEventCard, setOtherEventCard] = useState(new Map());
  const [orgMasterEventCard, setOrgMasterEventCard] = useState(new Map());
  const [orgOtherEventCard, setOrgOtherEventCard] = useState(new Map());
  const [searchText, setSearchText] = useState("");
  const [filteredBy, setFilteredBy] = React.useState(0);
  const [filterEmpty, setFilterEmpty] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (!eventCardLoaded) {
      setEventCardLoaded(true);
      setLoader(true);
      let collabId;

      if (userCollab && userCollab.length > 0) {
        collabId = userCollab[0].id;
        // collabId = 10;
      }

      if (
        (session &&
          !collabId &&
          session.user.role.id.toString() === ENV_ADMIN_ID) ||
        (session && collabId)
      ) {
        UserService.getMasterEventCard().then(
          (response) => {
            // setLoader(false);
            if (response.status === 200) {
              let mapMasterData = new Map();
              let k = 0;
              for (let i = response.data.length - 1; i >= 0 && k < 3; i--) {
                mapMasterData.set(response.data[i].access_id, response.data[i]);
                k++;
              }
              // if (response.data.length > 0) {
              //mapMasterData.set(response.data[response.data.length - 1].access_id, response.data[response.data.length - 1]);
              // response.data.map(i => mapMasterData.set(i.access_id, i));
              // }
              setMasterEventCard(mapMasterData);
              setOrgMasterEventCard(mapMasterData);
            }
          },
          (error) => {}
        );
      }

      if (session && session.user.role.id.toString() === ENV_ADMIN_ID) {
        UserService.getOtherEventCard().then(
          (response) => {
            setLoader(false);
            if (response.status === 200) {
              let mapOtherData = new Map();
              response.data.map((i) => mapOtherData.set(i.access_id, i));
              setOtherEventCard(mapOtherData);
              setOrgOtherEventCard(mapOtherData);
            }
          },
          (error) => {}
        );
      } else {
        UserService.getOtherEventCardByCollabId(collabId).then(
          (response) => {
            setLoader(false);
            if (response.status === 200) {
              let mapOtherData = new Map();
              response.data.map((i) => {
                let col_id_check = i.collaborator_ids;
                if (
                  col_id_check.match(
                    new RegExp("(?:^|,)" + collabId + "(?:,|$)")
                  )
                ) {
                  mapOtherData.set(i.access_id, i);
                }
              });
              setOtherEventCard(mapOtherData);
              setOrgOtherEventCard(mapOtherData);
            }
          },
          (error) => {}
        );
      }
    }
  });

  useEffect(() => {
    let tempList = [...orgMasterEventCard];

    if (searchText && searchText !== "") {
      tempList = tempList.filter((i) =>
        i[1].event_name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    if (filteredBy === "Published") {
      tempList = tempList.filter((i) => i[1].event_status === "Published");
    } else if (filteredBy === "Draft") {
      tempList = tempList.filter((i) => i[1].event_status === "Draft");
    } else if (filteredBy === "Archived") {
      tempList = tempList.filter((i) => i[1].event_status === "Archived");
    }
    let mapMasterData = new Map();
    tempList.map((i) => mapMasterData.set(i[0], i[1]));
    setMasterEventCard(mapMasterData);

    tempList = [...orgOtherEventCard];
    if (searchText && searchText !== "") {
      tempList = tempList.filter(
        (i) =>
          i[1].event_name.toLowerCase().includes(searchText.toLowerCase()) ||
          i[1].event_status.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    if (filteredBy === "Published") {
      tempList = tempList.filter((i) => i[1].event_status === "Published");
    } else if (filteredBy === "Draft") {
      tempList = tempList.filter((i) => i[1].event_status === "Draft");
    } else if (filteredBy === "Archived") {
      tempList = tempList.filter((i) => i[1].event_status === "Archived");
    }
    let mapOthersData = new Map();
    tempList.map((i) => mapOthersData.set(i[0], i[1]));
    setOtherEventCard(mapOthersData);
  }, [searchText, filteredBy]);

  const handleChangeFilterBy = (event) => {
    setFilteredBy(event.target.value);
    setFilterEmpty(true);
  };

  if (!session) {
    return <Redirect push to="/" />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Events | Infosys Living Labs</title>
        <meta name="description" content="" />
      </Helmet>
      <EventBar
        path={match.path}
        title="Events"
        setSearchText={setSearchText}
        setFilterEmpty={setFilterEmpty}
        masterEventCard={masterEventCard}
        otherEventCard={otherEventCard}
        session={session}
      />
      {(!userCollab || userCollab.length <= 0) && (
        <div className="collaborator_admin_msg">
          <p className="collaborator_admin_caption">
            {ReactHtmlParser(ENV_NO_COLLABORATOR)}
          </p>
        </div>
      )}
      <Container fluid>
        <div className="dropdowns_picker">
          <div className="d-flex align-items-center">
            <FormControl className="filter_by_control">
              <span className="filter_text_caption">Filter by:</span>
              <Select
                value={filteredBy}
                onChange={handleChangeFilterBy}
                displayEmpty
                inputProps={{ "aria-label": "Filtered by" }}
              >
                <MenuItem value={0}>All</MenuItem>
                <MenuItem value={"Published"}>Published</MenuItem>
                <MenuItem value={"Draft"}>Draft</MenuItem>
                <MenuItem value={"Archived"}>Archived</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <ul className="card_ul for_event_page">
          {masterEventCard && (
            <EventCard
              eventCard={masterEventCard}
              eventCardType="master"
              session={session}
              extra={otherEventCard}
            />
          )}
          {otherEventCard && (
            <EventCard
              eventCard={otherEventCard}
              eventCardType="others"
              session={session}
              extra={masterEventCard}
            />
          )}
        </ul>
        {masterEventCard.size === 0 &&
          otherEventCard.size === 0 &&
          searchText.length !== 0 && (
            <h5 className="no_result_found_title">No Events.</h5>
          )}
        {filterEmpty === true &&
          masterEventCard.size === 0 &&
          otherEventCard.size === 0 && (
            <h5 className="no_result_found_title">No Events.</h5>
          )}
        {loader && <LoaderGif />}
      </Container>
    </React.Fragment>
  );
};
export default Dashboard;
