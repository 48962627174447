export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const CREATE_EVENT_FAIL = "CREATE_EVENT_FAIL";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";
export const CREATE_COLLABRATOR_SUCCESS = "CREATE_COLLABRATOR_SUCCESS";
export const CREATE_COLLABRATOR_FAIL = "CREATE_COLLABRATOR_FAIL";
export const CREATE_SHOWCASES_SUCCESS = "CREATE_SHOWCASES_SUCCESS";
export const CREATE_SHOWCASES_FAIL = "CREATE_SHOWCASES_FAIL";
export const CREATE_EDITEVENT_SUCCESS = "CREATE_EDITEVENT_SUCCESS";
export const CREATE_EDITEVENT_FAIL = "CREATE_EDITEVENT_FAIL";
export const CREATE_AREA_SUCCESS = "CREATE_AREA_SUCCESS";
export const CREATE_AREA_FAIL = "CREATE_AREA_FAIL";
export const CREATE_MEDIATAG_SUCCESS = "CREATE_MEDIATAG_SUCCESS";
export const CREATE_MEDIATAG_FAIL = "CREATE_MEDIATAG_FAIL";
export const TEXTTOSPEECH_SUCCESS = "TEXTTOSPEECH_SUCCESS";
export const TEXTTOSPEECH_FAIL = "TEXTTOSPEECH_FAIL";
export const SEND_MAIL_SUCCESS = "SEND_MAIL_SUCCESS";
export const SEND_MAIL_FAIL = "SEND_MAIL_FAIL";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const EDIT_EVENT_SUCCESS = "EDIT_EVENT_SUCCESS";
export const EDIT_EVENT_FAIL = "EDIT_EVENT_FAIL";

export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";
export const UPLOAD_FAIL = "UPLOAD_FAIL";

export const UPLOAD_MEDIA_SUCCESS = "UPLOAD_MEDIA_SUCCESS";
export const UPLOAD_MEDIA_FAIL = "UPLOAD_MEDIA_FAIL";

export const DELETE_MEDIA_SUCCESS = "DELETE_MEDIA_SUCCESS";
export const DELETE_MEDIA_FAIL = "DELETE_MEDIA_FAIL";

export const CREATE_EVENT_ACCESS_SUCCESS = "CREATE_EVENT_ACCESS_SUCCESS";
export const CREATE_EVENT_ACCESS_FAIL = "CREATE_EVENT_ACCESS_FAIL";

export const CREATE_EDIT_INDUSTRYZONE_SUCCESS =
  "CREATE_EDIT_INDUSTRYZONE_SUCCESS";
export const CREATE_EDIT_INDUSTRYZONE_FAIL = "CREATE_EDIT_INDUSTRYZONE_FAIL";
