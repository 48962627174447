export default function authHeader() {
  const session = JSON.parse(localStorage.getItem("session"));
  if (session && session.jwt) {
    // For Spring Boot back-end
    return { Authorization: "Bearer " + session.jwt };

    // for Node.js Express back-end
    // return { "x-access-token": session.token };
  } else {
    return {};
  }
}
