import React, { Component } from "react";

class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      id: "",
    };
    this.props.keycloak.loadUserInfo().then((userInfo) => {
      this.setState({
        name: userInfo.name,
        email: userInfo.email,
        id: userInfo.sub,
      });
    });
  }

  render() {
    return (
      <>
        <p>
          This is a Keycloak-secured component of your application. You
          shouldn't be able to see this unless you've authenticated with
          Keycloak.
        </p>
        <p>Name: {this.state.name}</p>
        <p>Email: {this.state.email}</p>
        <p>ID: {this.state.id}</p>
      </>
    );
  }
}

export default UserInfo;
