import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import Button from "@material-ui/core/Button";
import BreadCrumb from "../modules/breadcrumb";
import UploadAssets from "../popups/upload-assets";
import PreviewEdit from "../popups/preview-edit";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import UserService from "../../services/user.service";
import { deleteMedia, MediaTagActions } from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import LoaderGif from "../modules/loader";
import DeleteConfirmation from "../popups/delete-confirmation";
import category_list_json from "../../assets/json/category_list";
import { statusErrorSuccess } from "../../App";
const ENV_MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

const MediaLibrary = ({ match }) => {
  const { session } = useSelector((state) => state.auth);
  const [uploadAssets, setuploadassets] = useState(false);
  const [orgMediaList, setOrgMediaList] = useState([]);
  const [mediaList, setMediaList] = useState([]);
  const [mediaListLoaded, setMediaListLoaded] = useState(false);
  const [mediaTagList, setMediaTagList] = useState(new Map());
  const [searchText, setSearchText] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (!mediaListLoaded) {
      setMediaListLoaded(true);
      setLoader(true);
      UserService.getMediaFiles().then(
        (response) => {
          setLoader(false);
          if (response.status === 200) {
            let mapMediaData = new Map();
            response.data.map((i) => mapMediaData.set(i.id, i));
            setMediaList(mapMediaData);
            setOrgMediaList(mapMediaData);
          }
        },
        (error) => {}
      );
      UserService.getMediaTages().then(
        (response) => {
          if (response.status === 200) {
            let mapMediaTag = new Map();
            response.data.map((i) => mapMediaTag.set(i.media_id, i));
            setMediaTagList(mapMediaTag);
          }
        },
        (error) => {}
      );
    }
  });

  const newmediauploaded = () => {};

  const searchMediaAssets = (e) => {
    let tempList = [...orgMediaList];
    tempList = tempList.filter(
      (i) =>
        i[1].name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        (i[1].mime.split("/")[0] === "application"
          ? i[1].mime
              .split("/")[0]
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          : i[1].mime
              .split("/")[0]
              .toLowerCase()
              .includes(e.target.value.toLowerCase()))
    );
    setMediaList(tempList);
    setSearchText(e.target.value);
  };

  if (!session) {
    return <Redirect push to="/" />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Media Library | Infosys Living Labs</title>
        <meta name="description" content="" />
      </Helmet>
      {uploadAssets === true && (
        <UploadAssets
          setuploadassets={setuploadassets}
          show={uploadAssets}
          onHide={() => setuploadassets(false)}
          newmediauploaded={newmediauploaded}
          ismedialibrary={"true"}
        />
      )}
      <BreadCrumb CurrentPage="Media Library" prevPageName="" prevPageURL="" />
      {mediaList && setMediaList && orgMediaList && (
        <React.Fragment>
          <MediaEventBar
            mediaListLen={mediaList.length ? mediaList.length : mediaList.size}
            onShow={() => setuploadassets(true)}
            searchMediaAssets={searchMediaAssets}
            searchText={searchText}
          />
          <MediaUploader
            mediaList={mediaList}
            setMediaList={setMediaList}
            orgMediaList={orgMediaList}
            mediaTagList={mediaTagList}
            searchText={searchText}
            loader={loader}
            setLoader={setLoader}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default MediaLibrary;

const MediaEventBar = (props) => {
  return (
    <section className="event_bar_section">
      <Container fluid>
        <div className="events_cover">
          <div className="d-flex align-items-center">
            <div>
              <h4 className="events_title">Media Library</h4>
              <p className="event_bar_url">
                {" "}
                {props.mediaListLen === undefined
                  ? "0"
                  : props.mediaListLen}{" "}
                Assets
              </p>
            </div>
            <div className="form_group">
              <input
                type="search"
                className="form_input_search"
                name="event-assets"
                placeholder="Search Assets"
                onChange={(e) => props.searchMediaAssets(e)}
              />
              <p className="event_search_icon">
                <img alt="" src="/images/search.svg" className="img-fluid" />
              </p>
            </div>
          </div>
          <Button variant="contained" color="primary" onClick={props.onShow}>
            Upload Assets
          </Button>
        </div>
      </Container>
    </section>
  );
};

const MediaUploader = ({
  mediaList,
  setMediaList,
  orgMediaList,
  mediaTagList,
  searchText,
  loader,
  setLoader,
}) => {
  const [filteredBy, setFilteredBy] = React.useState("all");
  const [sortedBy, setSortedBy] = React.useState("RECENT");
  const [catFilteredBy, setCatFilteredBy] = React.useState("all");
  const [checkedList, setCheckedList] = React.useState([]);
  const [checkedTagList, setCheckedTagList] = React.useState([]);
  const dispatch = useDispatch();
  const [deleteConfirmationState, setDeleteConfirmationState] = useState(false);
  const [deleteConfirmationValue, setDeleteConfirmationValue] = useState("");

  const [previewEditState, setPreviewEditState] = useState(false);
  const [previewEditId, setPreviewEditId] = useState(false);
  const [previewMedia, setPreviewMedia] = useState("");
  const [previewMediaTag, setPreviewMediaTag] = useState("");
  const [mediaTypeValue, setMediaTypeValue] = useState("");

  const handleChangeFilterBy = (event) => {
    setFilteredBy(event.target.value);
  };

  const handleChangeSortBy = (event) => {
    setSortedBy(event.target.value);
  };

  const handleChangeCatFilterBy = (event) => {
    setCatFilteredBy(event.target.value);
  };

  useEffect(() => {
    let tempList = [...orgMediaList];
    if (filteredBy !== "all") {
      tempList = tempList.filter((i) =>
        i[1].mime.split("/")[0].toLowerCase().includes(filteredBy.toLowerCase())
      );
    }

    if (catFilteredBy !== "all") {
      let temp = [...mediaTagList];
      temp = temp
        .filter((i) => i[1].industry === catFilteredBy)
        .map(function (item) {
          return item[0];
        });
      tempList = tempList.filter((i) => temp.includes(i[0]));
    }

    if (sortedBy !== "RECENT") {
      switch (sortedBy) {
        case "OLDEST":
          // tempList.sort(i => i[1]);
          tempList.sort((a, b) =>
            new Date(a[1].created_at) > new Date(b[1].created_at) ? 1 : -1
          );
          break;
        case "A2Z":
          tempList.sort((a, b) =>
            a[1].name.toLowerCase() > b[1].name.toLowerCase() ? 1 : -1
          );
          break;
        case "Z2A":
          tempList.sort((a, b) =>
            a[1].name.toLowerCase() < b[1].name.toLowerCase() ? 1 : -1
          );
          break;
        default:
          break;
      }
    }

    setMediaList(tempList);
  }, [filteredBy, sortedBy, catFilteredBy]);

  const handleCheckBoxChange = (event) => {
    if (event.target.checked) {
      let tempList = [...checkedList];
      tempList.push(event.target.value);
      setCheckedList(tempList);

      let tag = mediaTagList.get(parseInt(event.target.value));
      if (tag) {
        let tempTagList = [...checkedTagList];
        tempTagList.push(tag.id);
        setCheckedTagList(tempTagList);
      }
    } else {
      let tempList = [...checkedList];
      tempList = tempList.filter((i) => i !== event.target.value);
      setCheckedList(tempList);

      let tag = mediaTagList.get(event.target.value);
      if (tag) {
        let tempTagList = [...checkedTagList];
        tempTagList = tempTagList.filter((i) => i !== tag.id);
        setCheckedTagList(tempTagList);
      }
    }
  };

  const deleteAssets = () => {
    setLoader(true);
    checkedList.map((id, i) => {
      dispatch(deleteMedia(id))
        .then(() => {
          if (i === checkedList.length - 1) {
            setLoader(false);
            setDeleteConfirmationState(false);
            statusErrorSuccess("deletedSuccess", "success");
            window.location.href = "/admin/media-library";
          }
        })
        .catch(() => {
          setLoader(false);
          statusErrorSuccess("deleteFailed", "danger");
        });
    });

    checkedTagList.map((id, i) => {
      dispatch(MediaTagActions(id, {}, "DELETE"))
        .then(() => {})
        .catch(() => {
          setLoader(false);
        });
    });
  };
  // PreviewEditState
  const handlePreviewEdit = (id, media, mediaTagList, media_type) => {
    setPreviewEditState(true);
    setMediaTypeValue(media_type);
    setPreviewEditId(id);
    setPreviewMedia(media);
    {
      Array.from(mediaTagList).map(
        ([index, mediaTag], i) => index === id && setPreviewMediaTag(mediaTag)
      );
    }
  };

  return (
    <React.Fragment>
      {deleteConfirmationState === true && (
        <DeleteConfirmation
          setDeleteConfirmationState={setDeleteConfirmationState}
          show={deleteConfirmationState}
          onHide={() => setDeleteConfirmationState(false)}
          delete={() => deleteAssets()}
          deleteConfirmationValue={deleteConfirmationValue}
          page=""
          deleteMessage="Are you sure you want to remove this media?"
        />
      )}
      {previewEditState === true && (
        <PreviewEdit
          setPreviewEditState={setPreviewEditState}
          show={previewEditState}
          onHide={() => setPreviewEditState(false)}
          previewEditId={previewEditId}
          previewMedia={previewMedia}
          previewMediaTag={previewMediaTag}
          mediaTypeValue={mediaTypeValue}
        />
      )}
      <section className="media_uploader_section">
        <Container fluid>
          <div className="media_library_head d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="mr-5">
                <FormControl className="filter_by_control">
                  <span className="filter_text_caption">Sort by:</span>
                  <span>
                    <Select
                      value={sortedBy}
                      onChange={handleChangeSortBy}
                      displayEmpty
                      inputProps={{ "aria-label": "Sorted by" }}
                    >
                      <MenuItem value={"RECENT"}>Most Recent Uploads</MenuItem>
                      <MenuItem value={"OLDEST"}>Oldest Uploads</MenuItem>
                      <MenuItem value={"A2Z"}>
                        Alphabetical Order (A to Z)
                      </MenuItem>
                      <MenuItem value={"Z2A"}>
                        Alphabetical Order (Z to A)
                      </MenuItem>
                    </Select>
                  </span>
                </FormControl>
              </div>
              <div className="mr-5">
                <FormControl className="filter_by_control">
                  <span className="filter_text_caption">Type of Asset:</span>
                  <span>
                    <Select
                      value={filteredBy}
                      onChange={handleChangeFilterBy}
                      displayEmpty
                      inputProps={{ "aria-label": "Filtered by" }}
                    >
                      <MenuItem value={"all"}>All</MenuItem>
                      <MenuItem value={"image"}>Image</MenuItem>
                      <MenuItem value={"video"}>Video</MenuItem>
                      <MenuItem value={"audio"}>Audio</MenuItem>
                      <MenuItem value={"application"}>File</MenuItem>
                    </Select>
                  </span>
                </FormControl>
              </div>
              <div>
                <FormControl className="filter_by_control">
                  <span className="filter_text_caption">
                    Filter by Category:
                  </span>
                  <span>
                    <Select
                      value={catFilteredBy}
                      onChange={handleChangeCatFilterBy}
                      displayEmpty
                      inputProps={{ "aria-label": "Filtered by" }}
                    >
                      <MenuItem value={"all"}>All</MenuItem>
                      {category_list_json.map((category, i) => (
                        <MenuItem key={i} value={category.value}>
                          {category.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </span>
                </FormControl>
              </div>
            </div>
            <div className="selected_delete_option d-flex align-items-center ml-4">
              <p className="selected_assets_caption mr-5">
                {checkedList.length + " Asset Selected"}
              </p>
              <Button
                variant="outlined"
                color="secondary"
                disabled={checkedList.length > 0 ? false : true}
                onClick={() => setDeleteConfirmationState(true)}
              >
                Delete
              </Button>
            </div>
          </div>
          <ul className="media_library_ul">
            {Array.from(mediaList).map(([index, media], i) => (
              <li className="media_library_item" key={media.id}>
                <div className="checkbox_label_fixed">
                  <Checkbox
                    color="primary"
                    value={media.id}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    onChange={(e) => handleCheckBoxChange(e)}
                  />
                </div>
                <div
                  className="cover_uploaded_img"
                  onClick={() =>
                    handlePreviewEdit(
                      media.id,
                      media,
                      mediaTagList,
                      media.mime.split("/")[0]
                    )
                  }
                >
                  {
                    {
                      image: (
                        <React.Fragment>
                          <img
                            className="img-fluid"
                            src={
                              media.formats
                                ? ENV_MEDIA_URL + media.formats.thumbnail.url
                                : ENV_MEDIA_URL + media.url
                            }
                            alt=""
                          />
                          <label className="file_type">
                            {media.mime.split("/")[0]}
                          </label>
                        </React.Fragment>
                      ),
                      video: (
                        <div className="video_bg_media_lib">
                          <video className="media_video_lib">
                            <source
                              src={ENV_MEDIA_URL + media.url}
                              type="video/mp4"
                            />
                          </video>
                          <label className="file_type">
                            {media.mime.split("/")[0]}
                          </label>
                        </div>
                      ),
                      audio: (
                        <div className="cover_audio_bg">
                          <audio className="media_video_lib" controls>
                            <source
                              src={ENV_MEDIA_URL + media.url}
                              type="audio/mp3"
                            />
                          </audio>
                          <label className="file_type">
                            {media.mime.split("/")[0]}
                          </label>
                        </div>
                      ),
                      application: (
                        <React.Fragment>
                          <img
                            className="img-fluid"
                            src="/images/pdf-card.png"
                            alt=""
                          />
                          <label className="file_type">
                            {media.mime.split("/")[1]}
                          </label>
                        </React.Fragment>
                      ),
                    }[media.mime.split("/")[0]]
                  }
                </div>
                <div
                  onClick={() =>
                    handlePreviewEdit(
                      media.id,
                      media,
                      mediaTagList,
                      media.mime.split("/")[0]
                    )
                  }
                >
                  <h5 className="uploader_name">{media.name}</h5>
                  <p className="uploader_file_size">
                    {(media.size / 1024).toFixed(2) + " MB"}
                  </p>
                  {mediaTagList.get(media.id) && (
                    <React.Fragment>
                      <p className="category_label mb-3 mt-0">
                        {mediaTagList.get(media.id).industry
                          ? "Industry: " + mediaTagList.get(media.id).industry
                          : ""}
                      </p>
                      <p className="tag_label">
                        {mediaTagList
                          .get(media.id)
                          .tags.split(", ")
                          .map(
                            (tag, i) =>
                              tag !== "" && (
                                <span key={i} className="label_tag_span">
                                  {tag}
                                </span>
                              )
                          )}
                      </p>
                    </React.Fragment>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </Container>
        {searchText.length !== 0 && mediaList.length === 0 && (
          <h5 className="no_result_found_title pt-5">No Media.</h5>
        )}
      </section>
      {loader && <LoaderGif />}
    </React.Fragment>
  );
};
